import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { normalizeId } from '../../form-generator/helpers/utility';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import {
  addDocHubFormFieldsToForm,
  getAllDefaultCommissionOptionPreFill,
  getObjectForPrefill
} from '../../helpers/formHelpers';
import { openLinkInNewTab } from '../../helpers/global';
import NotFound from '../NotFound';
import SubmitButtonRow from '../common/SubmitButtonRow';
import File from '../common/file';
import ContractedUnitOverview from '../inventory/ContractedUnitOverview';
import InventoryPicker from '../inventory/InventoryPicker';
import { addChoicesToContractForm, formJson, validateAndCleanupForm } from './form';

const CreateForm = ({
  appChoices = {},
  onContractAdd,
  prefillContract,
  onAddInventoryPopupMetaChange,
  addInvenroryPopupMeta,
  onAddInvetoryPopupMetaClose
}) => {
  const location = useLocation();
  const [form, setForm] = useState(null);
  const [zipCodes, setZipCodes] = useState([]);
  const [importingContact, setImportingContact] = useState(
    prefillContract ? getObjectForPrefill(prefillContract) : undefined
  );

  const [role] = useState(localStorage.getItem('user-role'));
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [goToDochub, setGoToDocHub] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [inventoryPopupMeta, setInventoryPopupMeta] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const setUpForm = () => {
    if (location.state && location.state.preFillValues) {
      const { preFillValues, inventoryUnit, fromContract } = location.state;
      setImportingContact(getObjectForPrefill(preFillValues));
      setSelectedInventory(inventoryUnit);
      //clear location state, such that preFillValues is not imported again on refresh
      window.history.replaceState({}, document.title);
    }
    const newFormJson = addDocHubFormFieldsToForm({ formJson, appChoices });

    addChoicesToContractForm({ appChoices, form: newFormJson, initialFormValues: location?.state?.preFillValues });
    setZipCodes(appChoices.find(a => a.key === 'zipCodes')['values']);
    setForm(newFormJson);
  };

  useEffect(() => {
    if (!['super admin', 'admin'].includes(role)) return;
    setUpForm();
  }, []);

  if (!['super admin', 'admin'].includes(role)) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  const onFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    //checks
    const valid = validateAndCleanupForm(form);
    if (!valid) return;

    form['files'] = uploadedFiles.map(f => f.id);
    if (selectedInventory) form['inventoryUnit'] = selectedInventory._id;

    if (importingContact && !importingContact.hasOwnProperty('fromContract')) {
      form['importingContact'] = importingContact._id;
    }

    toast.info('Processing contract. This may take a while if Calendar events and email attachments are involved.');

    form.specialInstructions = form.specialInstructions.flatMap(i => i);
    setButtonsDisabled(true);

    const { response, error } = await makeApiRequests({
      requestBody: form,
      endpoint: ENDPOINTS.CONTRACTS_BASE
    });
    setButtonsDisabled(false);

    if (error) {
      return toast.error(error);
    }

    toast(`Contract created successfully!`, {
      type: 'success'
    });

    setUploadedFiles([]);

    if (onContractAdd) {
      onContractAdd(response);
    }

    setTimeout(() => {
      if (goToDochub) {
        openLinkInNewTab(`/dochub/print-groups?contractId=${response._id}`);
      }
      //this means it is not from a popup or sidebar
      if (!onContractAdd) document.location.reload();
    }, 1000);
  };

  const autoFillAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('zipCode').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('propertyState').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('propertyCity').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('propertyCounty').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  const autoFillMailingAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('mailingZip').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('mailingState').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('mailingCity').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('mailingCounty').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  window['autoFillMailingAddress'] = autoFillMailingAddress;
  window['autoFillAddress'] = autoFillAddress;
  window['onFormSubmit'] = onFormSubmit;

  const onButtonClick = goToDochub => {
    setGoToDocHub(goToDochub);
    setTimeout(() => {
      document.getElementById('add').click();
    }, 100);
  };

  const onLinkUnitClick = () => {
    setInventoryPopupMeta({});
  };

  const onRemoveUnitClick = () => {
    setSelectedInventory(null);
  };

  const onInventorySubmit = inventory => {
    setSelectedInventory(inventory[0]);
    setInventoryPopupMeta(null);
  };

  const onUnitSelect = unit => {
    setInventoryPopupMeta({ ...inventoryPopupMeta, selectedUnit: unit });
  };

  const setDefaultValueToCheckedKeys = (inputId, appChoiceKey) => {
    try {
      const keyValueContainer = document.getElementById(inputId);

      const checkedKeys = [...keyValueContainer.getElementsByTagName('input')].filter(
        input => input.type === 'checkbox' && input.checked
      );

      checkedKeys.forEach(checkedKey => {
        const relatedTextBox = document.getElementById(`${inputId}-${normalizeId(checkedKey.value)}-key-value`);
        if (!relatedTextBox.value) {
          relatedTextBox.value =
            appChoices.find(c => c.key === appChoiceKey)?.values.find(c => c.name === checkedKey.value)?.defaultValue ||
            0;
        }
      });
    } catch (e) {}
  };

  const onCommissionSheetOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetOptions', 'Commission Options');
  };

  window['onCommissionSheetOptionsChange'] = onCommissionSheetOptionsChange;

  const onCommissionSheetLessOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetLessOptions', 'Commission Less Options');
  };
  window['onCommissionSheetLessOptionsChange'] = onCommissionSheetLessOptionsChange;

  return (
    <Container fluid className="py-4 px-md-3">
      <Card>
        <Card.Body className="fade-in">
          {form && (
            <>
              <SubmitButtonRow
                disabled={buttonsDisabled}
                showGoToDocHub
                onGoToDocHubClick={() => onButtonClick(true)}
                onSaveClick={() => onButtonClick(false)}
              />
              <FormGenerator
                formJson={form}
                formValues={
                  importingContact
                    ? { add: importingContact }
                    : { add: { ...getAllDefaultCommissionOptionPreFill(appChoices), amountACRebateReceived: '500' } }
                }
              />
              <File uploadedFiles={uploadedFiles} onUploadedFilesChange={setUploadedFiles} containerClassName="mx-3" />

              <div className="m-3">
                <ContractedUnitOverview
                  onLinkUnitClick={onLinkUnitClick}
                  onRemoveUnitClick={onRemoveUnitClick}
                  onChangeUnitClick={onLinkUnitClick}
                  inventoryUnit={selectedInventory}
                />
                <InventoryPicker
                  excludedUnitIds={selectedInventory ? [selectedInventory['ID']] : []}
                  selectedUnits={
                    inventoryPopupMeta && inventoryPopupMeta.selectedUnit ? [inventoryPopupMeta.selectedUnit] : []
                  }
                  onUnitSelect={onUnitSelect}
                  show={inventoryPopupMeta}
                  onSubmit={onInventorySubmit}
                  fromContracts
                  onInventoryPickerClose={() => setInventoryPopupMeta(null)}
                  onAddInventoryPopupMetaChange={onAddInventoryPopupMetaChange}
                  addInvenroryPopupMeta={addInvenroryPopupMeta}
                  onAddInvetoryPopupMetaClose={onAddInvetoryPopupMetaClose}
                />
                {importingContact && !importingContact.hasOwnProperty('fromContract') && (
                  <h6 className="midFont text-right text-muted  mt-3 mb-0">
                    <i>
                      You are importing contact <b className="text-dark">{importingContact['buyer']}</b>. Saving this
                      contract will disable the contact and make it unavailable for edit.
                    </i>
                  </h6>
                )}
              </div>

              <SubmitButtonRow
                disabled={buttonsDisabled}
                showGoToDocHub
                onGoToDocHubClick={() => onButtonClick(true)}
                onSaveClick={() => onButtonClick(false)}
                bottom
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateForm;
