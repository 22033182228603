import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { ArrowUpRightCircle, Files } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { normalizeId } from '../../form-generator/helpers/utility';
import { deleteFileFromServer, makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { calculateCommissionsSummary } from '../../helpers/docHubFormHelpers';
import { convertKeyValueToFormObject, getObjectForPrefill } from '../../helpers/formHelpers';
import AlertModal from '../AlertModal';
import Loader from '../Loader';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import FileViewer from '../common/FileViewer';
import File from '../common/file';
import ContractedUnitOverview from '../inventory/ContractedUnitOverview';
import InventoryPicker from '../inventory/InventoryPicker';
import { addChoicesToContractForm, pnlEditFormJson, validateAndCleanupForm } from './form';
import PotentialUnitsOverview from '../inventory/PotentialUnitsOverview';
import cloneDeep from 'lodash/cloneDeep';

const PnlEditForm = ({ contract, type, appChoices, fromSearch, onContractEdit }) => {
  const [form, setForm] = useState(null);
  const [editingContract, setEditingContract] = useState();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [inventoryPopupMeta, setInventoryPopupMeta] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);
  const [role] = useState(localStorage.getItem('user-role'));

  useEffect(() => {
    if (contract) {
      setUpForm();
      updateContractState(cloneDeep(contract));
    }
  }, [type, contract]);

  useEffect(() => {
    try {
      calculateCommissionsSummary(
        type === 'Contract' ? selectedInventory?.invoice : selectedInventory?.[0]?.invoice || 0
      );
    } catch (e) {
      console.log('calculateCommissionsSummary', e);
    }
  }, [selectedInventory]);

  const setUpForm = () => {
    addChoicesToContractForm({ appChoices, form: pnlEditFormJson, initialFormValues: contract });
    setForm(pnlEditFormJson);
  };

  const updateContractState = contract => {
    setSelectedInventory(contract['inventoryUnit']);
    convertKeyValueToFormObject(contract, appChoices);
    setEditingContract(contract);
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = contract.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint:
        type === 'Contract' ? ENDPOINTS.CONTRACTS_WITH_ID(contract._id) : ENDPOINTS.CONTACTS_WITH_ID(contract._id),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    updateContractState(response);
    onContractEdit && onContractEdit(response, true);
    deleteFileFromServer(toBeDeletedFile);
  };

  const onEditFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    //checks
    const valid = validateAndCleanupForm(form);
    if (!valid) return;

    form['files'] = [...contract.files.map(f => f._id), ...uploadedFiles.map(f => f.id)];
    const isContact = type === 'Contact';

    form['inventoryUnit'] = selectedInventory
      ? isContact
        ? selectedInventory.map(i => i._id)
        : selectedInventory._id
      : isContact
      ? []
      : null;

    setButtonsDisabled(true);

    toast.info(
      `Processing ${
        type === 'Contact' ? 'contact' : 'contract'
      }. This may take a while if Calendar events and email attachments are involved.`
    );

    const { response, error } = await makeApiRequests({
      requestBody: form,
      method: 'PUT',
      endpoint:
        type === 'Contract' ? ENDPOINTS.CONTRACTS_WITH_ID(contract._id) : ENDPOINTS.CONTACTS_WITH_ID(contract._id)
    });

    setButtonsDisabled(false);
    if (error) {
      return toast.error(error);
    }

    toast.success(`${type === 'Contact' ? 'Contact' : 'Contract'} updated successfully!`);
    setUploadedFiles([]);

    const details = {
      ...response
    };

    onContractEdit && onContractEdit(details);
    updateContractState(details);
  };

  window['onEditFormSubmit'] = onEditFormSubmit;

  const onButtonClick = () => {
    document.getElementById('edit').click();
  };

  const ButtonsRow = ({ bottom }) => (
    <Row>
      <Col xs={12} className={`text-right ${bottom ? 'my-4' : ' mb-3 mt-2'}`}>
        <Button disabled={buttonsDisabled} onClick={() => onButtonClick(false)} size="sm" className="ml-2">
          Save Record
        </Button>
      </Col>
    </Row>
  );

  const onLinkUnitClick = replacingUnit => {
    if (type === 'Contact') {
      setInventoryPopupMeta({ replacingUnit });
    } else {
      setInventoryPopupMeta({});
    }
  };

  const onRemoveUnitClick = removingUnit => {
    if (type === 'Contact') {
      const existingIndex = selectedInventory.findIndex(i => i['_id'] === removingUnit['_id']);
      if (existingIndex !== -1) {
        selectedInventory.splice(existingIndex, 1);
      }
      setSelectedInventory([...selectedInventory]);
    } else {
      setSelectedInventory(null);
    }
  };

  const onInventorySubmit = inventoryList => {
    if (type === 'Contract') {
      setSelectedInventory(inventoryList[0]);
      setInventoryPopupMeta(null);
    } else {
      const inventory = inventoryList[0];
      const { replacingUnit } = inventoryPopupMeta;

      if (replacingUnit) {
        const existingIndex = selectedInventory.findIndex(i => i['_id'] === replacingUnit['_id']);
        if (existingIndex !== -1) {
          selectedInventory[existingIndex] = inventory;
        }
        setSelectedInventory([...selectedInventory]);
      } else {
        setSelectedInventory([...selectedInventory, inventory]);
      }
      setInventoryPopupMeta(null);
    }
  };

  const onUnitSelect = unit => {
    setInventoryPopupMeta({ ...inventoryPopupMeta, selectedUnit: unit });
  };

  const setDefaultValueToCheckedKeys = (inputId, appChoiceKey) => {
    try {
      const keyValueContainer = document.getElementById(inputId);

      const checkedKeys = [...keyValueContainer.getElementsByTagName('input')].filter(
        input => input.type === 'checkbox' && input.checked
      );

      checkedKeys.forEach(checkedKey => {
        const relatedTextBox = document.getElementById(`${inputId}-${normalizeId(checkedKey.value)}-key-value`);
        if (!relatedTextBox.value) {
          relatedTextBox.value =
            appChoices.find(c => c.key === appChoiceKey)?.values.find(c => c.name === checkedKey.value)?.defaultValue ||
            0;
        }
      });
    } catch (e) {}
  };

  const onCommissionSheetOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetOptions', 'Commission Options');
  };

  window['onCommissionSheetOptionsChange'] = onCommissionSheetOptionsChange;

  const onCommissionSheetLessOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetLessOptions', 'Commission Less Options');
  };
  window['onCommissionSheetLessOptionsChange'] = onCommissionSheetLessOptionsChange;

  const onPnLOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('pnlOptions', 'PnL Options');
  };
  window['onPnLOptionsChange'] = onPnLOptionsChange;

  window['calculateCommissionsSummary'] = () => {
    try {
      calculateCommissionsSummary(
        type === 'Contract' ? selectedInventory?.invoice : selectedInventory?.[0]?.invoice || 0
      );
    } catch (e) {
      console.log('calculateCommissionsSummary', e);
    }
  };

  return (
    <Container fluid className={!fromSearch ? 'px-md-3 py-4' : 'py-0 px-0'}>
      <Card>
        <Card.Body>
          {form && editingContract ? (
            <>
              {['super admin', 'admin', 'service'].includes(role) && (
                <>
                  <Row>
                    <Col className="text-right">
                      <Link target="_blank" to={`/dochub/print-groups?contractId=${contract['_id']}`}>
                        <Button variant="outline-dark" size="sm" className="py-1 ml-2">
                          <ArrowUpRightCircle className="align-text-top mr-2" />
                          Go to print
                        </Button>
                      </Link>

                      <Button
                        onClick={() => setShowFileViewer(true)}
                        variant="outline-dark"
                        size="sm"
                        className="py-1 ml-2"
                      >
                        <Files className="align-text-top mr-2" />
                        View Files
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                </>
              )}

              <FormGenerator formJson={form} formValues={{ edit: getObjectForPrefill(contract) }} />

              <File uploadedFiles={uploadedFiles} onUploadedFilesChange={setUploadedFiles} containerClassName="mx-3" />

              <div className="m-3">
                {type === 'Contract' ? (
                  <ContractedUnitOverview
                    onLinkUnitClick={onLinkUnitClick}
                    onRemoveUnitClick={onRemoveUnitClick}
                    onChangeUnitClick={onLinkUnitClick}
                    inventoryUnit={selectedInventory}
                  />
                ) : (
                  <PotentialUnitsOverview
                    onLinkNewUnitClick={onLinkUnitClick}
                    onRemoveUnitClick={onRemoveUnitClick}
                    onChangeUnitClick={onLinkUnitClick}
                    inventoryUnits={selectedInventory}
                  />
                )}
                <InventoryPicker
                  excludedUnitIds={
                    selectedInventory
                      ? type === 'Contact'
                        ? selectedInventory.map(i => i._id)
                        : [selectedInventory['_id']]
                      : []
                  }
                  show={inventoryPopupMeta}
                  onSubmit={onInventorySubmit}
                  selectedUnits={
                    inventoryPopupMeta && inventoryPopupMeta.selectedUnit ? [inventoryPopupMeta.selectedUnit] : []
                  }
                  onUnitSelect={onUnitSelect}
                  onInventoryPickerClose={() => setInventoryPopupMeta(null)}
                  fromContracts={type === 'Contract'}
                />
              </div>

              <ButtonsRow bottom />
              <hr />

              <hr className="my-2" />

              <FileViewer
                sidebarZIndex={2000}
                show={showFileViewer}
                onHide={() => setShowFileViewer(false)}
                files={contract['files']}
                driveFolder={contract['driveFolder']}
                onFileDeleteClick={onFileDeleteClick}
              />
              <AlertModal
                show={deleteFileModalMeta !== null}
                alertText={`Are you sure to delete this file (${
                  deleteFileModalMeta
                    ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                      ? deleteFileModalMeta.file.metadata.originalName
                      : deleteFileModalMeta.file.filename
                    : ''
                }). You will lose access to this file permanently?`}
                showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
                progressText="Deleting file..."
                continueButtonText="Delete"
                onDismissClick={() => setDeleteFileModalMeta(null)}
                onHide={() => setDeleteFileModalMeta(null)}
                onContinueClick={deleteFile}
              />
            </>
          ) : (
            <Loader />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

const PnlEditSidebar = ({ show, onHide, type, contract, appChoices, onContractEdit }) => {
  return (
    <SlidingSideBar fullScreen visible={show} onClose={onHide} title={'Edit Pnl Info'}>
      {contract && show && (
        <PnlEditForm contract={contract} type={type} appChoices={appChoices} onContractEdit={onContractEdit} />
      )}
    </SlidingSideBar>
  );
};

export default PnlEditSidebar;
