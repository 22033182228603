import React, { useState } from 'react';
import { Button, Row, Col, Card, Dropdown } from 'react-bootstrap';
import { DropDownInput } from '../../form-generator/components';
import { docHubSourceOptions } from '../../helpers/constants';
import ContactsPicker from '../ContactsPicker';
import ContractsPicker from '../ContractsPicker';
import InventoryPicker from '../inventory/InventoryPicker';
import CategorizedDropdown from '../common/CategorizedDropdown';
import { Eye, Printer } from 'react-bootstrap-icons';

const SelectionBox = ({
  showDocSourceSelector,
  docSource,
  onDocSourceChange,
  selectedGroup,
  onGroupPicked,
  selectedDocument,
  documents = [],
  onDocumentChange,
  onPreviewClick,
  onSwitchToExportedContractClick,
  documentFieldsLoading,
  printingBlankDocument,
  onPrintBlankDocumentClick
}) => {
  const [pickerMeta, setPickerMeta] = useState(null);

  const onPicked = contract => {
    onGroupPicked(contract[0]);
    onPickerClose();
  };

  const onPickerClose = () => {
    setPickerMeta(null);
  };

  return (
    <>
      <Card className="border rounded">
        <Card.Header className="bg-primary p-2">
          <h6 className="mb-0 text-white">Print Groups</h6>
        </Card.Header>
        <Card.Body className="p-2">
          <Row>
            {showDocSourceSelector && (
              <Col xs={12} md={4} className="px-3">
                <h6 className="mb-2">Select Source</h6>
                <select
                  value={docSource}
                  className={`form-control form-control-sm`}
                  id={'sourceSelect'}
                  onChange={onDocSourceChange}
                >
                  {docHubSourceOptions.map((option, index) => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
              </Col>
            )}
            <Col xs={12} md={showDocSourceSelector ? 4 : 6} className="px-3 mt-2 mt-md-0">
              <h6 className="mb-2">Select {docSource}</h6>
              <Button size="sm" variant="outline-info" block onClick={() => setPickerMeta({})}>
                {selectedGroup
                  ? selectedGroup['buyer'] ||
                    selectedGroup['serialA'] ||
                    selectedGroup['model'] ||
                    selectedGroup['sizeA'] ||
                    ''
                  : 'Tap to select'}
              </Button>
              {docSource === 'Contract' && (
                <ContractsPicker
                  submitButtonText="Select"
                  show={pickerMeta !== null}
                  selectedContracts={pickerMeta && pickerMeta.selectedData ? [pickerMeta.selectedData] : []}
                  onContractSelect={contract => setPickerMeta({ ...pickerMeta, selectedData: contract })}
                  onContractPickerClose={onPickerClose}
                  onSubmit={onPicked}
                />
              )}
              {docSource === 'Contact' && (
                <ContactsPicker
                  submitButtonText="Select"
                  show={pickerMeta !== null}
                  selectedContacts={pickerMeta && pickerMeta.selectedData ? [pickerMeta.selectedData] : []}
                  onContactSelect={contract => setPickerMeta({ ...pickerMeta, selectedData: contract })}
                  onContactPickerClose={onPickerClose}
                  onSubmit={onPicked}
                />
              )}

              {docSource === 'Inventory' && (
                <InventoryPicker
                  submitButtonText="Select"
                  show={pickerMeta !== null}
                  selectedUnits={pickerMeta && pickerMeta.selectedData ? [pickerMeta.selectedData] : []}
                  onUnitSelect={inventory => setPickerMeta({ ...pickerMeta, selectedData: inventory })}
                  onInventoryPickerClose={onPickerClose}
                  onSubmit={onPicked}
                />
              )}
            </Col>
            <Col xs={12} md={showDocSourceSelector ? 4 : 6} className="px-3 mt-2 mt-md-0">
              <h6 className="mb-2">Select Document</h6>
              <CategorizedDropdown
                childKeys={['name']}
                onOptionChange={option => onDocumentChange(option?.url)}
                options={documents}
                parentKey={'categoryLabel'}
                activeOption={selectedDocument}
                activeOptionToView={documents.find(document => document?.url === selectedDocument)?.name}
                toBeActiveKey={'url'}
              />
            </Col>

            <Col xs={12} className="mt-3 text-right">
              {docSource === 'Contact' && selectedGroup?.['exportedContract'] ? (
                <div className="d-flex alert alert-info mb-0 p-1 align-items-center">
                  <div className="flex-grow-1 text-left mr-2">
                    <b>This contact has already been contracted. Tap on the button to switch to the Contract.</b>{' '}
                  </div>
                  <Button size="sm" onClick={onSwitchToExportedContractClick}>
                    Switch
                  </Button>
                </div>
              ) : (
                <div className="d-flex justify-content-end">
                  <Button
                    size="sm"
                    className="mx-2"
                    onClick={onPreviewClick}
                    disabled={!selectedGroup || !selectedDocument || documentFieldsLoading}
                  >
                    <Eye className="mr-2" />
                    Preview
                  </Button>
                  {onPrintBlankDocumentClick && (
                    <Button
                      size="sm"
                      onClick={onPrintBlankDocumentClick}
                      disabled={!selectedDocument || printingBlankDocument}
                    >
                      <Printer className="mr-2" />
                      {printingBlankDocument ? 'Printing Blank Document...' : 'Print Blank Document'}
                    </Button>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default SelectionBox;
