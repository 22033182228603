import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DropDownInput from '../../../form-generator/components/DropDownInput';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS, months } from '../../../helpers/constants';
import Loader from '../../Loader';
import TextWithEdit from '../../TextWithEdit';

const getYears = () => {
  const nowYear = new Date().getFullYear();
  const years = [nowYear];

  for (let index = 1; index <= 5; index++) {
    years.push(nowYear - index);
  }

  return years.reverse();
};

const ACRebateExplorer = () => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeOptions] = useState(['Requested', 'Received']);
  const [selectedType, setSelectedType] = useState('Requested');

  const [years] = useState(getYears());
  const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
  const [filteredContracts, setFilteredContracts] = useState([]);

  const fetchContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        returnPaths: [
          'buyer',
          'salesLocation',
          'inventoryUnit',
          'dateACRebateRequested',
          'dateACRebateReceived',
          'amountACRebateReceived'
        ],
        filter: { $or: [{ dateACRebateRequested: { $ne: null } }, { dateACRebateReceived: { $ne: null } }] }
      },
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(
      response.results
        .filter(c => c['salesLocation'])
        .map(c => {
          const inventoryUnit = c['inventoryUnit'] || {};
          delete inventoryUnit['_id'];

          return { ...c, ...inventoryUnit };
        })
    );
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const filterContracts = () => {
    const dateType = selectedType === typeOptions[0] ? 'dateACRebateRequested' : 'dateACRebateReceived';

    const tempContracts = contracts
      .filter(c => {
        if (!c[dateType]) return false;

        const month = months.indexOf(selectedMonth);
        const dateToFilter = new Date(c[dateType]);
        return month == dateToFilter.getMonth() && selectedYear == dateToFilter.getFullYear();
      })
      .sort((a, b) => {
        const date1 = new Date(a[dateType]);
        const date2 = new Date(b[dateType]);

        return date1.getTime() - date2.getTime();
      });

    setFilteredContracts(tempContracts);
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    filterContracts();
  }, [contracts, selectedYear, selectedMonth, selectedType]);

  const onTypeChange = () => {
    const type = document.getElementById('reportTypeSelect').value;
    setSelectedType(type);
  };

  const onYearChange = () => {
    const year = document.getElementById('yearSelect').value;
    setSelectedYear(year);
  };

  const onMonthChange = () => {
    const month = document.getElementById('monthSelect').value;
    setSelectedMonth(month);
  };

  const SelectionBox = () => {
    return (
      <>
        <Card className="border rounded">
          <Card.Header className="bg-primary">
            <h5 className="mb-0 text-white">Explore AC Rebate</h5>
          </Card.Header>
          <Card.Body className="py-3">
            <Row>
              <Col xs={12} md={12} className="px-4 mt-3">
                <h6 className="mb-2">Report Type</h6>
                <DropDownInput
                  id="reportTypeSelect"
                  value={selectedType}
                  size="sm"
                  required
                  options={typeOptions}
                  onChangeFunction={onTypeChange}
                />
              </Col>
              <Col xs={12} md={6} className="px-4 mt-3">
                <h6 className="mb-2">Select Month</h6>
                <DropDownInput
                  id="monthSelect"
                  value={selectedMonth}
                  size="sm"
                  options={months}
                  onChangeFunction={onMonthChange}
                  required
                />
              </Col>

              <Col xs={12} md={6} className="px-4 mt-3">
                <h6 className="mb-2">Select Year</h6>
                <DropDownInput
                  id="yearSelect"
                  size="sm"
                  value={selectedYear}
                  options={years}
                  onChangeFunction={onYearChange}
                  required
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onRebateAmountRequiredInvalidation = contract => {
    toast.error('Amount is required!');
  };

  const onDateRebateRequestedvalidation = contract => {
    toast.error('Date rebate Requested is required!');
  };

  const onRebateAmountSubmit = async (contract, amount) => {
    const parsedAmount = Number(amount);
    toast.info('Please wait updating amount...');

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contract['_id']),
      requestBody: {
        amountACRebateReceived: parsedAmount
      },
      method: 'PUT'
    });

    if (error) {
      toast.error(error);
      return;
    }

    const index = contracts.findIndex(c => c['_id'] === contract['_id']);
    if (index !== -1) {
      contracts[index]['amountACRebateReceived'] = parsedAmount;
    }

    setContracts([...contracts]);
    toast.success(`Amount for buyer ${contract['buyer']} updated successfully`);
  };

  const onDateRebateRequestedSubmit = async (contract, date) => {
    toast.info('Please wait updating date...');

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contract['_id']),
      requestBody: {
        dateACRebateRequested: new Date(date).toISOString()
      },
      method: 'PUT'
    });

    if (error) {
      toast.error(error);
      return;
    }

    const index = contracts.findIndex(c => c['_id'] === contract['_id']);
    if (index !== -1) {
      contracts[index]['dateACRebateRequested'] = date;
    }

    setContracts([...contracts]);
    toast.success(`Date AC Rebate Requested for buyer ${contract['buyer']} updated successfully`);
  };

  return (
    <div className="px-0 px-md-5 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          <SelectionBox />
          <hr />

          <h6>Rebates:</h6>
          <Table style={{ fontSize: 14 }} responsive bordered className="text-center">
            <tr className="bg-dark text-light">
              <th>Buyer</th>
              <th>Sales Location</th>
              <th>Manufacturer</th>
              <th>Serial # A</th>
              <th>Serial # B</th>
              {selectedType === typeOptions[0] ? (
                <>
                  <th>Date AC Rebate Requested</th>
                  <th>Received?</th>
                </>
              ) : (
                <>
                  <th>Date AC Rebate Received</th>
                  <th>Amount Received</th>
                </>
              )}
            </tr>
            {filteredContracts.length > 0 ? (
              <>
                {filteredContracts.map(c => (
                  <tr>
                    <td>{c['buyer']}</td>
                    <td>{c['salesLocation'].location}</td>
                    <td>{c['manufacturer'] ? c['manufacturer'].name : ''}</td>
                    <td>{c['serialA']}</td>
                    <td>{c['serialB']}</td>
                    {selectedType === typeOptions[0] ? (
                      <>
                        <td>
                          <TextWithEdit
                            text={moment(c['dateACRebateRequested']).format('MMMM Do YYYY')}
                            onSubmit={value => onDateRebateRequestedSubmit(c, value)}
                            required
                            onRequiredInvalidation={() => onDateRebateRequestedvalidation(c)}
                            dateValue={c['dateACRebateRequested']}
                          />
                        </td>

                        <td>
                          {c['dateACRebateReceived'] ? moment(c['dateACRebateReceived']).format('MMMM Do YYYY') : ''}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{moment(c['dateACRebateReceived']).format('MMMM Do YYYY')}</td>
                        <td>
                          <TextWithEdit
                            preFix="$"
                            text={c['amountACRebateReceived'] || 0}
                            onSubmit={value => onRebateAmountSubmit(c, value)}
                            required
                            onRequiredInvalidation={() => onRebateAmountRequiredInvalidation(c)}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ))}
                {selectedType === typeOptions[1] && (
                  <tr>
                    <td colSpan={6} className="text-right">
                      <b>Total</b>
                    </td>
                    <td>
                      $
                      {filteredContracts
                        .map(c => c['amountACRebateReceived'])
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                )}
              </>
            ) : (
              <tr>
                <td colSpan={8}>No contracts to show</td>
              </tr>
            )}
          </Table>
          <h6 className="text-right text-muted">
            Total Reports: <span className="text-dark">{filteredContracts.length}</span>
          </h6>
        </>
      )}
    </div>
  );
};

export default ACRebateExplorer;
