import { getProfileFromLocalStorage, getSalesRepOfSalesManager } from './session';

export const STAGING_CLIENT_NAME = 'Housing Services';
export const MICASA_CLIENT_NAME = 'Micasa Housing';
export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_URL = process.env.REACT_APP_URL;
export const BRAND_NAME = process.env.REACT_APP_CLIENT;
export const CLIENT_PHONE_NUMBER = process.env.REACT_APP_CLIENT_PHONE;
export const CLIENT_EMAIL = process.env.REACT_APP_CLIENT_EMAIL;
export const isStaging = BRAND_NAME === STAGING_CLIENT_NAME;
export const isMicasa = BRAND_NAME === MICASA_CLIENT_NAME;

export const UNAUTHORIZED_TEXT = 'You are not authorized to view this page';
export const APP_ROLES = [
  'Super Admin',
  'Admin',
  'Sales',
  'Sales Manager',
  'Service',
  'Tech',
  'Installer',
  'Inventory Manager'
];
export const APP_ROLES_WITHOUT_ADMINS = ['Sales', 'Sales Manager', 'Service', 'Tech', 'Installer', 'Inventory Manager'];

export const DOCHUB_DATA_SOURCES = ['Contract', 'Contact', 'Mock', 'Inventory'];
export const CONTRACTOR_CATEGORY_OPTIONS = ['Factory', 'Transport', 'Factory Transport', 'Contractor'];

// API RELATED CONSTANTS
export const ENDPOINTS = {
  //auth
  LOGIN: '/users/login',
  REGISTER: '/users/register',
  GENERATE_REGISTER_OTP: '/users/generate-registration-otp',
  FORGOT_PASSWORD: '/users/send-password-reset-link',
  VERIFY_EMAIL: '/users/verify-email',
  CHANGE_PASSWORD: '/users/change-password',
  RESET_PASSWORD: '/users/reset-password',
  //users
  USERS_BASE: '/users/',
  USERS_ME: '/users/me',
  USERS_WITH_ID: id => `/users/${id}`,
  USERS_LIST: '/users/list',
  USERS_UPDATE: '/users/update-user-details',
  //sales location
  SALES_LOCATION_BASE: '/sales-locations',
  SALES_LOCATION_LIST: '/sales-locations/list',
  SALES_LOCATION_WITH_ID: id => `/sales-locations/${id}`,
  //sites
  SITE_BASE: '/sites',
  SITE_LIST: '/sites/search',
  SITE_WITH_ID: id => `/sites/${id}`,
  //slots
  SLOT_BASE: '/slots',
  SLOT_LIST: '/slots/search',
  SLOT_WITH_ID: id => `/slots/${id}`,
  //contracts
  CONTRACTS_BASE: '/contracts',
  CONTRACTS_WITH_ID: id => `/contracts/${id}`,
  CONTRACTS_DELETE_COMMENT: id => `/contracts/${id}/delete-comment`,
  CONTRACTS_SEARCH: '/contracts/search',
  CONTRACTS_SEARCH_NEW: '/contracts/search/new',
  CONTRACTS_NOTIFICATION: '/contracts/notification',
  CONTRACTS_CHANGE_WALKTHROUGH_ACTION: (contractId, actionType) =>
    `/contracts/change-walkthrough-action/${contractId}/${actionType}`,
  CONTRACTS_APPROVE_WALKTHROUGH: uuid => `/contracts/approve-walkthrough/${uuid}`,
  CONTRACTS_DOWNLOAD_CSV: `/contracts/csv/contracted-buyers`,

  //contract reports
  REPORTS_GET_FORMT: '/contracts/form-t',
  REPORTS_GET_SOL: '/contracts/sol-report',
  REPORTS_SUBMIT_REBATE: '/contracts/rebate',
  REPORTS_SUBMIT_AC_REBATE: '/contracts/ac-rebate',
  //service requests:
  SERVICE_REQUEST_BASE: '/service-requests',
  SERVICE_REQUEST_LIST: '/service-requests/list',
  SERVICE_REQUEST_CLAIM: '/service-requests/claim',
  SERVICE_REQUEST_RESOLVE: '/service-requests/resolve',
  //contacts
  CONTACTS_BASE: '/contacts',
  CONTACTS_WITH_ID: id => `/contacts/${id}`,
  CONTACTS_SEARCH: '/contacts/search',
  CONTACTS_SEARCH_NEW: '/contacts/search/new',

  //inventory
  INVENTORY_BASE: '/inventory',
  INVENTORY_WITH_ID: id => `/inventory/${id}`,
  INVENTORY_DELETE_COMMENT: id => `/inventory/${id}/delete-comment`,

  INVENTORY_SEARCH: '/inventory/search',
  INVENTORY_SEARCH_NEW: '/inventory/search/new',

  //payrolls
  PAYROLLS_BASE: '/payroll',
  PAYROLLS_WITH_ID: id => `/payroll/${id}`,
  PAYROLLS_SEARCH: '/payroll/list',
  //files
  FILE: '/files',
  BASE64_FILE: '/files/base64',
  BASE64_FILE_WITH_NAME: name => `/files/${name}`,
  FILE_WITH_ID: id => `/files/${id}`,
  FILE_STREAM_WITH_ID: id => `/files/stream/${id}`,
  //dochub
  DOCHUB_PRINT_TABLE: '/doc-hub/print-table',
  DOCHUB_PRINT_DOCUMENT_WITH_ID: id => `/doc-hub/print-document/${id}`,
  DOCHUB_PRINT_DOCUMENT_CANCEL: printSessionId => `/doc-hub/cancel-print-document/${printSessionId}`,
  DOCHUB_PRINT_DOCUMENT: `/doc-hub/print-document`,
  DOCHUB_DOCUMENT_PARAMS: id => `/doc-hub/document-params/${id}`,
  DOCHUB_PRINT_MHIT: '/doc-hub/print-mhit-report',

  //misc
  APP_CHOICES_BASE: '/app-choices',
  APP_CHOICES_LIST: '/app-choices/list',
  FORM_FIELDS_LIST: '/form-fields/list',
  FORM_FIELDS_UPDATE_MANY: '/form-fields/update-many',
  APP_CHOICES_WITH_ID: id => `/app-choices/${id}`,
  LOTSET_BASE: '/lot-set-options'
};

// Universal Search
export const UNIVERSAL_SEARCH_ROUTE = '/search';

//for client
export const serviceForm = {
  forms: [
    {
      name: 'Open A Service Request',
      markCompulsoryFields: true,
      compact: true,
      hideFormName: true,
      submit: {
        name: 'Request',
        show: false,
        onSubmit: 'onRequestFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 12,
              field: {
                type: 'custom-html',
                html: `<h6 class="text-muted">
                Please fill up the form below to request a new service.
              </h6><hr class='my-2'/>`
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 6,
              xs: 12,
              field: {
                id: 'name',
                title: 'Name',
                type: 'text',
                required: true
              }
            },
            {
              default: 6,
              xs: 12,
              field: {
                id: 'address',
                title: 'Address',
                type: 'text',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 6,
              xs: 12,
              field: {
                id: 'phoneNumber',
                title: 'Phone Number',
                type: 'text',
                required: true
              }
            },
            {
              default: 6,
              xs: 12,
              field: {
                id: 'emailAddress',
                title: 'Email Address',
                type: 'text',
                variant: 'email',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 6,
              xs: 12,
              field: {
                id: 'typeOfHome',
                title: 'Type of Home',
                type: 'dropdown',
                options: ['Single Wide', 'Double Wide', 'Tiny Home'],
                required: true
              }
            },
            {
              default: 6,
              xs: 12,
              field: {
                id: 'homeManufacturer',
                title: 'Home Manufacturer',
                type: 'dropdown',
                options: [
                  'Cavalier',
                  'Cavco',
                  'Clayton',
                  'Fleetwood',
                  'Jessup',
                  'Legacy',
                  'Southern Energy',
                  'TRU Homes'
                ],
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 12,
              xs: 12,
              field: {
                id: 'serviceIssue',
                title: 'Service Issue',
                type: 'text-area',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 6,
              xs: 12,
              field: {
                id: 'locationOfProblem',
                title: 'Location of problem',
                type: 'checkbox-group',
                options: ['Kitchen', 'Living Room', 'Other'],
                required: true,
                boxWidth: 12,
                dependentElem: 'locationProblemOther',
                showDependentOn: 'Other'
              }
            },
            {
              default: 6,
              xs: 12,
              field: {
                id: 'type',
                title: 'Type',
                type: 'checkbox-group',
                options: ['Plumbing', 'Electrical', 'Other'],
                required: true,
                boxWidth: 12,
                dependentElem: 'typeOther',
                showDependentOn: 'Other'
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 6,
              xs: 12,
              field: {
                id: 'locationProblemOther',
                type: 'text',
                required: false,
                hint: 'Other problem here...',
                className: 'd-none'
              }
            },
            {
              default: 6,
              xs: 12,
              field: {
                id: 'typeOther',
                type: 'text',
                required: false,
                hint: 'Other type here...',
                className: 'd-none'
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 12,
              xs: 12,
              field: {
                id: 'salesPerson',
                title: 'Sales person',
                type: 'text',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 12,
              xs: 12,
              field: {
                id: 'remarks',
                title: 'Remarks',
                type: 'text-area',
                required: true
              }
            }
          ]
        }
      ]
    }
  ]
};

// areKeysFixedOnDocument [when this is true only values are passed to api in array form ['a', '' ,'b']]
// because keys are already present on document
// when false, keys as well as value is passed to server in array of keyValue [{key:'', value: ''}]
// isTableInput, when this is true data received is array of arrays of length 2 but needs to be converted to array of keyValue
// isTableInput cannot be true is areKeysFixedOnDocument is true

export const keyValueFieldConfigs = [
  {
    name: 'optionalEquipmentAndAccessories',
    label: 'Optional Equipment And Accessories',
    isTableInput: true,
    defaultValue: 'Inc'
  },
  {
    name: 'twentyfirstImprovementFundsOptions',
    label: '21st Improvement Funds Options',
    isTableInput: true,
    defaultValue: 'Inc'
  },

  { name: 'commissionSheetOptions', label: 'Commission Sheet Options', isTableInput: true, defaultValue: 0 },
  {
    name: 'commissionSheetLessOptions',
    label: 'Commission Sheet Less Options',
    isTableInput: true,
    defaultValue: 0
  },
  { name: 'pnlOptions', label: 'P&L Options', defaultValue: 0, shouldBeChecked: true },
  { name: 'retailerOptions', label: 'Retailer Options', isTableInput: true, defaultValue: 0 },

  {
    name: 'countryPlaceFhaPaImprovements',
    label: 'Country Place (FHA) PA Improvements',
    isTableInput: true,
    defaultValue: 'Inc'
  },
  {
    name: 'twentifirstImprovementInspectionOptions',
    label: '21st Improvement Inspection Options',
    isTableInput: true,
    defaultValue: ''
  },
  {
    name: 'tinyHomeAdditionalProducts',
    label: 'Tiny Home Additional Products',
    areKeysFixedOnDocument: true,
    defaultValue: 'Inc'
  },
  {
    name: 'sellerPaidOptions',
    label: 'Seller Paid Options',
    isMultiText: true
  },
  {
    name: 'promiseListItems',
    label: 'Promise List Items',
    isMultiText: true
  }
];

export const numbersOnlyFields = ['Cell Phone #', 'Cell Phone #2'];

//SERVICE ORDER CONSTANTS
export const roomOptions = [
  'Kitchen',
  'Master Bath',
  'Guest Bath',
  'Laundry Room',
  'Livingroom 1',
  'Livingroom 2',
  'Hallway',
  'Guest Bedroom 1',
  'Guest Bedroom 2',
  'Guest Bedroom 3',
  'Guest Bedroom 4'
];

export const wallOptions = ['Front', 'Back', 'Left Endwall', 'Right Endwall'];
export const closetOptions = [
  'Master Closet',
  'Guest Bedroom Closet 1',
  'Guest Bedroom Closet 2',
  'Guest Bedroom Closet 3',
  'Guest Bedroom Closet 4',
  'Guest Bedroom Closet 5'
];

// Key Names

export const KEY_NAMES = ['ctrl', 'alt'];

export const serviceOrderOptions = {
  Kitchen: {
    parts: [],
    location: ['Counter', 'Island', 'Backsplash', 'Floor', 'Ceiling', 'Cabinets', 'Electrical', 'Plumbing']
  },
  'Heating, Plumbing, Baths': {
    parts: ['Faucets', 'Shower', 'Vents', 'Toilet', 'Furnace'],
    location: roomOptions
  },
  'Ceiling-Structural-Fireplaces': {
    parts: ['Texture', 'Flat'],
    location: roomOptions
  },
  'Exterior Siding': {
    parts: ['Vinyl', 'Smart Panel', 'Hardi Panel', 'Metal', 'Stucco'],
    location: wallOptions
  },
  'Exterior Dormer': { parts: [], location: [] },
  'Exterior Shutters': {
    parts: [],
    location: ['Left End Wall', 'Right End Wall', 'A Half', 'B Half', 'SingleWide Front', 'SingleWide Rear']
  },
  'Exterior Windows': { parts: [], location: wallOptions },
  'Exterior Belly': {
    parts: ['Belly Tape', 'Belly Wrap', 'Stapled Plywood'],
    location: ['A Half', 'B Half', 'SingleWide']
  },
  'Exterior Roof': {
    parts: [
      'Ridge Vent',
      'Shingles',
      'Metal Flashing',
      'Tar Paper',
      'Vent-PVC',
      'Vent-Mushroom',
      'Vent-Bell',
      'Vent-Exhaust'
    ],
    location: ['Ridge', 'A Half', 'B Half', 'SingleWide']
  },
  'Interior Walls and Decor': {
    parts: ['T&T', 'Batten Strip', 'Wall Panels', 'Shelf', 'Miniblinds'],
    location: [...wallOptions, ...closetOptions]
  },
  'Doors and Windows': {
    parts: [
      'Door Jamb',
      'Storm Door',
      'Front Door',
      'Bank Door',
      'Sliding Glass Door',
      'Barn Door',
      'French Door',
      'Window',
      'Double Window',
      'Bathroom Window',
      'Kitchen Window'
    ],
    location: ['Front Door', 'Back Door', 'Pantry', ...roomOptions]
  },
  Cabinets: { parts: [], location: roomOptions },
  Mirrors: { parts: [], location: roomOptions },
  'Cieling & Door Trims': {
    parts: [
      'Ceiling Panel',
      'Marriage Ceiling Board',
      'Texture/Stomp',
      'Crown Moulding',
      'Ceiling Trim',
      'Door Trim',
      'Door Jamb',
      'Door Stop'
    ],
    location: []
  },
  'Counter Tops & Ceramic Colors': { parts: [], location: ['Kitchen', 'Master Bathroom', 'Guest Bathroom'] },
  'Carpet and Lino': {
    parts: ['Carpet', 'Carpet Pad', 'Lino', 'Transition Bar', 'Quarter Round Trim', 'Wood Filler(Lino to Lino)'],
    location: roomOptions
  },
  Appliances: {
    parts: [
      'Refrigerator',
      'Stove',
      'Dishwasher',
      'Microwave',
      'Hood Vent',
      'Furnace',
      'Air Conditioner',
      'Heat Pump',
      'Water Heater'
    ],
    location: ['Kitchen', 'Master Bath', 'Guest Bath', 'Laundry Room', 'Hallway']
  },
  Electrical: {
    parts: [
      'Chandelier',
      'Breaker Box',
      'Thermostat',
      'Ceiling Fan',
      'AC Disconnect Box',
      'Pendant Lights',
      'Can Lights',
      'Coach Lights',
      'Porch Light',
      'Exterior Light',
      'Dish Fixture',
      'Globe Fixture',
      'Gumball Fixture',
      'Pendant Fixture',
      'Frosted Tube Fixture',
      'Jar Fixture',
      'Switch Plate Single',
      'Switch Plate Double',
      'Switch Plate Triple',
      'Toggle Switch Single',
      'Toggle Switch Double',
      'GFI Outlet',
      'Outlet Box- Receptacle',
      'Outlet Face Plate',
      'Smoke Alarm',
      'Exterior Outlet'
    ],
    location: [...roomOptions, 'Outside Front Door', 'Outside Back Door']
  },
  Insulation: { parts: [], location: ['Floor', 'Exterior Walls', 'Ceiling', 'Underbelly'] },
  Flooring: { parts: [], location: [] },
  'Furniture/Decor': { parts: [], location: [] }
};

export const repairOptions = ['Replace', 'Repair', 'Install', 'Adjust', 'Remove and Replace'];
export const causeOptions = ['Missing', 'Transport', 'Defect', 'Exterior Transport Damage', 'Low Water Pressure'];
export const descOptions = [
  'Kitchen',
  'Heating, Plumbing, Baths',
  'Ceiling-Structural-Fireplaces',
  'Exterior Siding',
  'Exterior Dormer',
  'Exterior Shutters',
  'Exterior Windows',
  'Exterior Belly',
  'Exterior Roof',
  'Interior Walls and Decor',
  'Doors and Windows',
  'Cabinets',
  'Mirrors',
  'Cieling & Door Trims',
  'Counter Tops & Ceramic Colors',
  'Carpet and Lino',
  'Appliances',
  'Electrical',
  'Insulation',
  'Flooring',
  'Furniture/Decor'
];

//SEARCH RELATED
export const contactSearchPaths = [
  '_id',
  'buyer',
  'coBuyer',
  'buyerEmail',
  'propertyAddress',
  'zipCode',
  'cellPhone',
  'cellPhone2',
  'consultantsFollowUpNotes'
];

export const inventoryPaths = [
  '_id',
  'unitLocation',
  'manufacturer/name',
  'manufacturer/factoryShortName',
  'model',
  'serialA',
  'serialB',
  'bedrooms',
  'bathrooms',
  'sizeA',
  'sizeB',
  'hudA',
  'hudB',
  'floorplanLender/name',
  'lotSet',
  'typeOfUnit'
];

export const contractSearchPaths = [
  '_id',
  'buyer',
  'coBuyer',
  'salesLocation/location',
  'tech/name',
  'salesperson',
  'propertyAddress',
  'propertyCity',
  'propertyState',
  'zipCode',
  'propertyCounty',
  'cellPhone',
  'cellPhone2',
  'buyerEmail',
  'stateInspection',
  'serialA'
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const contractMilestoneDates = [
  {
    name: 'Setup',
    key: 'setup',
    start: 'dateSetUpStart',
    end: 'dateSetUpEnd',
    emailField: 'dateSetupEmails',
    options: 'setupNotificationOptions'
  },
  {
    name: 'Makeready',
    key: 'makeready',
    start: 'makereadyStartDate',
    end: 'makereadyEndDate',
    emailField: 'makereadyDateEmails',
    options: 'makereadyNotificationOptions'
  },
  {
    name: 'Delivery',
    key: 'delivery',
    start: 'dateDeliveredStart',
    end: 'dateDeliveredEnd',
    emailField: 'dateDeliveredEmails',
    options: 'deliveryNotificationOptions'
  },
  {
    name: 'Walkthrough',
    key: 'walkthrough',
    start: 'walkThroughDate',
    emailField: 'walkThroughDateEmails'
  },
  {
    name: 'Service',
    key: 'service',
    start: 'serviceStartDate',
    end: 'serviceEndDate',
    emailField: 'serviceScheduledDateEmails',
    options: 'serviceNotificationOptions'
  }
];

export const contractToggleOptions = ({ contract, appChoices }) => [
  {
    label: 'Status',
    key: 'status',
    variant: 'info',
    options: appChoices.find(c => c.key === 'Status').values.map(s => ({ option: s, value: s }))
  },
  {
    label: 'Bill-Back Status',
    key: 'billbackStatus',
    variant: 'primary',
    options: appChoices.find(c => c.key === 'Bill-Back Status').values.map(s => ({ option: s, value: s }))
  },
  {
    label: 'Funding Status',
    key: 'fundingStatus',
    variant: 'danger',
    options: appChoices.find(c => c.key === 'Funding Status').values.map(s => ({ option: s, value: s }))
  },
  {
    label: 'Lender',
    key: 'lender',
    variant: 'dark',
    options: appChoices
      .find(c => c.key === 'lender')
      .values.map(l => ({ option: l.lienholderCorporateName, value: l._id }))
  },
  ...(contract['lender'] && contract['lender'].lienholderCorporateName === 'Cash'
    ? [
        {
          label: 'Cash Paid',
          key: 'cashPaid',
          variant: 'dark',
          options: ['Yes', 'No'].map(s => ({ option: s, value: s }))
        }
      ]
    : []),
  {
    label: 'AC Status',
    key: 'acStatus',
    variant: 'primary',
    options: appChoices.find(c => c.key === 'AC Status').values.map(s => ({ option: s, value: s }))
  }
];

export const contractSearchFilterOptions = [
  { key: 'salesLocation', label: 'Sales Location', optionLabelKey: 'location' },
  { key: 'tech', label: 'Tech', isMembers: true, memberRole: 'Tech' },
  { key: 'status', label: 'Status', appChoiceKey: 'Status', includeBlankIfNull: true },
  { key: 'manufacturer', label: 'Manufacturer', optionLabelKey: 'factoryShortName' },
  { key: 'floorplanLender', label: 'Floorplan Lender', optionLabelKey: 'name' },
  { key: 'fundingStatus', label: 'Funding Status', appChoiceKey: 'Funding Status', includeBlankIfNull: true },
  { key: 'lender', label: 'Lender', optionLabelKey: 'lienholderCorporateName' },
  { key: 'acStatus', label: 'AC Status', appChoiceKey: 'AC Status', includeBlankIfNull: true }
];

export const contractSearchSortByOptions = [
  { name: 'Contract Updated Date', key: 'updatedAt' },
  { name: 'Setup Date', key: 'setup.start' },
  { name: 'Trimout Date', key: 'trimout.start' },
  { name: 'Service Scheduled Date', key: 'service.start' },
  { name: 'Date Form T Sent', key: 'dateFormTSent' },
  { name: 'Date Rebate Requested', key: 'dateRebateRequested' },
  { name: 'Date Rebate Received', key: 'dateRebateReceived' },
  { name: 'Date Rebate Reported', key: 'dateRebateReported' },
  { name: 'Date Signed', key: 'dateSigned' },
  { name: 'NPS Call Date', key: 'npsCallDate' }
];

//contacts
export const contactToggleOptions = ({ contact, appChoices }) => [
  {
    label: 'Status',
    key: 'status',
    variant: 'primary',
    options: appChoices.find(c => c.key === 'Contact Status').values.map(s => ({ option: s, value: s }))
  },

  {
    label: 'Manufacturer',
    key: 'manufacturer',
    variant: 'primary',
    options: appChoices
      .find(c => c.key === 'manufacturer')
      .values.map(l => ({ option: l.factoryShortName, value: l._id }))
  }
];

const contactSearchFilterOptions = [
  { key: 'typeOfUnit', label: 'Type Of Unit', appChoiceKey: 'Type Of Unit', includeBlankIfNull: true },
  { key: 'status', label: 'Contact Status', appChoiceKey: 'Contact Status', includeBlankIfNull: true },
  {
    key: 'newOrUsed',
    label: 'New/Used',
    options: ['New', 'Used'].map(o => ({ option: o, value: o })),
    includeBlankIfNull: true
  },
  {
    key: 'stockOrRso',
    label: 'Stock/RSO',
    options: ['Stock', 'RSO'].map(o => ({ option: o, value: o })),
    includeBlankIfNull: true
  },
  { key: 'lender', label: 'Lender', optionLabelKey: 'lienholderCorporateName' }
];

export const createFilterOptionsForContact = role => {
  if (role === 'sales') return contactSearchFilterOptions;

  if (['super admin', 'admin'].includes(role))
    return [
      ...contactSearchFilterOptions,
      {
        key: 'contactOwner',
        label: 'Contact Owner',
        isMembers: true,
        memberRole: ['Super Admin', 'Admin', 'Sales', 'Sales Manager'],
        doNotIncludeUnassigned: true
      }
    ];

  //for sales manager
  if (role === 'sales manager') {
    const loggedInUser = getProfileFromLocalStorage();
    return [
      ...contactSearchFilterOptions,
      {
        key: 'contactOwner',
        label: 'Contact Owner',
        options: [
          {
            option: `${loggedInUser.name}(me)`,
            value: loggedInUser._id
          },
          ...getSalesRepOfSalesManager().map(user => ({
            option: `${user.name}`,
            value: user._id
          }))
        ].sort((m1, m2) => m1.option.localeCompare(m2.option)),
        doNotIncludeUnassigned: true
      }
    ];
  }
  return [];
};

export const contactSearchSortByOptions = [
  { name: 'Follow Up Date', key: 'followUpDate' },
  { name: 'Initial Contact Date', key: 'initialContactDate' },
  { name: 'Last Contact Date', key: 'lastContactDate' },
  { name: 'Last Updated', key: 'updatedAt' },
  { name: 'Created Date', key: 'createdAt' }
];

export const inventorySearchFilterOptions = [
  { appChoiceKey: 'salesLocation', key: 'unitLocation', label: 'Unit Location', optionLabelKey: 'location' },
  { key: 'manufacturer', label: 'Manufacturer', optionLabelKey: 'factoryShortName' },
  { key: 'floorplanLender', label: 'Floorplan Lender', optionLabelKey: 'name' }
];

export const inventorySearchSortByOptions = [
  { name: 'Created Date', key: 'createdAt' },
  { name: 'Last Updated', key: 'updatedAt' },
  { name: 'Date of manufacture', key: 'dateOfManufacture' },
  { name: 'Invoice Date', key: 'invoiceDate' }
];

export const inventoryToggleOptions = ({ inventory, appChoices, includeManufacturer = true }) => {
  const manufacturer = includeManufacturer
    ? [
        {
          label: 'Manufacturer',
          key: 'manufacturer',
          variant: 'primary',
          options: appChoices
            .find(c => c.key === 'manufacturer')
            .values.map(l => ({ option: l.factoryShortName, value: l._id }))
        }
      ]
    : [];

  const sitesChoices = appChoices.find(choice => choice.key === 'salesLocation')?.values || [];

  const activeSiteChoice = sitesChoices.find(choice => choice?._id === inventory?.unitLocation?._id) || {};

  const slotsOptions = activeSiteChoice.sites
    ? activeSiteChoice.sites.flatMap(site => {
        return [
          {
            isTitle: true,
            title: site.name
          },
          ...site.slots
            .filter(s => !s.isOfficeBuilding)
            .map(s => ({
              option: s.number,
              value: s._id
            }))
        ];
      })
    : [];

  return [
    ...manufacturer,
    {
      label: 'Assigned Slot',
      key: 'currentSlotId',
      variant: 'primary',
      options: slotsOptions
    }
  ];
};

export const serviceSearchFilterOptions = [
  {
    key: 'status',
    label: 'Status',
    options: ['Pending', 'Claimed', 'Resolved'].map(s => ({
      option: s,
      value: s
    })),
    includeBlankIfNull: true
  }
];

export const myClaimsSearchFilterOptions = [
  {
    key: 'status',
    label: 'Status',
    options: ['Claimed', 'Resolved'].map(s => ({
      option: s,
      value: s
    })),
    includeBlankIfNull: true
  }
];

export const newServiceSearchSortByOptions = [
  { name: 'Last Updated', key: 'updatedAt' },
  { name: 'Request Date', key: 'createdAt' }
];

export const serviceSearchSortByOptions = [
  ...newServiceSearchSortByOptions,
  { name: 'Resolved Date', key: 'resolvedDate' }
];

export const installerFilterOptions = [
  { key: 'salesLocation', label: 'Sales Location', optionLabelKey: 'location' },
  { key: 'status', label: 'Status', appChoiceKey: 'Status' }
];

export const installerSortByOptions = [
  { name: 'Not Installed First', key: 'notInstalledFirst' },
  { name: 'Contract Updated Date', key: 'updatedAt' },
  { name: 'Setup Date', key: 'setup.start' },
  { name: 'Trimout Date', key: 'trimout.start' },
  { name: 'Service Scheduled Date', key: 'service.start' }
];

export const opsFilterOptions = installerFilterOptions;

export const opsSortByOptions = [
  { name: 'No Walkthrough First', key: 'noWalkthoughFirst' },
  { name: 'Contract Updated Date', key: 'updatedAt' },
  { name: 'Setup Date', key: 'setup.start' },
  { name: 'Trimout Date', key: 'trimout.start' },
  { name: 'Service Scheduled Date', key: 'service.start' }
];

export const typeOfTransactionOptions = [
  'Personal | New',
  'Personal | Used',
  'Personal | Lien Assignment',
  'Personal | Convert back to personal property',
  'Personal | Other',
  'Real | New',
  'Real | Used',
  'Real | Update',
  'Real | Other'
];

export const docHubSourceOptions = ['Contract', 'Contact', 'Inventory'];

export const commissionImportantFieldHTML = `
  <h6>Calculated Values: </h6>
  <div class='border border-dark p-2 rounded bg-light'>
  <div class='row text-dark midFont'>
      <div class='col col-12 col-md-4 mb-1'>
        Net Commission: <b id='netCommission'>$N/A</b>
      </div>
       <div class='col col-12 col-md-4 mb-1'>
      Total of Options: <b id='totalOfOptions'>$N/A</b>
      </div>
      <div class='col col-12 col-md-4 mb-1'>
      Gross Profit: <b id='grossProfit'>$N/A</b>
      </div>
      <div class='col col-12 col-md-4 mb-1'>
      Commission Due: <b id='commissionDue'>$N/A</b>
      </div>
       <div class='col col-12 col-md-4 mb-1'>
      Commission Due with Bonus: <b id='commissionDueWithBonus'>$N/A</b>
      </div>
       <div class='col col-12 col-md-4 mb-1'>
      Front End: <b id='frontEnd'>$N/A</b>
      </div>
      <div class='col col-12 col-md-4 mb-1'>
      Back End: <b id='backEnd'>$N/A</b>
      </div>
       <div class='col col-12 col-md-4 mb-1'>
      Balance to be Paid: <b id='balanceToBePaid'>$N/A</b>
      </div>
       <div class='col col-12 col-md-4 mb-1'>
      Balance after Advance: <b id='balanceAfterAdvance'>$N/A</b>
      </div>
      
      ${
        isMicasa
          ? `
        <div class='col col-12 col-md-4 mb-1'>
      <span id='bonusTypeName'>Bonus</span>: <b id='bonusValue'>$N/A</b>
      </div>
      <div class='col col-12 col-md-4 mb-1'>
      Total w/Bonus: <b id='totalWithBonusValue'>$N/A</b>
      </div>
        `
          : ''
      }
     
  </div>
  </div>`;

export const salesImportantFieldHTML = `
<h6>Calculated Values: </h6>
<div class='border border-dark p-2 rounded bg-light'>
<div class='row text-dark midFont'>
    <div class='col col-12 col-md-4 mb-1'>
    Options: <b id='options'>$N/A</b>
    </div>
    <div class='col col-12 col-md-4 mb-1'>
    Sub-Total: <b id='subTotal'>$N/A</b>
    </div>
    <div class='col col-12 col-md-4 mb-1'>
    Trade-In Net Allowance: <b id='tradeInNetAllowance'>$N/A</b>
    </div>
    <div class='col col-12 col-md-4 mb-1'>
    Total Down Payment: <b id='totalDownPayment'>$N/A</b>
    </div>
    <div class='col col-12 col-md-4 mb-1'>
    Unpaid Cash Price: <b id='unpaidCashPrice'>$N/A</b>
    </div>
    <div class='col col-12 col-md-4 mb-1'>
    Total Unpaid Balance: <b id='totalUnpaidBalance'>$N/A</b>
    </div>
    <div class='col col-12 col-md-4 mb-1'>
    Total Balance Due / Amount Financed: <b id='totalBalanceDue'>$N/A</b>
    </div>
</div>
</div>`;

export const appChoicesKeys = [
  '21st Improvement Funds Options',
  '21st Improvement Inspection Options',
  'Admin Fee',
  'AC Status',
  'Bill-Back Status',
  'Contact Status',
  'Country Place (FHA) PA Improvements',
  'Commission Less Options',
  'Commission Options',
  'Delivery Notification Options',
  'Delivery/Set Up',
  'Exterior Siding',
  'Floorplan',
  'Funding Status',
  'Lead Source',
  'Makeready Notification Options',
  'New A/C',
  'Optional Equipment And Accessories',
  'Retailer Options',
  'Seller Paid Options',
  'Promise List Items',
  'Payroll Categories',
  'Rebate Eligible',
  'Setup Notification Options',
  'Service Notification Options',
  'Status',
  'Steps',
  'Stock/Lot Set/RSO',
  'Tech Status',
  'Tiny Home Additional Products',
  'Type',
  'Type Of Unit',
  'Utilities',
  'Walkthrough Status',
  'Print Groups Categories',
  'Special Instruction Options',
  'PnL Options'
];

export const withDefaultValuesKeys = ['Commission Options', 'Commission Less Options', 'PnL Options'];

export const hasDefaulValues = key => withDefaultValuesKeys.includes(key);

export const additionalDocHubfieldTypes = ['text', 'number', 'date', 'dropdown', 'checkbox', 'checkbox-group'];
export const additionalDocHubfieldTypesWithDropdowns = ['dropdown', 'checkbox-group'];

export const RETURN_PATHS = {
  CONTRACT: ['_id', 'buyer', 'status', 'salesLocation', 'inventoryUnit'],
  CONTACT: ['_id', 'buyer', 'status', 'salesLocation'],
  INVENTORY: ['_id', 'unitLocation', 'serialA', 'serialB', 'model', 'hudA', 'hudB', 'isRSO']
};

export const defaultSitesLatLong = { lat: 31.9686, lng: -99.9018 };
