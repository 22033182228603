import { cloneDeep, isArray, uniqueId } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Card, Col, Container, Dropdown, Form, FormControl, Row, Table } from 'react-bootstrap';
import {
  ArrowUpRightCircle,
  Check,
  Files,
  InfoSquareFill,
  Pen,
  PencilSquare,
  PlusCircle,
  PlusCircleFill,
  Trash2Fill,
  X
} from 'react-bootstrap-icons';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { findDifferenceInObjects, setAttribute } from '../../form-generator/helpers/utility';
import { deleteFileFromServer, makeApiRequests } from '../../helpers/api';
import {
  ENDPOINTS,
  RETURN_PATHS,
  contactSearchSortByOptions,
  contactToggleOptions,
  createFilterOptionsForContact,
  isMicasa
} from '../../helpers/constants';
import { addDocHubFormFieldsToForm, convertKeyValueToFormObject, getObjectForPrefill } from '../../helpers/formHelpers';
import { copyContactAddress, copyContactDetails, isObjectIncomplete, openLinkInNewTab } from '../../helpers/global';
import { getProfileFromLocalStorage, getSalesRepOfSalesManager } from '../../helpers/session';
import AlertModal from '../AlertModal';
import NotFound from '../NotFound';
import OverViewColumns from '../OverViewColumns';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import FileViewer from '../common/FileViewer';
import FloatingButton from '../common/FloatingButton';
import HorizontalProgress from '../common/HorizontalProgress';
import QuickToggles from '../common/QuickToggles';
import File from '../common/file';
import SearchBox from '../common/searchbox';
import ContactList from '../crm/ContactList';
import CreateSideBar from '../crm/CreateSidebar';
import { addChoicesToCRMForm, editFormJson, validateAndCleanupCRMForm } from '../crm/form';
import EditInventory from '../inventory/EditInventory';
import InventoryPicker from '../inventory/InventoryPicker';
import PotentialUnitsOverview from '../inventory/PotentialUnitsOverview';
import ContactInfoSideBar from './ContactInfoSidebar';
import DochubSidebar from './DochubSidebar';
import ListFilterText from './ListFilterText';
import PipelineLoaderCard from './PipelineLoaderCard';
import StatusTabs from './StatusTabs';
import TextButton from '../TextButton';
import { DropDownInput } from '../../form-generator/components';

const staticSectionDateFields = [
  'dateFormTSent',
  'dateSigned',
  'dateRebateRequested',
  'dateRebateReceived',
  'dateACRebateRequested',
  'dateACRebateReceived'
];
const staticSectionDateTimeFields = ['setup'];

const staticSectionPriceFields = ['salesPrice', 'downPayment', 'amountRebateReceived'];
const staticSectionEmailFields = ['buyerEmail', 'coBuyerEmail'];
const staticSectionPhoneFields = ['cellPhone', 'cellPhone2'];
const staticSectionDropdownFields = ['salesLocation', 'lender', 'installer'];
const staticSectionUrlFields = ['pindropUrl'];

const OptionalEquipmentAndAccessorTable = ({ data, field, onChange, closePopup }) => {
  const [initialData, setInitialData] = useState([]);
  const inputRefs = useRef({});

  const fields = [
    { title: 'Optional Equipment And Accessories', key: 'key' },
    { title: 'Optional Equipment And Accessories', key: 'value' }
  ];
  console.log({ data });
  useEffect(() => {
    setInitialData(data?.map(d => ({ ...d, id: uniqueId() })) || []);
  }, [data]);

  const handleInputChange = (id, fieldKey, value) => {
    const updatedData = initialData.map(row => (row.id === id ? { ...row, [fieldKey]: value } : row));
    setInitialData(updatedData);
  };
  const deleteRow = id => {
    setInitialData(initialData?.filter(d => d.id !== id));
  };

  const onUpdate = () => {
    onChange(
      field,
      initialData?.map(d => ({ ...d, value: isNaN(Number(d?.value)) || !Number(d?.value) ? 'Inc' : Number(d?.value) }))
    );
    closePopup();
  };
  return (
    <div className="m-2" style={{ width: 580 }}>
      <Table bordered className="rounded m-0" responsive>
        <thead>
          <tr className="bg-primary text-white smallFont">
            {[...fields, { title: 'Action' }].map((field, index) => (
              <th key={index} className="text-center py-1 px-0">
                {field.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {initialData.map(d => (
            <tr key={d.id} className="p-0">
              {fields.map(field => (
                <td key={`${field.key}-${d.id}`} className="p-1">
                  <FormControl
                    size="sm"
                    className="smallFont"
                    value={field.key === 'key' ? d[field.key] : Number(d[field.key])}
                    type={field.key === 'key' ? 'text' : 'number'}
                    onChange={e => handleInputChange(d.id, field.key, e.target.value)}
                    ref={el => (inputRefs.current[`${field.key}-${d.id}`] = el)}
                  />
                </td>
              ))}
              <td align="center" className="p-1">
                <Trash2Fill
                  className="smallFont text-danger pointer"
                  title="Delete This Row"
                  onClick={() => deleteRow(d?.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
        {initialData.length === 0 && (
          <tfoot>
            <tr>
              <td className="mid text-center" colSpan={3}>
                Nothing to show
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
      <div className="d-flex justify-content-end mt-2">
        <TextButton
          Icon={PlusCircleFill}
          variant="success"
          text="Add A Row"
          onClick={() => setInitialData([...initialData, { id: uniqueId(), key: '', value: '' }])}
        />
      </div>
      <div className="d-flex justify-content-end mt-2">
        <Button size="sm" className="mx-1 px-1 py-0" variant="danger" onClick={closePopup}>
          Close
        </Button>
        <Button size="sm" className="mx-1 px-1 py-0" onClick={onUpdate}>
          Update
        </Button>
      </div>
    </div>
  );
};

const InventoryEditSideBar = ({ editingInventory, appChoices, onHide, onInventoryDelete, onInventoryEdit }) => {
  return (
    <SlidingSideBar fullScreen visible={editingInventory} onClose={onHide} title={'Inventory Details'}>
      {editingInventory && (
        <EditInventory
          id={editingInventory['_id']}
          inventoryFromSearch={editingInventory}
          appChoices={appChoices}
          fromSearch
          onInventoryDelete={onInventoryDelete}
          onInventoryEdit={onInventoryEdit}
        />
      )}
    </SlidingSideBar>
  );
};

const CRMPipeline = ({ appChoices }) => {
  const history = useHistory();

  const [role] = useState(localStorage.getItem('user-role'));
  const [statuses, setStatuses] = useState(
    appChoices.find(choice => choice.key === 'Contact Status').values.map(s => ({ name: s }))
  );
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);

  const [activeContact, setActiveContact] = useState(null);

  const [activeStatusObject, setActiveStatusObject] = useState(statuses[0]);

  const [form, setForm] = useState(null);
  const [toggling, setToggling] = useState(false);
  const [allListMode, setAllListMode] = useState(true);

  const [sidebarMode, setSidebarMode] = useState(null);
  const [inventoryPopupMeta, setInventoryPopupMeta] = useState(null);
  const [assigningInventory, setAssigningInventory] = useState(false);
  const [removeUnitAlertMeta, setRemoveUnitAlertMeta] = useState(null);
  const [removingInventory, setRemovingInventory] = useState(false);
  const [editingInventory, setEditingInventory] = useState(null);
  const [zipCodes, setZipCodes] = useState([]);
  const [createCrmMeta, setCreateCrmMeta] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [importInventoryPopupMeta, setImportInventoryPopupMeta] = useState(null);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);
  const [hideForm, setHideForm] = useState(false);

  const [showDateOfDepositeDateCell, setShowDateOfDepositeDateCell] = useState(null);
  const [dateOfDepositeInUpdate, setDateOfDepositeInUpdate] = useState(false);

  const [openDochubMeta, setOpenDochubMeta] = useState(null);
  const [overviewColumnEditMeta, setOverviewColumnEditMeta] = useState({});

  const [selectedInventoryForCalculation, setSelectedInventoryForCalculation] = useState(null);

  useEffect(() => {
    setSelectedInventoryForCalculation(activeContact?.inventoryUnit?.[0]);
  }, [activeContact]);

  const staticSectionFields = useMemo(
    () => [
      {
        key: 'salesLocation',
        label: 'Sales Location',
        isObject: true,

        objectKey: 'location',
        options:
          appChoices.find(ac => ac.key === 'salesLocation')?.values.map(v => ({ label: v.location, value: v._id })) ||
          [],
        required: true
      },
      { key: 'salesperson', label: 'Salesperson' },
      { key: 'salesPrice', label: 'Sales Price' },
      {
        key: 'additionalFields',
        label: 'Curtailment Bonus',
        isObject: true,
        objectKey: 'curtailmentBonus',
        valueKey: 'curtailmentBonus'
      },
      {
        key: 'additionalFields',
        label: 'Team Leader Bonus',
        isObject: true,
        objectKey: 'teamLeaderBonus',
        valueKey: 'teamLeaderBonus'
      },
      {
        key: 'additionalFields',
        label: 'First Half Paid',
        isObject: true,
        objectKey: 'firstHalfPaid',
        valueKey: 'firstHalfPaid'
      },
      {
        key: 'additionalFields',
        label: 'Backend Advance Paid',
        isObject: true,
        objectKey: 'backendAdvancePaid',
        valueKey: 'backendAdvancePaid'
      },
      {
        key: 'optionalEquipmentAndAccessories',
        label: 'Optional Equipment And Accessories',
        type: 'custom',
        valueGetter: data => {
          return `$${data?.optionalEquipmentAndAccessories?.reduce((a, b) => {
            let val = Array.isArray(b) ? Number(b[1] || 0) : isNaN(Number(b?.value)) ? 0 : Number(b?.value);
            return a + val;
          }, 0)}`;
        },
        renderCustomField: (field, data, onChange, closePopup) => (
          <OptionalEquipmentAndAccessorTable
            field={field}
            data={data.map(d => (Array.isArray(d) ? { key: d[0], value: d[1] } : d))}
            onChange={onChange}
            closePopup={closePopup}
          />
        )
      },
      { key: 'downPayment', label: 'Down Payment' },
      { key: 'taxFactorAdjustedSalesPrice', label: 'MHIT' },
      { key: 'applicationNumber', label: 'Application #' },
      {
        key: 'lender',
        label: 'Lender',
        isObject: true,
        objectKey: 'lienholderCorporateName',
        options:
          appChoices
            .find(ac => ac.key === 'lender')
            ?.values.map(v => ({ label: v.lienholderCorporateName, value: v._id })) || []
      },

      { key: 'pindropUrl', label: 'Pindrop Url' },
      { key: 'propertyAddress', label: 'Property Address', required: true },
      { key: 'propertyCity', label: 'Property City', required: true },
      { key: 'propertyState', label: 'Property State', required: true },
      {
        key: 'zipCode',
        label: 'Zip Code',
        required: true,
        onChange: value => {
          let zipCodes = appChoices.find(a => a.key === 'zipCodes')['values'];
          const zipCodeFiltered = zipCodes.filter(zip => value == zip['Zip Code']);

          if (zipCodeFiltered[0]) {
            let propertyState = zipCodeFiltered[0]['Property State'];
            let propertyCity = zipCodeFiltered[0]['Property City'];
            let propertyCounty = zipCodeFiltered[0]['Property County'];
            return { propertyCity, propertyCounty, propertyState };
          }
          return {};
        }
      },
      { key: 'propertyCounty', label: 'Property County', required: true },
      { key: 'cellPhone', label: 'Cell Phone', required: true },
      { key: 'cellPhone2', label: 'Cell Phone #2' },
      { key: 'buyerEmail', label: 'Buyer Email' },
      { key: 'coBuyerEmail', label: 'Co-Buyer Email' },
      { key: 'dateSigned', label: 'Date Signed' }
    ],
    [appChoices]
  );

  const calculatedValues = useMemo(() => {
    const inventoryInvoice = Number(selectedInventoryForCalculation?.invoice || 0); // WIP
    const salesPrice = Number(activeContact?.salesPrice || 0);

    // active contact is converted to formgenerator needed form so we are taking value from array
    const commissionLessValue = activeContact?.commissionSheetLessOptions?.reduce((a, b) => a + Number(b[1] || 0), 0) || 0;
    const netCommissionsTotal = salesPrice - commissionLessValue;

    const optionsTotal =
      inventoryInvoice + activeContact?.commissionSheetOptions?.reduce((a, b) => a + Number(b[1] || 0), 0) || 0;
    const grossProfit = netCommissionsTotal - optionsTotal;

    let commissionDue;
    let bonus = 0;

    if (isMicasa) {
      bonus = Number(activeContact?.additionalFields?.bonus || 0);
      commissionDue = grossProfit >= 20000 ? 0.25 * grossProfit : 0.2 * grossProfit;
    } else {
      commissionDue = grossProfit / 2;
    }

    const curtailmentBonus = Number(activeContact?.additionalFields?.curtailmentBonus || 0);
    const teamLeaderBonus = Number(activeContact?.additionalFields?.teamLeaderBonus || 0);

    const commissionDueWithBonus = commissionDue + curtailmentBonus + teamLeaderBonus;
    const frontEnd = commissionDueWithBonus / 2;

    const firstHalfPaid = Number(activeContact?.additionalFields?.firstHalfPaid || 0);
    const backendAdvancePaid = Number(activeContact?.additionalFields?.backendAdvancePaid || 0);

    const balanceToBePaid = commissionDueWithBonus - firstHalfPaid;
    const balanceAfterAdvance = balanceToBePaid - backendAdvancePaid;

    return {
      netCommission: netCommissionsTotal.toFixed(2),
      totalOfOptions: optionsTotal.toFixed(2),
      grossProfit: grossProfit.toFixed(2),
      commissionDue: commissionDue.toFixed(2),
      commissionDueWithBonus: commissionDueWithBonus.toFixed(2),
      frontEnd: frontEnd.toFixed(2),
      balanceToBePaid: balanceToBePaid.toFixed(2),
      balanceAfterAdvance: balanceAfterAdvance.toFixed(2),
      backEnd: frontEnd.toFixed(2)
    };
  }, [activeContact, selectedInventoryForCalculation]);

  const fetchContacts = async ({ sortBy, maxLimit, descSort, filters, query }) => {
    setLoading(true);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_SEARCH_NEW,
      requestBody: {
        filter: filters,
        sort: { [sortBy]: descSort ? -1 : 1 },
        query,
        pageNumber: 1,
        pageSize: maxLimit === 'All' ? undefined : maxLimit,
        returnPaths: RETURN_PATHS.CONTACT,
        populate: false
      }
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setAllListMode(true);
    setContacts(response.results);
  };

  const filterContacts = () => {
    setFilteredContacts(
      allListMode || !activeStatusObject ? [...contacts] : contacts.filter(c => c['status'] === activeStatusObject.name)
    );
  };

  useEffect(() => {
    setStatuses(statuses.map(s => ({ ...s, count: contacts.filter(r => r['status'] === s.name).length })));
  }, [contacts]);

  useEffect(() => filterContacts(), [contacts, activeStatusObject, allListMode]);

  useEffect(() => {
    const activeInFilteredIndex = activeContact
      ? filteredContacts.findIndex(c => c['_id'] === activeContact['_id'])
      : -1;
    const contact = filteredContacts[activeInFilteredIndex !== -1 ? activeInFilteredIndex : 0];
    updateContactState(contact);
  }, [filteredContacts]);

  const setUpForm = () => {
    const form = cloneDeep(editFormJson(role, true));
    const newFormJson = addDocHubFormFieldsToForm({ formJson: form, appChoices, model: 'Contact' });
    addChoicesToCRMForm({ appChoices, form: newFormJson, ignoreTableFields: true });
    setZipCodes(appChoices.find(a => a.key === 'zipCodes')['values']);

    if (['super admin', 'admin', 'sales manager'].includes(role)) {
      const members = (['super admin', 'admin'].includes(role)
        ? appChoices
            .find(c => c.key === 'members')
            .values.filter(m => ['super admin', 'admin', 'sales', 'sales manager'].includes(m.role.toLowerCase()))
        : [getProfileFromLocalStorage(), ...getSalesRepOfSalesManager()]
      ).sort((m1, m2) => m1.name.localeCompare(m2.name));

      setAttribute(
        newFormJson,
        'contactOwner',
        'options',
        members.map(s => s['name'])
      );
      setAttribute(
        newFormJson,
        'contactOwner',
        'optionValues',
        members.map(s => s['_id'])
      );
    }

    setForm(newFormJson);
  };

  useEffect(() => {
    setUpForm();
  }, []);

  useEffect(() => {
    setUploadedFiles([]);

    if (!activeContact) {
      setActiveStatusObject(null);
      return;
    }

    setActiveStatusObject(statuses.find(s => s.name === activeContact['status']));

    setHideForm(true);

    setTimeout(() => {
      setHideForm(false);
    }, 100);

    if (!activeContact.hasOwnProperty('loadingError') && isObjectIncomplete(activeContact, RETURN_PATHS.CONTACT)) {
      fetchCompleteContact(activeContact);
    }
  }, [activeContact]);

  const fetchCompleteContact = async activeContact => {
    updateContactList({ ...activeContact, loadingError: false });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_SEARCH_NEW,
      requestBody: {
        filter: { _id: activeContact._id },
        populate: true
      }
    });

    if (error) {
      updateContactList({ ...activeContact, loadingError: true });
      toast.error(error);
      return;
    }

    updateContactList(response.results[0]);
  };

  const updateContactState = contact => {
    if (!contact) return setActiveContact(null);

    const clonedContact = cloneDeep(contact);

    convertKeyValueToFormObject(clonedContact, appChoices);
    setActiveContact(clonedContact);

    setLoading(false);
  };

  const onCrmEditFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    //checks
    const valid = validateAndCleanupCRMForm(form);
    if (!valid) return;

    form['files'] = [...activeContact.files.map(f => f._id), ...uploadedFiles.map(f => f.id)];

    toast.info('Please wait, processing contact..');

    const formData = findDifferenceInObjects(form, activeContact, ['_id']);
    const { response, error } = await makeApiRequests({
      requestBody: formData,
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact._id),
      method: 'PUT'
    });

    if (error) {
      return toast.error(error);
    }

    toast.success(`Contact updated successfully!`);

    updateContactList(response);

    //if status has been changed, show status info as well
    if (Object.keys(formData).includes('status')) {
      toast.info(`Contact ${response['buyer']} moved to ${response['status']}.`);
    }
  };

  const autoFillMailingAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('mailingZipCodePipeline').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('mailingStatePipeline').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('mailingCityPipeline').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('mailingCountyPipeline').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  const autoFillAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('mailingZipCodePipeline').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('propertyStatePipeline').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('propertyCityPipeline').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('propertyCountyPipeline').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  window['autoFillMailingAddress'] = autoFillMailingAddress;
  window['autoFillAddress'] = autoFillAddress;
  window['onCrmEditFormSubmit'] = onCrmEditFormSubmit;

  const onContactSelect = contact => {
    const index = filteredContacts.findIndex(c => c['_id'] === contact['_id']);
    if (index !== -1) updateContactState(filteredContacts[index]);
  };

  const updateContactList = (contact, forFile) => {
    const contactIndex = contacts.findIndex(c => c['_id'] === contact['_id']);
    if (contactIndex !== -1) {
      contacts[contactIndex] = contact;
      setContacts([...contacts]);
    } else {
      setContacts([contact, ...contacts]);
    }

    setCreateCrmMeta(null);
    if (!forFile) setSidebarMode(null);
  };

  const toggleContact = async (label, key, value) => {
    setToggling(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact._id),
      requestBody: { [key]: value },
      method: 'PUT'
    });

    setToggling(false);
    if (error) {
      toast.error(error);
      return;
    }

    updateContactList(response);

    //if status has been changed, show status info as well
    if (key === 'status') {
      toast.info(`Contact ${response['buyer']} moved to ${response['status']}.`);
    } else {
      toast.success(`Contact's ${label} updated successfully!`);
    }

    setToggling(false);
  };

  const onContactInfoClick = () => {
    setSidebarMode('view');
  };

  const onContactEditClick = () => {
    setSidebarMode('edit');
  };

  const onContactDelete = contactId => {
    const index = contacts.findIndex(c => c['_id'] === contactId);
    if (index !== -1) {
      contacts.splice(index, 1);
      setContacts([...contacts]);
    }
    setSidebarMode(null);
  };

  const onContactInfoSideBarHide = () => {
    setSidebarMode(null);
  };

  const onLinkUnitClick = replacingInventoryUnit => {
    setInventoryPopupMeta({ replacingInventoryUnit });
  };

  const onInventorySubmit = async inventoryUnits => {
    const inventoryUnit = inventoryUnits[0];
    setAssigningInventory(true);

    let newUnitIds;
    if (activeContact['inventoryUnit']) {
      newUnitIds = activeContact['inventoryUnit'].map(u => u._id);
      if (inventoryPopupMeta['replacingInventoryUnit']) {
        const replacingIndex = newUnitIds.indexOf(inventoryPopupMeta['replacingInventoryUnit']['_id']);
        newUnitIds[replacingIndex] = inventoryUnit['_id'];
      } else {
        newUnitIds.push(inventoryUnit['_id']);
      }
    } else {
      newUnitIds = [inventoryUnit['_id']];
    }

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact['_id']),
      requestBody: { inventoryUnit: newUnitIds },
      method: 'PUT'
    });

    setAssigningInventory(false);

    if (error) {
      toast(error, {
        type: 'error'
      });
      return;
    }

    toast(`Inventory unit updated successfully!`, {
      type: 'success'
    });

    updateContactList(response);
    setInventoryPopupMeta(null);
  };

  const onRemoveUnitClick = removingInventoryUnit => {
    setRemoveUnitAlertMeta({ removingInventoryUnit });
  };

  const removeInventoryUnit = async () => {
    setRemovingInventory(true);

    let newUnitIds = activeContact['inventoryUnit'].map(u => u._id);
    const removingIndex = newUnitIds.indexOf(removeUnitAlertMeta['removingInventoryUnit']['_id']);
    newUnitIds.splice(removingIndex, 1);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact['_id']),
      requestBody: { inventoryUnit: newUnitIds },
      method: 'PUT'
    });

    setRemovingInventory(false);

    if (error) {
      toast(error, {
        type: 'error'
      });
      return;
    }

    toast(`Inventory unit removed successfully!`, {
      type: 'success'
    });

    updateContactList(response);
    setRemoveUnitAlertMeta(null);
  };

  const onInventoryEdit = updatedInventory => {
    const inventoryUnits = activeContact['inventoryUnit'];

    const existingIndex = inventoryUnits.findIndex(u => u['_id'] === updatedInventory['_id']);
    if (existingIndex !== -1) {
      activeContact['inventoryUnit'][existingIndex] = updatedInventory;
    }

    updateContactList(activeContact);
    setEditingInventory(null);
  };

  const onInventoryDelete = deletedInventoryId => {
    const inventoryUnits = activeContact['inventoryUnit'];

    const existingIndex = inventoryUnits.findIndex(u => u['_id'] === deletedInventoryId);
    if (existingIndex !== -1) {
      activeContact['inventoryUnit'].splice(existingIndex, 1);
    }

    updateContactList(activeContact);
    setEditingInventory(null);
  };

  const onUnitSelect = unit => {
    setInventoryPopupMeta({ ...inventoryPopupMeta, selectedUnit: unit });
  };

  const onImportClick = () => {
    const importContact = contacts.find(c => c['_id'] === activeContact['_id']);

    if (importContact['inventoryUnit']?.length > 1) {
      setImportInventoryPopupMeta({ crm: importContact });
      return;
    }

    history.push({
      pathname: '/contract/create',
      state: {
        preFillValues: importContact,
        inventoryUnit: importContact['inventoryUnit']?.[0]
      }
    });
  };

  const onDuplicateClick = () => {
    setCreateCrmMeta({ prefillContact: { ...activeContact } });
  };

  const onImportInventorySubmit = inventoryList => {
    const inventory = inventoryList[0];
    const { crm } = importInventoryPopupMeta;

    history.push({
      pathname: '/contract/create',
      state: {
        preFillValues: crm,
        inventoryUnit: inventory
      }
    });
  };

  const onImportUnitSelect = unit => {
    setImportInventoryPopupMeta({ ...importInventoryPopupMeta, selectedUnit: unit });
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = activeContact.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact._id),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    updateContactList(response);
    deleteFileFromServer(toBeDeletedFile);
  };

  const editDateOfDeposite = async () => {
    if (!showDateOfDepositeDateCell) {
      return toast.error('Date of deposite Cannot Be Empty/');
    }
    setDateOfDepositeInUpdate(true);

    const { response: updatedContract, error } = await makeApiRequests({
      requestBody: { dateOfDeposit: new Date(showDateOfDepositeDateCell)?.toISOString() },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact._id)
    });

    if (error) {
      setDateOfDepositeInUpdate(false);
      return toast.error(error);
    }

    toast.success('Date of deposite Updated Successfully');
    setShowDateOfDepositeDateCell(null);
    setActiveContact(updatedContract);
    setDateOfDepositeInUpdate(false);
  };

  const onOverviewColumnsSubmit = async form => {
    setOverviewColumnEditMeta({ ...overviewColumnEditMeta, showProgress: true });

    toast.info('Saving contact...');

    const { response: updatedContract, error } = await makeApiRequests({
      requestBody: form,
      method: 'PUT',
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact._id)
    });

    setOverviewColumnEditMeta({ ...overviewColumnEditMeta, showProgress: false });
    if (error) {
      return toast.error(error);
    }

    toast.success(`Contact updated successfully!`);
    setOverviewColumnEditMeta({ ...overviewColumnEditMeta, isEditing: false });
    updateContactList(updatedContract);
  };

  const handleOpenDochub = () => {
    setOpenDochubMeta({ contactId: activeContact?._id });
  };

  return (
    <Container fluid className={'h-100 pt-2 pb-3 px-md-2'}>
      <SearchBox
        appChoices={appChoices}
        filterCreationOptions={createFilterOptionsForContact(role)}
        sortByOptions={contactSearchSortByOptions}
        maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
        onSearchOptionsChange={fetchContacts}
        disabled={loading}
      />
      {loading && <HorizontalProgress text={'Fetching contacts...'} />}
      <hr />

      {contacts.length > 0 ? (
        <Row className="h-100 px-md-3">
          <Col xs={4} md={2} className="p-0 pb-5 overflow-auto h-100">
            <ListFilterText allListMode={allListMode} onClearFilterClick={() => setAllListMode(true)} />
            <ContactList
              contacts={filteredContacts}
              activeId={activeContact ? activeContact['_id'] : ''}
              onSelect={onContactSelect}
              appChoices={appChoices}
            />
          </Col>
          <Col xs={8} md={10} className="h-100 overflow-auto px-1 mb-5">
            <StatusTabs
              statuses={statuses}
              activeStatusObject={activeStatusObject}
              onStatusClick={s => {
                setAllListMode(false);
                setActiveStatusObject(s);
              }}
            />
            <Card className="mt-2 mb-5">
              {activeContact ? (
                isObjectIncomplete(activeContact, RETURN_PATHS.CONTACT) ? (
                  <PipelineLoaderCard
                    loadingError={activeContact?.loadingError}
                    title={[activeContact['buyer'], activeContact['coBuyer']].filter(Boolean).join(', ')}
                    onRetryClick={() => fetchCompleteContact(activeContact)}
                  />
                ) : (
                  <>
                    <Card.Header className="bg-primary text-white px-2 py-1  smallFont">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h6 className="mb-0 d-inline-block midFont">
                            <Link
                              className=" text-white"
                              to={'/crm/' + activeContact['_id']}
                              target="_blank"
                              onClick={e => e.stopPropagation()}
                            >
                              {activeContact['buyer']}
                              {activeContact['coBuyer'] ? `, ${activeContact['coBuyer']}` : ''}
                              <ArrowUpRightCircle size={14} />
                            </Link>
                          </h6>
                          <InfoSquareFill className="align-text-bottom ml-2 hover-light" onClick={onContactInfoClick} />
                          <PencilSquare className="align-text-bottom mx-2 hover-light" onClick={onContactEditClick} />

                          {activeContact['manufacturer'] && activeContact['nanufacturer'].isNPS && (
                            <Badge className="align-text-bottom mr-2" variant={'info'}>
                              NPS Sale
                            </Badge>
                          )}
                          {!showDateOfDepositeDateCell ? (
                            <Badge className="align-text-bottom ml-2 mt-1" variant={'success'} title="Date of Deposit">
                              <div className="d-flex justify-content-between align-items-center">
                                {/* todo Update */}
                                {activeContact?.dateOfDeposit
                                  ? `Deposit : ${moment(activeContact?.dateOfDeposit)?.format('MMMM Do YYYY')}`
                                  : 'No Date of Deposit'}
                                {/* todo Update */}
                                <Pen
                                  className="ml-2 text-light smallFont pointer"
                                  size={8}
                                  onClick={() =>
                                    setShowDateOfDepositeDateCell(
                                      activeContact?.dateOfDeposit
                                        ? moment(activeContact?.dateOfDeposit)?.format('YYYY-MM-DD')
                                        : moment()?.format('YYYY-MM-DD')
                                    )
                                  }
                                />
                              </div>
                            </Badge>
                          ) : (
                            <Badge className=" ml-2 ">
                              <span className="d-flex justify-content-start align-items-center ">
                                <Form.Control
                                  type="date"
                                  placeholder="Call Date"
                                  size="sm"
                                  className="tinyFont w-100"
                                  value={showDateOfDepositeDateCell || null}
                                  onChange={e => setShowDateOfDepositeDateCell(e.target.value)}
                                  disabled={dateOfDepositeInUpdate}
                                />
                                <Button
                                  className="p-0 ml-1"
                                  variant="success"
                                  onClick={() => editDateOfDeposite()}
                                  disabled={dateOfDepositeInUpdate}
                                >
                                  <Check />
                                </Button>
                                <Button
                                  className="p-0 ml-1"
                                  variant="danger"
                                  onClick={() => setShowDateOfDepositeDateCell(null)}
                                  disabled={dateOfDepositeInUpdate}
                                >
                                  <X />
                                </Button>
                              </span>
                            </Badge>
                          )}
                        </div>

                        <div className="align-self-center">
                          {['super admin', 'admin', 'sales', 'sales manager'].includes(role) && (
                            <Dropdown className="d-inline-block">
                              <Dropdown.Toggle className=" ml-2 px-1 py-0" size="sm" variant="info">
                                Print
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={handleOpenDochub}>Commission Sheet</Dropdown.Item>
                                <Dropdown.Item
                                  onClick={e => {
                                    openLinkInNewTab(
                                      `/dochub/print-groups?contactId=${activeContact['_id']}&source=Contact`
                                    );
                                  }}
                                >
                                  Open Dochub
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                          <Button
                            onClick={() => setShowFileViewer(true)}
                            variant="dark"
                            size="sm"
                            className="py-0 px-1 mx-1"
                          >
                            <Files className="align-text-top mr-2" />
                            View Files
                          </Button>
                          <Dropdown className="d-inline-block">
                            <Dropdown.Toggle className=" mx-1 px-1 py-0" size="sm" variant="warning">
                              Copy
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {activeContact?.inventoryUnit?.length > 1 ? (
                                activeContact?.inventoryUnit.map((o, i) => (
                                  <Dropdown.Item
                                    key={o._id}
                                    onClick={e => {
                                      copyContactDetails(activeContact, activeContact?.inventoryUnit?.[i]);
                                      toast.success('Copied Contact!');
                                    }}
                                  >
                                    Contact ({o?.serialA})
                                  </Dropdown.Item>
                                ))
                              ) : (
                                <Dropdown.Item
                                  onClick={e => {
                                    copyContactDetails(
                                      activeContact,
                                      isArray(activeContact?.inventoryUnit) && activeContact?.inventoryUnit?.length
                                        ? activeContact?.inventoryUnit?.[0]
                                        : {}
                                    );
                                    toast.success('Copied Contact!');
                                  }}
                                >
                                  Contact
                                </Dropdown.Item>
                              )}

                              <Dropdown.Item
                                onClick={e => {
                                  copyContactAddress(activeContact);
                                  toast.success('Copied Address!');
                                }}
                              >
                                Address
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {['super admin', 'admin'].includes(role) && (
                            <Button onClick={onImportClick} size="sm" variant="success" className="py-0 px-1 mx-2">
                              Import
                            </Button>
                          )}
                          <Button onClick={onDuplicateClick} size="sm" variant="success" className="py-0 px-1">
                            Duplicate
                          </Button>
                        </div>
                      </div>
                    </Card.Header>
                    {!hideForm && !createCrmMeta && sidebarMode === null && !editingInventory && !openDochubMeta && (
                      <Card.Body className="p-2 fade-in">
                        {activeContact['exportedContract'] && (
                          <>
                            <h6 className="midFont text-right text-muted  mt-3 mb-0">
                              <i>
                                This contact has already been exported to contract{' '}
                                <Link target="_blank" to={`/contract/${activeContact['exportedContract']._id}`}>
                                  <b className="text-dark">{activeContact['exportedContract']['buyer']}</b>
                                </Link>
                                . You are not allowed to edit it.
                              </i>
                            </h6>
                            <hr className="my-2" />
                          </>
                        )}
                        <div class="border border-dark p-2 rounded bg-light mb-2">
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <h6 className="underline flex-grow-1">Calculated Values:</h6>
                            {activeContact?.inventoryUnit?.length > 0 && (
                              <select
                                value={selectedInventoryForCalculation?._id}
                                onChange={e =>
                                  setSelectedInventoryForCalculation(
                                    activeContact?.inventoryUnit?.find(i => i._id === e.target.value)
                                  )
                                }
                                style={{ fontSize: 12 }}
                                className="form-control w-25 form-control-sm mb-0  text-dark px-2 py-1 bg-primary-light rounded"
                              >
                                {activeContact?.inventoryUnit.map(o => (
                                  <option key={o._id} value={o._id}>
                                    {o?.serialA}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                          <div class="row text-dark midFont">
                            <div class="col col-12 col-md-4 mb-1">
                              Net Commission: <b>${calculatedValues?.netCommission}</b>
                            </div>
                            <div class="col col-12 col-md-4 mb-1">
                              Total of Options: <b>${calculatedValues?.totalOfOptions}</b>
                            </div>
                            <div class="col col-12 col-md-4 mb-1">
                              Gross Profit: <b>${calculatedValues?.grossProfit}</b>
                            </div>
                            <div class="col col-12 col-md-4 mb-1">
                              Commission Due: <b>${calculatedValues?.commissionDue}</b>
                            </div>
                            <div class="col col-12 col-md-4 mb-1">
                              Commission Due with Bonus: <b>${calculatedValues?.commissionDueWithBonus}</b>
                            </div>
                            <div class="col col-12 col-md-4 mb-1">
                              Front End: <b>${calculatedValues?.frontEnd}</b>
                            </div>
                            <div class="col col-12 col-md-4 mb-1">
                              Back End: <b>${calculatedValues?.backEnd}</b>
                            </div>
                            <div class="col col-12 col-md-4 mb-1">
                              Balance to be Paid: <b>${calculatedValues?.balanceToBePaid}</b>
                            </div>
                            <div class="col col-12 col-md-4 mb-1">
                              Balance after Advance: <b>${calculatedValues?.balanceAfterAdvance}</b>
                            </div>
                          </div>
                        </div>
                        <OverViewColumns
                          className="px-3"
                          fontSize="smallFont"
                          md={2}
                          dateFields={staticSectionDateFields}
                          dateTimeFields={staticSectionDateTimeFields}
                          priceFields={staticSectionPriceFields}
                          emailRows={staticSectionEmailFields}
                          numRows={staticSectionPhoneFields}
                          dropdownFields={staticSectionDropdownFields}
                          urlFields={staticSectionUrlFields}
                          data={activeContact}
                          fieldsToShow={staticSectionFields}
                          editable={!activeContact['exportedContract']}
                          showProgress={overviewColumnEditMeta?.showProgress}
                          isEditing={overviewColumnEditMeta?.isEditing}
                          onToggleEditMode={() =>
                            setOverviewColumnEditMeta({
                              ...overviewColumnEditMeta,
                              isEditing: !overviewColumnEditMeta.isEditing
                            })
                          }
                          onSubmit={onOverviewColumnsSubmit}
                        />
                        <hr className="my-2" />

                        {!activeContact['exportedContract'] && (
                          <div className="mx-2">
                            <QuickToggles
                              data={activeContact}
                              toggleOptions={contactToggleOptions({ contact: activeContact, appChoices })}
                              onToggle={toggleContact}
                              toggling={toggling}
                            />
                          </div>
                        )}
                        <hr className="my-2" />
                        <div className="m-3">
                          <PotentialUnitsOverview
                            onLinkNewUnitClick={onLinkUnitClick}
                            onRemoveUnitClick={onRemoveUnitClick}
                            onChangeUnitClick={onLinkUnitClick}
                            inventoryUnits={activeContact['inventoryUnit']}
                            onEditUnitClick={unit => setEditingInventory(unit)}
                            disabled={activeContact['exportedContract']}
                          />
                        </div>
                        {!activeContact['exportedContract'] &&
                          ['super admin', 'admin', 'sales', 'sales manager'].includes(role) && (
                            <File
                              uploadedFiles={uploadedFiles}
                              onUploadedFilesChange={setUploadedFiles}
                              containerClassName="mx-3 mb-2"
                            />
                          )}
                        {form && !createCrmMeta && sidebarMode === null && !editingInventory && !openDochubMeta && (
                          <FormGenerator
                            prefix="crm-pipeline"
                            formJson={form}
                            formValues={{ update: getObjectForPrefill(activeContact) }}
                            formDisabled={
                              activeContact['exportedContract'] ||
                              !['super admin', 'admin', 'sales', 'sales manager'].includes(role)
                            }
                          />
                        )}
                        <hr />
                        <FileViewer
                          show={showFileViewer}
                          onHide={() => setShowFileViewer(false)}
                          files={activeContact['files']}
                          onFileDeleteClick={onFileDeleteClick}
                          driveFolder={activeContact['driveFolder']}
                        />
                        <AlertModal
                          show={deleteFileModalMeta !== null}
                          alertText={`Are you sure to delete this file (${
                            deleteFileModalMeta
                              ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                                ? deleteFileModalMeta.file.metadata.originalName
                                : deleteFileModalMeta.file.filename
                              : ''
                          }). You will lose access to this file permanently?`}
                          showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
                          progressText="Deleting file..."
                          continueButtonText="Delete"
                          onDismissClick={() => setDeleteFileModalMeta(null)}
                          onHide={() => setDeleteFileModalMeta(null)}
                          onContinueClick={deleteFile}
                        />
                      </Card.Body>
                    )}
                    {!activeContact['exportedContract'] && (
                      <>
                        <InventoryPicker
                          excludedUnitIds={
                            activeContact['inventoryUnit'] ? activeContact['inventoryUnit'].map(u => u['_id']) : []
                          }
                          selectedUnits={
                            inventoryPopupMeta && inventoryPopupMeta.selectedUnit
                              ? [inventoryPopupMeta.selectedUnit]
                              : []
                          }
                          onUnitSelect={onUnitSelect}
                          show={inventoryPopupMeta !== null}
                          submitting={assigningInventory}
                          onSubmit={onInventorySubmit}
                          onInventoryPickerClose={() => setInventoryPopupMeta(null)}
                        />
                        {/** FOR IMPORT [NEEDS TO BE MERGED TO SINGLE PICKER ABOVE] */}
                        <InventoryPicker
                          title="Select an inventory unit to be imported"
                          show={importInventoryPopupMeta !== null}
                          selectedUnits={
                            importInventoryPopupMeta && importInventoryPopupMeta.selectedUnit
                              ? [importInventoryPopupMeta.selectedUnit]
                              : []
                          }
                          onUnitSelect={onImportUnitSelect}
                          onSubmit={onImportInventorySubmit}
                          fetchedInventories={
                            importInventoryPopupMeta && importInventoryPopupMeta['crm']['inventoryUnit']
                          }
                          onInventoryPickerClose={() => setImportInventoryPopupMeta(null)}
                        />
                        <AlertModal
                          show={removeUnitAlertMeta != null}
                          alertText="Are you sure to remove this inventory unit from contact?"
                          showProgress={removingInventory}
                          progressText="Removing unit..."
                          onDismissClick={() => setRemoveUnitAlertMeta(null)}
                          onHide={() => setRemoveUnitAlertMeta(null)}
                          onContinueClick={removeInventoryUnit}
                        />
                        {activeContact['inventoryUnit'] && activeContact['inventoryUnit'].length > 0 && (
                          <InventoryEditSideBar
                            appChoices={appChoices}
                            onHide={() => setEditingInventory(null)}
                            editingInventory={editingInventory}
                            onInventoryEdit={onInventoryEdit}
                            onInventoryDelete={onInventoryDelete}
                          />
                        )}
                      </>
                    )}
                  </>
                )
              ) : (
                <>
                  <Card.Header className="bg-primary text-white p-2">
                    <h6 className="mb-0">Select a contact</h6>
                  </Card.Header>
                  <Card.Body>
                    <h6 className="p-5 text-center">No contact selected. Selected contact will appear here...</h6>
                  </Card.Body>
                </>
              )}
            </Card>
          </Col>
        </Row>
      ) : (
        !loading && <NotFound text="No contacts to show!" />
      )}
      <CreateSideBar
        onContactAdd={updateContactList}
        appChoices={appChoices}
        show={createCrmMeta}
        onHide={() => setCreateCrmMeta(null)}
        prefillContact={createCrmMeta?.prefillContact}
      />
      <FloatingButton
        variant="success"
        text={'Add New Contact'}
        Icon={PlusCircle}
        onClick={() => setCreateCrmMeta({})}
      />
      <ContactInfoSideBar
        appChoices={appChoices}
        contact={contacts?.find(c => c._id === activeContact?._id)}
        sidebarMode={sidebarMode}
        onContactDelete={onContactDelete}
        onContactEdit={updateContactList}
        onContactInfoSideBarHide={onContactInfoSideBarHide}
      />
      <DochubSidebar
        show={openDochubMeta}
        onHide={() => setOpenDochubMeta(null)}
        appChoices={appChoices}
        source={'Contact'}
        contactId={openDochubMeta?.contactId}
      />
    </Container>
  );
};

export default CRMPipeline;
