import React from 'react';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import PrintGroups from '../doc-hub/PrintGroups';

const DochubSidebar = ({ show, onHide, source, contractId, contactId, appChoices }) => {
  return (
    <SlidingSideBar fullScreen visible={show} onClose={onHide} title={'Print Commission Sheet'}>
      <PrintGroups
        appChoices={appChoices}
        source={source}
        contractId={contractId}
        contactId={contactId}
        sidebarMode={true}
        document={'Commission Sheet'}
      />
    </SlidingSideBar>
  );
};

export default DochubSidebar;
