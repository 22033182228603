import React, { useState } from 'react';
import { Dropdown, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { PersonCircle, Youtube } from 'react-bootstrap-icons';
import { DropdownSubmenu, NavDropdownMenu } from 'react-bootstrap-submenu';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../helpers/api';
import { ENDPOINTS } from '../helpers/constants';
import { downloadFileFromString } from '../helpers/global';
import { adminEntities } from './admin/constants';
import Logo from './common/Logo';

const getUserFirstName = () => {
  const name = localStorage.getItem('user-name') || '';
  if (name) return name.split(' ')[0];

  return name;
};

const Header = ({ noMenu = false, currentSectionVideoMeta, featuredVideoMeta, onOpenVideoPlayerChange }) => {
  const history = useHistory();
  const location = useLocation();
  const [role] = useState(localStorage.getItem('user-role'));

  const LinkItem = ({ dropdown = false, title, path, onClick, isLink = true }) => {
    return dropdown ? (
      <NavDropdown.Item onClick={onClick} href={path} active={isLink && location.pathname === path}>
        {title}
      </NavDropdown.Item>
    ) : (
      <Nav.Link onClick={onClick} href={path} active={isLink && location.pathname === path}>
        {title}
      </Nav.Link>
    );
  };

  const logout = () => {
    localStorage.clear();
    history.push('/login');
  };

  const ProfileMenuDropdownItems = ({ history }) => (
    <>
      <Dropdown.Toggle size="sm" variant="outline-dark rounded">
        <PersonCircle size={18} className="mr-2 align-text-top" />
        {getUserFirstName()}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => history.push('/profile')}>View Profile</Dropdown.Item>
        <Dropdown.Item onClick={() => logout(history)}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </>
  );

  const VideoMenuDropdownItems = () => (
    <>
      <Dropdown.Toggle size="sm" variant="outline-dark rounded">
        <Youtube size={18} className="align-text-top" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {featuredVideoMeta?.videoUrls?.length > 0 && (
          <Dropdown.Item onClick={() => onOpenVideoPlayerChange('featured')}>About this app</Dropdown.Item>
        )}
        {currentSectionVideoMeta?.videoUrls?.length > 0 && (
          <Dropdown.Item onClick={() => onOpenVideoPlayerChange('section')}>Videos for this page</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </>
  );

  const downloadContractedBuyersCSV = async () => {
    toast.info('File will be downloaded shortly...');

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_DOWNLOAD_CSV,
      method: 'GET'
    });

    if (error) {
      toast.error(error);
      return;
    }

    const { csv } = response;
    downloadFileFromString({ data: csv, fileName: 'Contracts.csv' });
  };

  return (
    <Navbar bg="light" expand="lg" style={{ fontSize: 15 }}>
      <Navbar.Brand className="mr-4" href="/">
        <Logo small />
      </Navbar.Brand>
      {!noMenu && (
        <>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {!['inventory manager'].includes(role) && (
                <NavDropdown
                  title="Contracts"
                  active={location.pathname.startsWith('/contract') || location.pathname === '/pipeline'}
                >
                  <LinkItem path="/contract/search" title="Search" dropdown />

                  {['super admin', 'admin'].includes(role) && (
                    <LinkItem path="/contract/create" title="Create" dropdown />
                  )}

                  {['super admin', 'admin', 'sales', 'sales manager', 'service', 'installer'].includes(role) && (
                    <LinkItem path="/pipeline" title="Pipeline" dropdown />
                  )}
                </NavDropdown>
              )}

              {role === 'service' && (
                <NavDropdown title="Service Requests" active={location.pathname.startsWith('/service-requests')}>
                  <LinkItem path="/service-requests/new" title="New requests" dropdown />
                  <LinkItem path="/service-requests/my-claims" title="My claims" dropdown />
                  <LinkItem path="/service-requests/browse" title="Browse requests" dropdown />
                </NavDropdown>
              )}

              {!['installer', 'tech', 'service', 'inventory manager'].includes(role) && (
                <LinkItem path="/service-requests/browse" title="Service requests" />
              )}

              {['super admin', 'admin', 'sales', 'sales manager', 'service', 'inventory manager'].includes(role) && (
                <LinkItem path="/dochub/print-groups" title="Doc hub" />
              )}

              {['sales', 'sales manager', 'admin', 'super admin'].includes(role) && (
                <NavDropdown
                  title="CRM"
                  active={location.pathname.startsWith('/crm') || location.pathname === '/pipeline/crm'}
                >
                  <LinkItem path="/crm/search" title="Search CRM" dropdown />
                  <LinkItem path="/crm/create" title="Create Contact" dropdown />
                  <LinkItem path="/pipeline/crm" title="Pipeline" dropdown />
                </NavDropdown>
              )}

              {role === 'tech' && <LinkItem path="/walkthrough/search" title="Walkthrough" />}

              {role === 'installer' && <LinkItem path="/setup/search" title="Set up" />}
              {!['inventory manager'].includes(role) && <LinkItem path="/schedule" title="Schedule" />}
              {['super admin', 'admin', 'service'].includes(role) && (
                <NavDropdownMenu
                  title="Reports"
                  className="mb-2 mb-lg-0"
                  active={location.pathname.startsWith('/reports')}
                >
                  {['super admin', 'admin'].includes(role) && (
                    <>
                      <LinkItem path="/reports/form-t" title="Form T" dropdown />
                      <LinkItem path="/reports/sol" title="SOL" dropdown />

                      <DropdownSubmenu title="Rebate">
                        <LinkItem path="/reports/request-rebate" title="Request Rebate" dropdown />
                        <LinkItem path="/reports/record-receipt" title="Record Receipt" dropdown />
                        <LinkItem path="/reports/rebate-report" title="Rebate Report" dropdown />
                        <LinkItem path="/reports/explore-rebate" title="Explore Rebate" dropdown />
                      </DropdownSubmenu>
                      <DropdownSubmenu title="AC Rebate">
                        <LinkItem path="/reports/request-ac-rebate" title="Request AC Rebate" dropdown />
                        <LinkItem path="/reports/record-ac-receipt" title="Record AC Receipt" dropdown />
                        <LinkItem path="/reports/ac-rebate-report" title="AC Rebate Report" dropdown />
                        <LinkItem path="/reports/explore-ac-rebate" title="Explore AC Rebate" dropdown />
                      </DropdownSubmenu>

                      <LinkItem path="/reports/mhit-report" title="MHIT Report" dropdown />
                      <LinkItem path="/reports/delivery-setup-report" title="Delivery/Setup Report" dropdown />
                      <LinkItem path="/reports/floorplan-update" title="Floorplan Update" dropdown />
                      <LinkItem path="/reports/curtailments" title="Curtailments" dropdown />
                      <LinkItem path="/reports/cash-deal" title="Cash Deal" dropdown />
                      <DropdownSubmenu title="Payroll">
                        <LinkItem path="/reports/record-payroll" title="Record Payroll" dropdown />
                        <LinkItem path="/reports/explore-payrolls" title="Explore Payroll" dropdown />
                      </DropdownSubmenu>
                      <LinkItem path="/reports/pnl" title="P&L Dealboard" dropdown />
                    </>
                  )}

                  <DropdownSubmenu title="Billback">
                    <LinkItem path="/reports/request-billback" title="Request Billback" dropdown />
                    <LinkItem path="/reports/record-billback-receipt" title="Record Billback Receipt" dropdown />
                    <LinkItem path="/reports/explore-billback" title="Explore Billback" dropdown />
                  </DropdownSubmenu>
                  <LinkItem path="/reports/service" title="Service" dropdown />
                </NavDropdownMenu>
              )}

              {['super admin', 'admin', 'sales', 'sales manager', 'service', 'inventory manager'].includes(role) && (
                <NavDropdown
                  title="Inventory"
                  active={location.pathname.startsWith('/inventory') || location.pathname === '/pipeline/inventory'}
                >
                  <LinkItem path="/inventory/browse" title="Browse Inventory" dropdown />
                  <LinkItem path="/inventory/create" title="Create Inventory Item" dropdown />
                  <LinkItem path="/pipeline/inventory" title="Pipeline" dropdown />
                  <LinkItem path="/inventory/site-map" title="Site Map" dropdown />
                </NavDropdown>
              )}

              {['sales', 'sales manager', 'super admin', 'admin', 'service'].includes(role) && (
                <NavDropdown title="Utils" active={location.pathname.startsWith('/utilities')}>
                  {['sales', 'sales manager', 'super admin', 'admin'].includes(role) && (
                    <>
                      <LinkItem path="/utilities/delivery-set-calculator" title="Del & set calculator" dropdown />
                      <LinkItem
                        path="#"
                        title="Download Contracted Buyers"
                        dropdown
                        onClick={downloadContractedBuyersCSV}
                      />
                    </>
                  )}
                  <LinkItem path="/utilities/commission-sheet" title="Commission Sheet" dropdown />
                  <LinkItem path="/utilities/sales-agreement" title="Sales Agreement" dropdown />
                </NavDropdown>
              )}

              {['super admin', 'admin'].includes(role) && (
                <NavDropdown title="Admin" active={location.pathname.startsWith('/admin')}>
                  <LinkItem path="/admin/users" title="Users" dropdown />
                  <LinkItem path="/admin/sales-location" title="Sales Location" dropdown />
                  <LinkItem path="/admin/section-video" title="Section Videos" dropdown />

                  <LinkItem path="/admin/app-choices" title="App Choices" dropdown />
                  {adminEntities.map(e => (
                    <LinkItem path={`/admin${e.route}`} title={e.name} dropdown />
                  ))}
                  <LinkItem path="/admin/lot-sets" title="Lot-Set Options" dropdown />
                  <LinkItem path="/admin/dochub-fields" title="DocHub Fields" dropdown />
                </NavDropdown>
              )}
            </Nav>
            <div>
              {(currentSectionVideoMeta?.videoUrls?.length > 0 || featuredVideoMeta?.videoUrls?.length > 0) && (
                <>
                  <Dropdown className="d-none d-lg-inline-block mr-1" style={{ zIndex: 999 }} drop="left">
                    <VideoMenuDropdownItems />
                  </Dropdown>
                  <Dropdown className="d-inline-block d-lg-none mt-1" style={{ zIndex: 999 }}>
                    <VideoMenuDropdownItems />
                  </Dropdown>
                </>
              )}

              <Dropdown className="d-none d-lg-inline-block" style={{ zIndex: 999 }} drop="left">
                <ProfileMenuDropdownItems history={history} />
              </Dropdown>
              <Dropdown className="d-inline-block d-lg-none ml-2 mt-1" style={{ zIndex: 999 }}>
                <ProfileMenuDropdownItems history={history} />
              </Dropdown>
            </div>
          </Navbar.Collapse>
        </>
      )}
    </Navbar>
  );
};

export default Header;
