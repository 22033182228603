import React from 'react';
import { Button } from 'react-bootstrap';
import { ClipboardCheck, Pin } from 'react-bootstrap-icons';
import { copyToClipboard, openLinkInNewTab } from '../../helpers/global';
import { toast } from 'react-toastify';

const PindropURLButton = ({ pindropUrl }) => {
  return (
    <div className="d-inline-block rounded mr-1">
      <Button
        variant="danger"
        size="sm"
        style={styles.inputGroupLeft}
        onClick={e => {
          e.stopPropagation();
          openLinkInNewTab(pindropUrl, true);
        }}
      >
        Pindrop <Pin className="align-text-bottom" />
      </Button>

      <Button
        onClick={e => {
          e.stopPropagation();
          copyToClipboard(pindropUrl);
          toast.success('Link Copied!');
        }}
        className="ml-0"
        variant="outline-danger"
        size="sm"
        style={styles.inputGroupRight}
      >
        <ClipboardCheck className="align-text-top" />
      </Button>
    </div>
  );
};

const styles = {
  inputGroupLeft: {
    borderRadius: '0.25em 0 0 0.25em'
  },
  inputGroupRight: {
    borderRadius: '0 0.25em 0.25em 0'
  }
};

export default PindropURLButton;
