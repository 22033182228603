import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';
import {
  ArrowRightCircle,
  ArrowUpRightCircle,
  Clipboard,
  Files,
  InfoSquareFill,
  PencilSquare,
  PlusCircle
} from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { findDifferenceInObjects } from '../../form-generator/helpers/utility';
import { deleteFileFromServer, makeApiRequests } from '../../helpers/api';
import {
  ENDPOINTS,
  RETURN_PATHS,
  inventorySearchFilterOptions,
  inventorySearchSortByOptions,
  inventoryToggleOptions
} from '../../helpers/constants';
import { addDocHubFormFieldsToForm, getObjectForPrefill } from '../../helpers/formHelpers';
import { copyInventoryDetails, fillSizesInInventory, isObjectIncomplete } from '../../helpers/global';
import AlertModal from '../AlertModal';
import ContactsPicker from '../ContactsPicker';
import ContractsPicker from '../ContractsPicker';
import NotFound from '../NotFound';
import Comments from '../comments/Comments';
import FileViewer from '../common/FileViewer';
import FloatingButton from '../common/FloatingButton';
import HorizontalProgress from '../common/HorizontalProgress';
import QuickToggles from '../common/QuickToggles';
import File from '../common/file';
import SearchBox from '../common/searchbox';
import ContractedBuyerOverview from '../inventory/ContractedBuyerOverview';
import CreateSideBar from '../inventory/CreateSidebar';
import InventoryList from '../inventory/InventoryList';
import PotentialBuyersOverview from '../inventory/PotentialBuyersOverview';
import { addChoicesToInventoryForm, editFormJson, validateAndCleanupForm } from '../inventory/form';
import InventoryInfoSideBar from './InventoryInfoSidebar';
import ListFilterText from './ListFilterText';
import PipelineLoaderCard from './PipelineLoaderCard';

const InventoryPipeline = ({ appChoices }) => {
  const [statuses, setStatuses] = useState(['Contracted', 'Stock', 'RSO'].map(s => ({ name: s, count: 0 })));
  const [loading, setLoading] = useState(true);
  const [inventories, setInventories] = useState([]);
  const [filteredInventories, setFilteredInventories] = useState([]);

  const [activeInventory, setActiveInventory] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [activeStatus, setActiveStatus] = useState(statuses[0]);

  const [form, setForm] = useState(null);
  const [hideForm, setHideForm] = useState(false);
  const [toggling, setToggling] = useState(false);
  const [allListMode, setAllListMode] = useState(true);

  const [sidebarMode, setSidebarMode] = useState(null);
  const [inventoryFiles, setInventoryFiles] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [buyerPopupMeta, setBuyerPopupMeta] = useState(null);
  const [removeAlertMeta, setRemoveAlertMeta] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [createUnitMeta, setCreateUnitMeta] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);
  const [deleteCommentModalMeta, setDeleteCommentModalMeta] = useState(null);

  const [numberOfSections, setNumberOfSections] = useState(1);
  const role = useMemo(() => localStorage.getItem('user-role'), []);

  const onNumberOfSectionsChange = number => {
    setNumberOfSections(Number(number));
  };

  const fetchInventory = async ({ sortBy, maxLimit, descSort, filters, query }) => {
    setLoading(true);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_SEARCH_NEW,
      requestBody: {
        filter: filters,
        sort: { [sortBy]: descSort ? -1 : 1 },
        query,
        pageNumber: 1,
        pageSize: maxLimit === 'All' ? undefined : maxLimit,
        returnPaths: RETURN_PATHS.INVENTORY,
        populate: false,
        includeContract: false,
        includeContact: false
      }
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setAllListMode(true);
    setInventories(
      response.results.map(i => ({
        ...i,
        inventoryStatus: i['contract'] ? 'Contracted' : i['isRSO']?.toLowerCase() === 'yes' ? 'RSO' : 'Stock'
      }))
    );
  };

  const filterInventories = () => {
    setFilteredInventories(
      allListMode || !activeStatus
        ? [...inventories]
        : inventories.filter(c => c['inventoryStatus'] === activeStatus.name)
    );
  };

  useEffect(() => {
    setStatuses(statuses.map(s => ({ ...s, count: inventories.filter(r => r['inventoryStatus'] === s.name).length })));
  }, [inventories]);

  useEffect(() => filterInventories(), [inventories, activeStatus, allListMode]);

  useEffect(() => {
    const activeInFilteredIndex = activeInventory
      ? filteredInventories.findIndex(c => c['_id'] === activeInventory['_id'])
      : -1;
    const inventory = filteredInventories[activeInFilteredIndex !== -1 ? activeInFilteredIndex : 0];
    setActiveInventory(inventory);
  }, [filteredInventories]);

  const setUpForm = () => {
    const form = editFormJson(true, numberOfSections, onNumberOfSectionsChange);

    const newFormJson = addDocHubFormFieldsToForm({ formJson: form, appChoices, model: 'Inventory' });
    addChoicesToInventoryForm({ appChoices, form: newFormJson });
    setForm(newFormJson);
  };

  useEffect(() => {
    setUpForm();
    fillSizesInInventory(activeInventory?.sizeOverall || '', numberOfSections);
  }, [numberOfSections, hideForm]);

  useEffect(() => {
    if (activeInventory) {
      onNumberOfSectionsChange(activeInventory?.numberOfSections);
    }
  }, [activeInventory]);

  useEffect(() => {
    setUploadedFiles([]);

    if (!activeInventory) {
      setActiveStatus(null);
      setInventoryFiles([]);
      return;
    }

    setActiveStatus(statuses.find(s => s.name === activeInventory['inventoryStatus']));
    setHideForm(true);

    setTimeout(() => {
      setHideForm(false);

      if (!activeInventory.fileNames) {
        getInventoryFiles(activeInventory.driveUrl, activeInventory._id);
      } else {
        setInventoryFiles(activeInventory.fileNames);
        setFileLoading(false);
      }
    }, 100);

    if (
      !activeInventory.hasOwnProperty('loadingError') &&
      isObjectIncomplete(activeInventory, RETURN_PATHS.INVENTORY)
    ) {
      fetchCompleteInventory(activeInventory);
    }
  }, [activeInventory]);

  const fetchCompleteInventory = async activeInventory => {
    updateInventoryList({ ...activeInventory, loadingError: false });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_SEARCH_NEW,
      requestBody: {
        filter: { _id: activeInventory._id },
        populate: true,
        includeContract: true,
        includeContact: true
      }
    });

    if (error) {
      updateInventoryList({ ...activeInventory, loadingError: true });
      toast.error(error);
      return;
    }

    updateInventoryList(response.results[0]);
  };

  //TODO files
  const getInventoryFiles = async (folderId, inventoryId) => {
    setFileLoading(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        folderId: folderId
      },
      requestType: 'readFiles'
    });

    setFileLoading(false);

    if (response) {
      const details = response['files'];
      updateInventoryFile(
        details.map(d => d.fileName),
        inventoryId
      );
    }
  };

  const onInventoryEditFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    //checks
    const valid = validateAndCleanupForm(form);
    if (!valid) return;

    form['files'] = [...activeInventory.files.map(f => f._id), ...uploadedFiles.map(f => f.id)];

    form.numberOfSections = Number(form?.numberOfSections);

    let allBValues = ['sizeB', 'serialB', 'hudB'];
    let allCValues = ['sizeC', 'serialC', 'hudC'];

    [...(numberOfSections === 1 ? allBValues : []), ...(numberOfSections <= 2 ? allCValues : [])].forEach(id => {
      form[id] = null;
    });

    setButtonsDisabled(true);
    toast.info('Please wait, processing inventory..');

    const { response, error } = await makeApiRequests({
      requestBody: findDifferenceInObjects(form, activeInventory, ['_id']),
      method: 'PUT',
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(activeInventory._id)
    });

    setButtonsDisabled(false);
    if (error) {
      return toast.error(error);
    }

    toast.success(`Inventory updated successfully!`);

    updateInventoryList(response);
  };

  window['onInventoryEditFormSubmit'] = onInventoryEditFormSubmit;

  const onInventorySelect = inventory => {
    const index = filteredInventories.findIndex(c => c['_id'] === inventory['_id']);
    if (index !== -1) setActiveInventory(filteredInventories[index]);
  };

  const StatusTabs = () => (
    <Alert className="my-2  px-2 py-1 text-center" variant="primary">
      {statuses.map((s, index) => (
        <div className="mt-1 d-inline-block" key={s.name}>
          {index !== 0 && <ArrowRightCircle size={10} className="mx-1" />}

          <Badge
            variant={activeStatus && activeStatus.name === s.name ? 'primary' : 'light'}
            className="hover-dark p-1 tinyFont"
            onClick={() => {
              setAllListMode(false);
              setActiveStatus(s);
            }}
          >
            {s.name}
            {s.count !== undefined ? ` (${s.count})` : ''}
          </Badge>
        </div>
      ))}
    </Alert>
  );

  const updateInventoryList = (newInventory, forFile) => {
    const inventoryToUpdate = {
      ...newInventory,
      inventoryStatus: newInventory['contract']
        ? 'Contracted'
        : newInventory['isRSO']?.toLowerCase() === 'yes'
        ? 'RSO'
        : 'Stock'
    };

    const inventoryIndex = inventories.findIndex(c => c['_id'] === newInventory['_id']);
    if (inventoryIndex !== -1) {
      inventories[inventoryIndex] = inventoryToUpdate;
      setInventories([...inventories]);
    } else {
      setInventories([inventoryToUpdate, ...inventories]);
    }

    setCreateUnitMeta(null);
    if (!forFile) setSidebarMode(null);
  };

  const updateInventoryFile = (fileNames, inventoryId) => {
    const inventoryIndex = inventories.findIndex(c => c['_id'] === inventoryId);
    if (inventoryIndex !== -1) {
      inventories[inventoryIndex]['fileNames'] = fileNames;
      setInventories([...inventories]);

      var currentInventory;
      setActiveInventory(activeInventory => {
        // Do not change the state by getting the updated state
        currentInventory = activeInventory;
        return activeInventory;
      });

      if (currentInventory['_id'] !== inventoryId) return;

      setInventoryFiles(inventories[inventoryIndex]['fileNames']);
      setFileLoading(false);
    }
  };

  const toggleInventory = async (label, key, value) => {
    setToggling(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(activeInventory._id),
      requestBody: { [key]: value },
      method: 'PUT'
    });

    setToggling(false);
    if (error) {
      toast.error(error);
      return;
    }

    toast.success(`${label} updated successfully!`);
    updateInventoryList(response);

    setToggling(false);
  };

  const onInventoryInfoClick = () => {
    setSidebarMode('view');
  };

  const onInventoryEditClick = () => {
    setSidebarMode('edit');
  };

  const onInventoryDelete = inventoryId => {
    const index = inventories.findIndex(c => c['_id'] === inventoryId);
    if (index !== -1) {
      inventories.splice(index, 1);
      setInventories([...inventories]);
    }
    setSidebarMode(null);
  };

  const onInventoryInfoSideBarHide = () => {
    setSidebarMode(null);
  };

  const onRemoveContactClick = removingContact => {
    setRemoveAlertMeta({ type: 'Contact', removingContact });
  };

  const onRemoveContractClick = () => {
    setRemoveAlertMeta({ type: 'Contract' });
  };

  const removeBuyer = async () => {
    const { type, removingContact } = removeAlertMeta;

    setRemoveAlertMeta({ ...removeAlertMeta, removingBuyer: true });

    const { response, error } = await makeApiRequests({
      endpoint:
        type === 'Contact'
          ? ENDPOINTS.CONTACTS_WITH_ID(removingContact._id)
          : ENDPOINTS.CONTRACTS_WITH_ID(activeInventory.contract._id),
      requestBody: {
        inventoryUnit:
          type === 'Contact'
            ? removingContact['inventoryUnit'].filter(unit => activeInventory['_id'] !== unit._id).map(u => u._id)
            : null
      },
      method: 'PUT'
    });

    setRemoveAlertMeta({ ...removeAlertMeta, removingBuyer: false });
    if (error) {
      return toast.error(error);
    }

    if (type === 'Contact') {
      activeInventory['contacts'] = activeInventory['contacts'].filter(c => c['_id'] !== removingContact['_id']);
    } else {
      activeInventory['contract'] = undefined;
      activeInventory['inventoryStatus'] = 'Stock';
    }

    toast.success('Buyer removed successfully');
    updateInventoryList(activeInventory);
    setRemoveAlertMeta(null);
  };

  const onAddContactClick = () => {
    setBuyerPopupMeta({ type: 'Contact' });
  };

  const onAddContractClick = () => {
    setBuyerPopupMeta({ type: 'Contract' });
  };

  const onBuyerSubmit = async buyerList => {
    const buyer = buyerList[0];
    const { type } = buyerPopupMeta;

    setBuyerPopupMeta({ ...buyerPopupMeta, submitting: true });

    const { response, error } = await makeApiRequests({
      endpoint: type === 'Contact' ? ENDPOINTS.CONTACTS_WITH_ID(buyer._id) : ENDPOINTS.CONTRACTS_WITH_ID(buyer._id),
      requestBody: {
        inventoryUnit:
          type === 'Contact' ? [...(buyer['inventoryUnit'] || []), activeInventory['_id']] : activeInventory['_id']
      },
      method: 'PUT'
    });

    setBuyerPopupMeta({ ...buyerPopupMeta, submitting: false });

    if (error) {
      return toast.error(error);
    }

    if (type === 'Contact') {
      activeInventory['contacts'] = [...(activeInventory['contacts'] || []), response];
    } else {
      activeInventory['contract'] = response;
      activeInventory['inventoryStatus'] = 'Contracted';
    }

    toast.success('Buyer added successfully');
    updateInventoryList(activeInventory);
    setBuyerPopupMeta(null);
  };

  const submitComment = async () => {
    toast.info('Adding comment...');
    setButtonsDisabled(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        comments: commentText
      },
      method: 'PUT',
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(activeInventory._id)
    });

    setButtonsDisabled(false);

    if (error) {
      return toast.error(error);
    }

    toast.success(`Comment added successfully!`);
    setCommentText('');
    updateInventoryList(response);
  };

  const onCommentDeleteClick = comment => {
    setDeleteCommentModalMeta({ comment });
  };

  const deleteComment = async () => {
    const commentToDelete = deleteCommentModalMeta.comment._id;
    setDeleteCommentModalMeta({ ...deleteCommentModalMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      requestBody: {
        commentId: commentToDelete
      },
      method: 'POST',
      endpoint: ENDPOINTS.INVENTORY_DELETE_COMMENT(activeInventory._id)
    });

    setDeleteCommentModalMeta({ ...deleteCommentModalMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    setDeleteCommentModalMeta(null);
    toast.success(`Comment deleted successfully!`);
    updateInventoryList(response);
  };

  const onBuyerSelected = contact => {
    setBuyerPopupMeta({ ...buyerPopupMeta, selectedBuyer: contact });
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = activeInventory.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(activeInventory._id),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    updateInventoryList(response);
    deleteFileFromServer(toBeDeletedFile);
  };

  const onDuplicateClick = () => {
    setCreateUnitMeta({
      prefillUnit: { ...activeInventory, serialA: '', serialB: '', serialC: '', hudA: '', hudB: '', hudC: '' },
      isDuplicating: true
    });
  };

  return (
    <Container fluid className={'h-100 pt-2 pb-3 px-md-2'}>
      <SearchBox
        appChoices={appChoices}
        filterCreationOptions={inventorySearchFilterOptions}
        sortByOptions={inventorySearchSortByOptions}
        maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
        onSearchOptionsChange={fetchInventory}
        disabled={loading}
      />
      {loading && <HorizontalProgress text={'Fetching inventories...'} />}
      <hr />

      {inventories.length > 0 ? (
        <Row className="h-100 px-md-3">
          <Col xs={4} md={2} className="p-0 pb-5 overflow-auto h-100">
            <ListFilterText allListMode={allListMode} onClearFilterClick={() => setAllListMode(true)} />
            <InventoryList
              inventories={filteredInventories}
              activeId={activeInventory ? activeInventory['_id'] : ''}
              onSelect={onInventorySelect}
              appChoices={appChoices}
            />
          </Col>
          <Col xs={8} md={10} className="h-100 overflow-auto px-1">
            <StatusTabs />
            <Card className="mt-2 mb-5">
              {activeInventory ? (
                isObjectIncomplete(activeInventory, RETURN_PATHS.INVENTORY) ? (
                  <PipelineLoaderCard
                    loadingError={activeInventory?.loadingError}
                    title={activeInventory['serialA'] || activeInventory['serialB']}
                    onRetryClick={() => fetchCompleteInventory(activeInventory)}
                  />
                ) : (
                  <>
                    <Card.Header className="bg-primary text-white p-2  smallFont">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h6 className="mb-0 d-inline-block midFont">
                            <Link
                              className="text-white"
                              to={'/inventory/' + activeInventory['_id']}
                              target="_blank"
                              onClick={e => e.stopPropagation()}
                            >
                              {activeInventory['serialA'] || activeInventory['serialB']}{' '}
                              <ArrowUpRightCircle size={14} />
                            </Link>
                          </h6>
                          <InfoSquareFill
                            className="align-text-bottom ml-2 hover-light"
                            onClick={onInventoryInfoClick}
                          />
                          <PencilSquare className="align-text-bottom mx-2 hover-light" onClick={onInventoryEditClick} />

                          {activeInventory['unitLocation'] && (
                            <Badge className="align-text-bottom" variant={'light'}>
                              {activeInventory['unitLocation'].location}
                            </Badge>
                          )}
                          <Badge className="align-text-bottom ml-2" variant={'info'}>
                            {activeInventory['inventoryStatus']}
                          </Badge>
                          {activeInventory['manufacturer'] && activeInventory['manufacturer'].isNPS && (
                            <Badge className="align-text-bottom ml-2" variant={'info'}>
                              NPS Sale
                            </Badge>
                          )}
                          {activeInventory['manufacturer'] && activeInventory['manufacturer'].isFactoryMakeReady && (
                            <Badge className="align-text-bottom ml-2" variant="dark">
                              Factory Makeready
                            </Badge>
                          )}
                          {activeInventory['hasFurnitureInside']?.toLowerCase() === 'yes' && (
                            <Badge className="align-text-bottom ml-2" variant="dark">
                              Has Furniture
                            </Badge>
                          )}
                        </div>
                        <div className="align-self-center">
                          <Button
                            className="mr-2 py-0 px-1"
                            size="sm"
                            variant="warning"
                            onClick={e => {
                              copyInventoryDetails(activeInventory);
                              toast.success('Copied!');
                            }}
                          >
                            <Clipboard className="align-text-top mr-2" /> Copy
                          </Button>

                          <Button
                            onClick={() => setShowFileViewer(true)}
                            variant="dark"
                            size="sm"
                            className="py-0 px-1 mr-2"
                          >
                            <Files className="align-text-top mr-2" />
                            View Files
                          </Button>

                          <Button onClick={onDuplicateClick} size="sm" className="py-0 px-1 ml-2" variant="success">
                            Duplicate
                          </Button>
                        </div>
                      </div>
                    </Card.Header>
                    {!hideForm && (
                      <Card.Body className="p-2 fade-in">
                        <div className="mx-2">
                          <QuickToggles
                            data={activeInventory}
                            toggleOptions={inventoryToggleOptions({ inventory: activeInventory, appChoices })}
                            onToggle={toggleInventory}
                            toggling={toggling}
                          />
                        </div>
                        <hr className="my-2" />
                        <div className="m-3">
                          <ContractedBuyerOverview
                            onAddContractClick={onAddContractClick}
                            onRemoveContractClick={onRemoveContractClick}
                            contract={activeInventory['contract']}
                          />

                          {!activeInventory['contract'] && (
                            <PotentialBuyersOverview
                              onAddContactClick={onAddContactClick}
                              onRemoveContactClick={onRemoveContactClick}
                              contacts={activeInventory['contacts']}
                            />
                          )}
                        </div>

                        {form && (
                          <>
                            <File
                              uploadedFiles={uploadedFiles}
                              onUploadedFilesChange={setUploadedFiles}
                              containerClassName="mx-3"
                            />
                            {form && !createUnitMeta && sidebarMode === null && (
                              <FormGenerator
                                prefix="inventory-pipeline"
                                formJson={form}
                                formValues={{ update: getObjectForPrefill(activeInventory) }}
                              />
                            )}
                          </>
                        )}
                        <hr />

                        <div className="m-3">
                          <Comments
                            comments={activeInventory['comments']}
                            disabled={buttonsDisabled}
                            onCommentSubmit={submitComment}
                            commentText={commentText}
                            onCommentTextChange={setCommentText}
                            onCommentDeleteClick={onCommentDeleteClick}
                          />
                        </div>
                        <FileViewer
                          show={showFileViewer}
                          onHide={() => setShowFileViewer(false)}
                          files={activeInventory['files']}
                          onFileDeleteClick={onFileDeleteClick}
                          driveFolder={activeInventory['driveFolder']}
                        />
                        <AlertModal
                          show={deleteFileModalMeta !== null}
                          alertText={`Are you sure to delete this file (${
                            deleteFileModalMeta
                              ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                                ? deleteFileModalMeta.file.metadata.originalName
                                : deleteFileModalMeta.file.filename
                              : ''
                          }). You will lose access to this file permanently?`}
                          showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
                          progressText="Deleting file..."
                          continueButtonText="Delete"
                          onDismissClick={() => setDeleteFileModalMeta(null)}
                          onHide={() => setDeleteFileModalMeta(null)}
                          onContinueClick={deleteFile}
                        />
                        {role === 'super admin' && (
                          <AlertModal
                            show={deleteCommentModalMeta !== null}
                            alertText="Are you sure to remove this comment?"
                            showProgress={deleteCommentModalMeta?.showProgress}
                            progressText="Deleting comment..."
                            onDismissClick={() => setDeleteCommentModalMeta(null)}
                            onHide={() => setDeleteCommentModalMeta(null)}
                            onContinueClick={deleteComment}
                          />
                        )}
                      </Card.Body>
                    )}
                    <ContactsPicker
                      submitButtonText="Select"
                      selectedContacts={
                        buyerPopupMeta && buyerPopupMeta.selectedBuyer ? [buyerPopupMeta.selectedBuyer] : []
                      }
                      excludedCrmIds={activeInventory['contacts'] ? activeInventory['contacts'].map(c => c._id) : []}
                      onContactSelect={onBuyerSelected}
                      show={buyerPopupMeta !== null && buyerPopupMeta['type'] === 'Contact'}
                      submitting={buyerPopupMeta && buyerPopupMeta['submitting']}
                      submittingText="Submitting contact..."
                      onSubmit={onBuyerSubmit}
                      onContactPickerClose={() => setBuyerPopupMeta(null)}
                      updateInventoryList={updateInventoryList}
                    />
                    <ContractsPicker
                      submitButtonText="Select"
                      excludedContractIds={activeInventory['contract'] ? [activeInventory['contract']['_id']] : []}
                      selectedContracts={
                        buyerPopupMeta && buyerPopupMeta.selectedBuyer ? [buyerPopupMeta.selectedBuyer] : []
                      }
                      onContractSelect={onBuyerSelected}
                      show={buyerPopupMeta !== null && buyerPopupMeta['type'] === 'Contract'}
                      submitting={buyerPopupMeta && buyerPopupMeta['submitting']}
                      submittingText="Submitting contract..."
                      onSubmit={onBuyerSubmit}
                      onContractPickerClose={() => setBuyerPopupMeta(null)}
                      updateInventoryList={updateInventoryList}
                    />
                    <AlertModal
                      show={removeAlertMeta != null}
                      alertText={`Are you sure to remove this ${
                        removeAlertMeta ? removeAlertMeta['type'] : ''
                      } from this unit?`}
                      showProgress={removeAlertMeta && removeAlertMeta['removingBuyer']}
                      progressText="Removing buyer..."
                      onDismissClick={() => setRemoveAlertMeta(null)}
                      onHide={() => setRemoveAlertMeta(null)}
                      onContinueClick={removeBuyer}
                    />
                  </>
                )
              ) : (
                <>
                  <Card.Header className="bg-primary text-white p-2">
                    <h6 className="mb-0">Select a inventory</h6>
                  </Card.Header>
                  <Card.Body>
                    <h6 className="p-5 text-center">No inventory selected. Selected inventory will appear here...</h6>
                  </Card.Body>
                </>
              )}
            </Card>
          </Col>
        </Row>
      ) : (
        !loading && <NotFound text="No inventories to show!" />
      )}
      <CreateSideBar
        onInventoryAdd={updateInventoryList}
        appChoices={appChoices}
        show={createUnitMeta !== null}
        onHide={() => setCreateUnitMeta(null)}
        prefillUnit={createUnitMeta?.prefillUnit}
        isDuplicating={createUnitMeta?.isDuplicating}
      />
      <FloatingButton variant="success" text={'Add New Unit'} Icon={PlusCircle} onClick={() => setCreateUnitMeta({})} />
      <InventoryInfoSideBar
        appChoices={appChoices}
        inventory={activeInventory}
        sidebarMode={sidebarMode}
        onInventoryDelete={onInventoryDelete}
        onInventoryEdit={updateInventoryList}
        onInventoryInfoSideBarHide={onInventoryInfoSideBarHide}
      />
    </Container>
  );
};

export default InventoryPipeline;
