import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  FormControl,
  Modal,
  ProgressBar,
  Row,
  Table
} from 'react-bootstrap';
import {
  Check,
  Files,
  InfoSquareFill,
  Mailbox,
  Pen,
  PlusCircle,
  PlusCircleFill,
  Receipt,
  Trash2Fill,
  X
} from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';

import { uniqueId } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { findDifferenceInObjects } from '../../form-generator/helpers/utility';
import { deleteFileFromServer, makeApiRequests } from '../../helpers/api';
import {
  ENDPOINTS,
  RETURN_PATHS,
  contractMilestoneDates,
  contractSearchFilterOptions,
  contractSearchSortByOptions,
  contractToggleOptions,
  isMicasa
} from '../../helpers/constants';
import { getObjectForPrefill } from '../../helpers/formHelpers';
import { copyContractAddress, copyContractDetails, isObjectIncomplete, openLinkInNewTab } from '../../helpers/global';
import AlertModal from '../AlertModal';
import NotFound from '../NotFound';
import OverViewColumns from '../OverViewColumns';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import TextButton from '../TextButton';
import AppChoicesSidebar from '../admin/AppChoicesSidebar';
import Comments from '../comments/Comments';
import FileViewer from '../common/FileViewer';
import FloatingButton from '../common/FloatingButton';
import HorizontalProgress from '../common/HorizontalProgress';
import QuickToggles from '../common/QuickToggles';
import File from '../common/file';
import SearchBox from '../common/searchbox';
import ContractedUnitOverview from '../inventory/ContractedUnitOverview';
import EditInventory from '../inventory/EditInventory';
import InventoryPicker from '../inventory/InventoryPicker';
import ContractList from '../main/ContractList';
import CreateSideBar from '../main/CreateSidebar';
import NotificationModal from '../main/NotificationModal';
import {
  addChoicesToContractForm,
  mainFieldsRows,
  validateAndCleanupForm,
  validateAndCleanupQuickEditForm
} from '../main/form';
import PayrollViewSidebar from '../reports/payroll/PayrollViewSidebar';
import ServiceOrderReport from '../service-order/ServiceOrderReport';
import ContractInfoSideBar from './ContractInfoSidebar';
import DochubSidebar from './DochubSidebar';
import ListFilterText from './ListFilterText';
import PipelineLoaderCard from './PipelineLoaderCard';
import StatusTabs from './StatusTabs';

const staticSectionDateFields = [
  'dateFormTSent',
  'dateSigned',
  'dateRebateRequested',
  'dateRebateReceived',
  'dateACRebateRequested',
  'dateACRebateReceived'
];
const staticSectionDateTimeFields = ['setup'];

const staticSectionPriceFields = ['salesPrice', 'downPayment', 'amountRebateReceived'];
const staticSectionEmailFields = ['buyerEmail', 'coBuyerEmail'];
const staticSectionPhoneFields = ['cellPhone', 'cellPhone2'];
const staticSectionDropdownFields = ['salesLocation', 'lender', 'installer'];
const staticSectionUrlFields = ['pindropUrl'];

//Used to decide which fields to show for a particular status
const statusConfigs = [
  {
    name: 'Contracted - RSO',
    fields: ['Utilities', 'Offline Date'],
    editRoles: [],
    readRoles: []
  },
  { name: 'Contracted - Stock', editRoles: [], readRoles: [], fields: ['Utilities'] },
  { name: 'Needs Foundation', editRoles: [], readRoles: [], fields: ['Utilities'] },
  { name: 'Factory Makeready Requested', editRoles: [], readRoles: [], fields: ['Utilities'] },
  {
    name: 'Ready For Delivery',
    fields: [
      'Utilities',
      'Delivery Start Date',
      'Delivery End Date',
      'Notify Date Delivered to:',
      'Delivery Notification Options:',
      'Delivery Company'
    ],
    notify: contractMilestoneDates.find(d => d.key === 'delivery'),
    editRoles: ['installer'],
    readRoles: ['installer']
  },
  {
    name: 'Ready for Setup',
    fields: [
      'Utilities',
      'Setup Start Date',
      'Setup End Date',
      'Notify Date Set Up to:',
      'Setup Notification Options:',
      'Installer'
    ],
    notify: contractMilestoneDates.find(d => d.key === 'setup'),

    editRoles: ['installer'],
    readRoles: ['installer']
  },
  {
    name: 'Ready for Makeready',
    fields: [
      'Utilities',
      'Tech',
      'Funded',
      'Date Funded',
      'Application Number',
      'Makeready Start Date',
      'Makeready End Date',
      'Notify Makeready Date to:',
      'Makeready Notification Options:'
    ],
    notify: contractMilestoneDates.find(d => d.key === 'makeready'),
    editRoles: ['service'],
    readRoles: ['service'],
    showServiceOrderReport: true
  },
  {
    name: 'In Makeready',
    fields: [
      'Utilities',
      'Tech',
      'Service Needed',
      'Makeready Start Date',
      'Makeready End Date',
      'Notify Makeready Date to:',
      'Makeready Notification Options:'
    ],
    readRoles: ['sales', 'sales manager'],
    editRoles: ['service']
  },
  { name: 'In SW Makeready', fields: ['Utilities'], editRoles: ['sales', 'sales manager'], readRoles: ['service'] },
  {
    name: 'Needs Service',
    fields: [
      'Utilities',
      'Service Tech',
      'Service Start Date',
      'Service End Date',
      'Notify Service Scheduled Date to:',
      'Bill-Back Status',
      'Service Tech'
    ],
    notify: contractMilestoneDates.find(d => d.key === 'service'),
    showServiceOrderReport: true,
    readRoles: ['sales', 'sales manager'],
    editRoles: ['service']
  },
  {
    name: 'Parts Arrived Needs Service',

    fields: [
      'Utilities',
      'Service Tech',
      'Service Start Date',
      'Service End Date',
      'Notify Service Scheduled Date to:',
      'Bill-Back Status',
      'Service Tech'
    ],
    notify: contractMilestoneDates.find(d => d.key === 'service'),
    showServiceOrderReport: true,
    readRoles: ['sales', 'sales manager'],
    editRoles: ['service']
  },
  {
    name: 'Parts Needed',
    fields: ['Utilities', 'Upload Parts Order'],
    readRoles: ['sales', 'sales manager'],
    editRoles: ['service']
  },
  {
    name: 'Parts Ordered',
    fields: ['Utilities', 'Upload Parts Order'],
    readRoles: ['sales', 'sales manager'],
    editRoles: ['service']
  },
  {
    name: 'In Service',
    fields: ['Utilities', 'Service Tech', 'Service Complete'],
    showServiceOrderReport: true,
    readRoles: ['sales', 'sales manager'],
    editRoles: ['service']
  },
  {
    name: 'Urgent Service/Parts Needed',
    fields: [
      'Utilities',
      'Service Tech',
      'Service Start Date',
      'Service End Date',
      'Notify Service Scheduled Date to:',
      'Bill-Back Status',
      'Service Tech'
    ],
    notify: contractMilestoneDates.find(d => d.key === 'service'),
    showServiceOrderReport: true,
    readRoles: ['sales', 'sales manager'],
    editRoles: ['service']
  },
  {
    name: 'In Urgent Service',

    fields: ['Utilities', 'Service Tech', 'Urgent Service Complete'],
    readRoles: ['sales', 'sales manager'],
    editRoles: ['service']
  },
  {
    name: 'State Complaint',

    fields: [
      'Utilities',
      'Action Required',
      'State Inspection Deadline',
      'State Reinspection Date',
      'Service Start Date',
      'Service End Date',
      'Notify Service Scheduled Date to:',
      'Bill-Back Status',
      'Service Tech'
    ],
    notify: contractMilestoneDates.find(d => d.key === 'service'),
    showServiceOrderReport: true,
    readRoles: ['sales', 'sales manager'],
    editRoles: ['service']
  },
  { name: 'Complete', fields: ['Utilities'], readRoles: ['sales', 'sales manager'], editRoles: ['service'] }
];

const getRoleWiseStatuses = (appChoices, role) => {
  const allStatuses = appChoices
    .find(choice => choice.key === 'Status')
    .values.map(
      s =>
        statusConfigs.find(statusConfig => statusConfig.name === s) || {
          name: s,
          fields: ['Utilities'],
          editRoles: [],
          readRoles: []
        }
    );

  return allStatuses.map(s => ({ ...s, count: 0 }));
};

const InventoryEditSideBar = ({ editingInventory, appChoices, onHide, onInventoryDelete, onInventoryEdit }) => {
  return (
    <SlidingSideBar fullScreen visible={editingInventory} onClose={onHide} title={'Inventory Details'}>
      {editingInventory && (
        <EditInventory
          id={editingInventory['_id']}
          inventoryFromSearch={editingInventory}
          appChoices={appChoices}
          fromSearch
          onInventoryDelete={onInventoryDelete}
          onInventoryEdit={onInventoryEdit}
        />
      )}
    </SlidingSideBar>
  );
};

const createPipelineForm = ({ activeStatusObject, appChoices }) => {
  const form = {
    forms: [
      {
        name: 'Pipeline',
        hideFormName: true,
        markCompulsoryFields: true,
        compact: true,
        submit: {
          name: 'Save',
          show: true,
          onSubmit: 'onPipelineFormSubmit'
        },
        rows: [
          {
            columns: activeStatusObject.fields
              ? mainFieldsRows(true)
                  .flatMap(r => r.columns)
                  .filter(c => activeStatusObject.fields.includes(c.field.title))
              : []
          }
        ]
      }
    ]
  };

  addChoicesToContractForm({ form, appChoices });
  return form;
};

const OptionalEquipmentAndAccessorTable = ({ data, field, onChange, closePopup }) => {
  const [initialData, setInitialData] = useState([]);
  const inputRefs = useRef({});

  const fields = [
    { title: 'Optional Equipment And Accessories', key: 'key' },
    { title: 'Optional Equipment And Accessories', key: 'value' }
  ];

  useEffect(() => {
    setInitialData(data?.map(d => ({ ...d, id: uniqueId() })) || []);
  }, [data]);

  const handleInputChange = (id, fieldKey, value) => {
    const updatedData = initialData.map(row => (row.id === id ? { ...row, [fieldKey]: value } : row));
    setInitialData(updatedData);
  };
  const deleteRow = id => {
    setInitialData(initialData?.filter(d => d.id !== id));
  };

  const onUpdate = () => {
    onChange(
      field,
      initialData?.map(d => ({ ...d, value: isNaN(Number(d?.value)) || !Number(d?.value) ? 'Inc' : Number(d?.value) }))
    );
    closePopup();
  };
  return (
    <div className="m-2" style={{ width: 580 }}>
      <Table bordered className="rounded m-0" responsive>
        <thead>
          <tr className="bg-primary text-white smallFont">
            {[...fields, { title: 'Action' }].map((field, index) => (
              <th key={index} className="text-center py-1 px-0">
                {field.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {initialData.map(d => (
            <tr key={d.id} className="p-0">
              {fields.map(field => (
                <td key={`${field.key}-${d.id}`} className="p-1">
                  <FormControl
                    size="sm"
                    className="smallFont"
                    value={field.key === 'key' ? d[field.key] : Number(d[field.key])}
                    type={field.key === 'key' ? 'text' : 'number'}
                    onChange={e => handleInputChange(d.id, field.key, e.target.value)}
                    ref={el => (inputRefs.current[`${field.key}-${d.id}`] = el)}
                  />
                </td>
              ))}
              <td align="center" className="p-1">
                <Trash2Fill
                  className="smallFont text-danger pointer"
                  title="Delete This Row"
                  onClick={() => deleteRow(d?.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
        {initialData.length === 0 && (
          <tfoot>
            <tr>
              <td className="mid text-center" colSpan={3}>
                Nothing to show
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
      <div className="d-flex justify-content-end mt-2">
        <TextButton
          Icon={PlusCircleFill}
          variant="success"
          text="Add A Row"
          onClick={() => setInitialData([...initialData, { id: uniqueId(), key: '', value: '' }])}
        />
      </div>
      <div className="d-flex justify-content-end mt-2">
        <Button size="sm" className="mx-1 px-1 py-0" variant="danger" onClick={closePopup}>
          Close
        </Button>
        <Button size="sm" className="mx-1 px-1 py-0" onClick={onUpdate}>
          Update
        </Button>
      </div>
    </div>
  );
};

const Pipeline = ({ appChoices: initAppChoices }) => {
  const [appChoices, setAppChoices] = useState(initAppChoices);
  const [role] = useState(localStorage.getItem('user-role'));
  const [roleWiseStatuses, setRoleWiseStatuses] = useState(getRoleWiseStatuses(appChoices, role));

  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);

  const [activeContract, setActiveContract] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [activeStatusObject, setActiveStatusObject] = useState(roleWiseStatuses[0]);
  const [overviewColumnEditMeta, setOverviewColumnEditMeta] = useState({});

  const [form, setForm] = useState(null);
  const [toggling, setToggling] = useState(false);
  const [allListMode, setAllListMode] = useState(true);
  const [showServiceReportModal, setShowServiceReportModal] = useState(false);
  const [serviceOrderRows, setServiceOrderRows] = useState(null);
  const [serviceRowSubmitting, setServiceRowSubmitting] = useState(false);
  const [sidebarMode, setSideBarMode] = useState(null);
  const [activeNotificationType, setActiveNotificationType] = useState();
  const [sendingNotification, setSendingNotification] = useState(false);
  const [inventoryPopupMeta, setInventoryPopupMeta] = useState(null);
  const [assigningInventory, setAssigningInventory] = useState(false);
  const [showRemoveUnitAlertModal, setShowRemoveUnitAlertModal] = useState(false);

  const [removingInventory, setRemovingInventory] = useState(false);
  const [editingInventory, setEditingInventory] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [createContractMeta, setCreateContractMeta] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);
  const [deleteCommentModalMeta, setDeleteCommentModalMeta] = useState(null);

  const [showNpsCallDate, setShowNpsCallDate] = useState(null);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [toBeDeletedBill, setToBeDeletedBill] = useState(null);
  const [showRecordPayrollSidebar, setShowRecordPayrollSidebar] = useState(null);
  //TODO remove
  const [payrolls, setPayrolls] = useState([]);
  const [payrollBillMeta, setPayrollBillMeta] = useState(null);
  const [payrollLoading, setPayrollLoading] = useState(false);
  const [showAppChoiceUpdatePopup, setShowAppChoiceUpdatePopup] = useState(false);
  const [payrollViewContract, setPayrollViewContract] = useState(null);
  const [hideForm, setHideForm] = useState(false);
  const [callDateInUpdate, setCallDateInUpdate] = useState(false);

  const [showDateOfDepositeDateCell, setShowDateOfDepositeDateCell] = useState(null);
  const [dateOfDepositeInUpdate, setDateOfDepositeInUpdate] = useState(false);

  const [openDochubMeta, setOpenDochubMeta] = useState(null);

  const calculatedValues = useMemo(() => {
    const inventoryInvoice = Number(activeContract?.inventoryUnit?.invoice || 0); // WIP
    const salesPrice = Number(activeContract?.salesPrice || 0);
    const commissionLessValue = activeContract?.commissionSheetLessOptions?.reduce(
      (a, b) => a + Number(b.value || 0),
      0
    );
    const netCommissionsTotal = salesPrice - commissionLessValue;

    const optionsTotal =
      inventoryInvoice + activeContract?.commissionSheetOptions?.reduce((a, b) => a + Number(b.value || 0), 0);
    const grossProfit = netCommissionsTotal - optionsTotal;

    let commissionDue;
    let bonus = 0;

    if (isMicasa) {
      bonus = Number(activeContract?.additionalFields?.bonus || 0);
      commissionDue = grossProfit >= 20000 ? 0.25 * grossProfit : 0.2 * grossProfit;
    } else {
      commissionDue = grossProfit / 2;
    }

    const curtailmentBonus = Number(activeContract?.additionalFields?.curtailmentBonus || 0);
    const teamLeaderBonus = Number(activeContract?.additionalFields?.teamLeaderBonus || 0);

    const commissionDueWithBonus = commissionDue + curtailmentBonus + teamLeaderBonus;
    const totalWithBonus = commissionDue + bonus;
    const frontEnd = commissionDueWithBonus / 2;

    const firstHalfPaid = Number(activeContract?.additionalFields?.firstHalfPaid || 0);
    const backendAdvancePaid = Number(activeContract?.additionalFields?.backendAdvancePaid || 0);

    const balanceToBePaid = commissionDueWithBonus - firstHalfPaid;
    const balanceAfterAdvance = balanceToBePaid - backendAdvancePaid;

    return {
      netCommission: netCommissionsTotal.toFixed(2),
      totalOfOptions: optionsTotal.toFixed(2),
      grossProfit: grossProfit.toFixed(2),
      commissionDue: commissionDue.toFixed(2),
      commissionDueWithBonus: commissionDueWithBonus.toFixed(2),
      frontEnd: frontEnd.toFixed(2),
      balanceToBePaid: balanceToBePaid.toFixed(2),
      balanceAfterAdvance: balanceAfterAdvance.toFixed(2),
      backEnd: frontEnd.toFixed(2)
    };
  }, [activeContract]);

  const staticSectionFields = useMemo(
    () => [
      {
        key: 'salesLocation',
        label: 'Sales Location',
        isObject: true,

        objectKey: 'location',
        options:
          appChoices.find(ac => ac.key === 'salesLocation')?.values.map(v => ({ label: v.location, value: v._id })) ||
          [],
        required: true
      },
      { key: 'salesperson', label: 'Salesperson' },
      { key: 'salesPrice', label: 'Sales Price' },
      {
        key: 'additionalFields',
        label: 'Curtailment Bonus',
        isObject: true,
        objectKey: 'curtailmentBonus',
        valueKey: 'curtailmentBonus'
      },
      {
        key: 'additionalFields',
        label: 'Team Leader Bonus',
        isObject: true,
        objectKey: 'teamLeaderBonus',
        valueKey: 'teamLeaderBonus'
      },
      {
        key: 'additionalFields',
        label: 'First Half Paid',
        isObject: true,
        objectKey: 'firstHalfPaid',
        valueKey: 'firstHalfPaid'
      },
      {
        key: 'additionalFields',
        label: 'Backend Advance Paid',
        isObject: true,
        objectKey: 'backendAdvancePaid',
        valueKey: 'backendAdvancePaid'
      },

      {
        key: 'optionalEquipmentAndAccessories',
        label: 'Optional Equipment And Accessories',
        type: 'custom',
        valueGetter: data => {
          return `$${data?.optionalEquipmentAndAccessories?.reduce((a, b) => {
            let val = isNaN(Number(b?.value)) ? 0 : Number(b?.value);
            return a + val;
          }, 0)}`;
        },
        renderCustomField: (field, data, onChange, closePopup) => (
          <OptionalEquipmentAndAccessorTable field={field} data={data} onChange={onChange} closePopup={closePopup} />
        )
      },
      { key: 'downPayment', label: 'Down Payment' },
      { key: 'taxFactorAdjustedSalesPrice', label: 'MHIT' },
      { key: 'applicationNumber', label: 'Application #' },
      {
        key: 'lender',
        label: 'Lender',
        isObject: true,
        objectKey: 'lienholderCorporateName',
        options:
          appChoices
            .find(ac => ac.key === 'lender')
            ?.values.map(v => ({ label: v.lienholderCorporateName, value: v._id })) || []
      },
      {
        key: 'installer',
        label: 'Installer',
        isObject: true,
        objectKey: 'name',
        options: appChoices.find(ac => ac.key === 'installer')?.values.map(v => ({ label: v.name, value: v._id })) || []
      },
      { key: 'pindropUrl', label: 'Pindrop Url' },
      { key: 'dateFormTSent', label: 'Date Form T Sent' },
      { key: 'propertyAddress', label: 'Property Address', required: true },
      { key: 'propertyCity', label: 'Property City', required: true },
      { key: 'propertyState', label: 'Property State', required: true },
      {
        key: 'zipCode',
        label: 'Zip Code',
        required: true,
        onChange: value => {
          let zipCodes = appChoices.find(a => a.key === 'zipCodes')['values'];
          const zipCodeFiltered = zipCodes.filter(zip => value == zip['Zip Code']);

          if (zipCodeFiltered[0]) {
            let propertyState = zipCodeFiltered[0]['Property State'];
            let propertyCity = zipCodeFiltered[0]['Property City'];
            let propertyCounty = zipCodeFiltered[0]['Property County'];
            return { propertyCity, propertyCounty, propertyState };
          }
          return {};
        }
      },
      { key: 'propertyCounty', label: 'Property County', required: true },
      { key: 'cellPhone', label: 'Cell Phone', required: true },
      { key: 'cellPhone2', label: 'Cell Phone #2' },
      { key: 'buyerEmail', label: 'Buyer Email' },
      { key: 'coBuyerEmail', label: 'Co-Buyer Email' },
      { key: 'tyresAndAlxes', label: 'Tires / Axles' },
      { key: 'setup', label: 'Setup Start Date', isObject: true, objectKey: 'start', valueKey: 'start' },
      { key: 'setup', label: 'Setup End Date', isObject: true, objectKey: 'end', valueKey: 'end' },
      { key: 'dateSigned', label: 'Date Signed' },
      { key: 'amountRebateReceived', label: 'Rebate Amount' },
      { key: 'dateRebateReceived', label: 'Date Rebate Received' },
      { key: 'dateRebateRequested', label: 'Date Rebate Requested' },
      { key: 'amountACRebateReceived', label: 'AC Rebate Amount' },
      { key: 'dateACRebateReceived', label: 'Date AC Rebate Received' },
      { key: 'dateACRebateRequested', label: 'Date AC Rebate Requested' }
    ],
    [appChoices]
  );

  const fetchContracts = async ({ sortBy, maxLimit, descSort, filters, query }) => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW,
      requestBody: {
        filter: filters,
        sort: { [sortBy]: descSort ? -1 : 1 },
        query,
        pageNumber: 1,
        pageSize: maxLimit === 'All' ? undefined : maxLimit,
        returnPaths: RETURN_PATHS.CONTRACT,
        populate: false
      }
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    const roleStatusNames = roleWiseStatuses.map(s => s.name);
    setAllListMode(true);
    setContracts(response.results.filter(c => roleStatusNames.includes(c['status'])));
  };

  useEffect(() => {
    setRoleWiseStatuses(
      roleWiseStatuses.map(s => ({ ...s, count: contracts.filter(r => r['status'] === s.name).length }))
    );
  }, [contracts]);

  const filterContracts = () => {
    setFilteredContracts(
      allListMode || !activeStatusObject
        ? [...contracts]
        : contracts.filter(c => c['status'] === activeStatusObject.name)
    );
  };

  useEffect(() => filterContracts(), [contracts, activeStatusObject, allListMode]);

  useEffect(() => {
    const activeInFilteredIndex = activeContract
      ? filteredContracts.findIndex(c => c['_id'] === activeContract['_id'])
      : -1;
    const contract = filteredContracts[activeInFilteredIndex !== -1 ? activeInFilteredIndex : 0];
    if (contract) {
      //convert contract important dates to linear fields
      contractMilestoneDates.forEach(({ key, start, end, emailField, options }) => {
        const milestoneObject = contract[key];
        if (milestoneObject) {
          contract[start] = milestoneObject.start;
          contract[end] = milestoneObject.end;
          contract[emailField] =
            milestoneObject.notificationEmails?.map(e => (e === contract['buyerEmail'] ? 'buyer-mhc' : e)) || [];
          contract[options] = milestoneObject.options;
        }
      });
    }
    setActiveContract(contract);
  }, [filteredContracts]);

  const setupForm = () => {
    if (!activeStatusObject) {
      setForm(null);
      return;
    }

    setForm(createPipelineForm({ activeStatusObject, appChoices }));
  };

  useEffect(() => {
    setupForm();
  }, [activeStatusObject]);

  useEffect(() => {
    setUploadedFiles([]);
    if (!activeContract) {
      setActiveStatusObject(null);
      return;
    }

    setActiveStatusObject(roleWiseStatuses.find(s => s.name === activeContract['status']));

    setHideForm(true);

    setTimeout(() => {
      setHideForm(false);
    }, 100);

    if (!activeContract.hasOwnProperty('loadingError') && isObjectIncomplete(activeContract, RETURN_PATHS.CONTRACT)) {
      fetchCompleteContract(activeContract);
    }
  }, [activeContract]);

  const fetchCompleteContract = async activeContract => {
    updateContractList({ ...activeContract, loadingError: false });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW,
      requestBody: {
        filter: { _id: activeContract._id },
        populate: true
      }
    });

    if (error) {
      updateContractList({ ...activeContract, loadingError: true });
      toast.error(error);
      return;
    }

    updateContractList(response.results[0]);
  };

  const onPipelineFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    if (!form['buyerEmail']) {
      form['buyerEmail'] = activeContract['buyerEmail'];
    }

    const valid = validateAndCleanupForm(form);
    if (!valid) return;

    form['files'] = [...activeContract.files.map(f => f._id), ...uploadedFiles.map(f => f.id)];

    setButtonsDisabled(true);

    toast.info('Processing contract. This may take a while if Calendar events and email attachments are involved.');

    const formData = findDifferenceInObjects(form, activeContract, ['_id']);
    const { response: updatedContract, error } = await makeApiRequests({
      requestBody: formData,
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(activeContract._id)
    });

    setButtonsDisabled(false);
    if (error) {
      return toast.error(error);
    }

    toast.success(`Contract updated successfully!`);

    updateContractList(updatedContract);

    //if status has been changed, show status info as well
    if (Object.keys(formData).includes('status')) {
      toast.info(`Contract ${updatedContract['Buyer']} moved to ${updatedContract['status']}.`);
    }
  };

  const onOverviewColumnsSubmit = async form => {
    const valid = validateAndCleanupQuickEditForm(form);
    if (!valid) return;

    setOverviewColumnEditMeta({ ...overviewColumnEditMeta, showProgress: true });

    toast.info('Saving contract...');

    const { response: updatedContract, error } = await makeApiRequests({
      requestBody: form,
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(activeContract._id)
    });

    setOverviewColumnEditMeta({ ...overviewColumnEditMeta, showProgress: false });
    if (error) {
      return toast.error(error);
    }

    toast.success(`Contract updated successfully!`);
    setOverviewColumnEditMeta({ ...overviewColumnEditMeta, isEditing: false });
    updateContractList(updatedContract);
  };

  const editNpsCallDate = async () => {
    if (!showNpsCallDate) {
      return toast.error('NPS Call Date Cannot Be Empty/');
    }
    setCallDateInUpdate(true);

    const { response: updatedContract, error } = await makeApiRequests({
      requestBody: { npsCallDate: new Date(showNpsCallDate)?.toISOString() },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(activeContract._id)
    });

    if (error) {
      setCallDateInUpdate(false);
      return toast.error(error);
    }

    toast.success('NPS Call Date Updated Successfully');
    setShowNpsCallDate(null);
    setActiveContract(updatedContract);
    setCallDateInUpdate(false);
  };

  const editDateOfDeposite = async () => {
    if (!showDateOfDepositeDateCell) {
      return toast.error('Date of deposite Cannot Be Empty/');
    }
    setDateOfDepositeInUpdate(true);

    const { response: updatedContract, error } = await makeApiRequests({
      requestBody: { dateOfDeposit: new Date(showDateOfDepositeDateCell)?.toISOString() },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(activeContract._id)
    });

    if (error) {
      setDateOfDepositeInUpdate(false);
      return toast.error(error);
    }

    toast.success('Date of deposite Updated Successfully');
    setShowDateOfDepositeDateCell(null);
    setActiveContract(updatedContract);
    setDateOfDepositeInUpdate(false);
  };

  window['onPipelineFormSubmit'] = onPipelineFormSubmit;

  const onContractSelect = contract => {
    const index = filteredContracts.findIndex(c => c['_id'] === contract['_id']);
    if (index !== -1) setActiveContract(filteredContracts[index]);
  };

  const updateContractList = (newContract, forFile) => {
    const existingIndex = contracts.findIndex(c => c._id === newContract._id);
    if (existingIndex !== -1) {
      contracts[existingIndex] = newContract;
      setContracts([...contracts]);
    } else {
      setContracts([newContract, ...contracts]);
    }

    setCreateContractMeta(null);
    if (!forFile) setSideBarMode(null);
  };

  const submitComment = async () => {
    toast.info('Adding comment...');
    setButtonsDisabled(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        comments: commentText
      },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(activeContract._id)
    });

    setButtonsDisabled(false);

    if (error) {
      return toast.error(error);
    }

    toast.success(`Comment added successfully!`);
    setCommentText('');
    updateContractList(response);
  };

  const toggleContract = async (label, key, value) => {
    setToggling(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(activeContract._id),
      requestBody: { [key]: value },
      method: 'PUT'
    });

    setToggling(false);
    if (error) {
      toast.error(error);
      return;
    }

    updateContractList(response);

    //if status has been changed, show status info as well
    if (key === 'status') {
      toast.info(`Contract ${response['buyer']} moved to ${response['status']}.`);
    } else {
      toast.success(`Contract's ${label} updated successfully!`);
    }

    setToggling(false);
  };

  const onCommentDeleteClick = comment => {
    setDeleteCommentModalMeta({ comment });
  };

  const deleteComment = async () => {
    const commentToDelete = deleteCommentModalMeta.comment._id;
    setDeleteCommentModalMeta({ ...deleteCommentModalMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      requestBody: {
        commentId: commentToDelete
      },
      method: 'POST',
      endpoint: ENDPOINTS.CONTRACTS_DELETE_COMMENT(activeContract._id)
    });

    setDeleteCommentModalMeta({ ...deleteCommentModalMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    setDeleteCommentModalMeta(null);
    toast.success(`Comment deleted successfully!`);
    updateContractList(response);
  };

  const onServiceOrderReportClick = () => {
    setShowServiceReportModal(true);
  };

  const handleServiceReportClose = () => {
    setServiceOrderRows(null);
    setShowServiceReportModal(false);
  };

  const onServiceOrderRowsChange = rows => {
    setServiceOrderRows(rows);
  };

  const ServiceReportModal = () => (
    <Modal
      dialogClassName="h-100 my-0"
      contentClassName="h-100"
      size="lg"
      show={showServiceReportModal}
      onHide={handleServiceReportClose}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton={!serviceRowSubmitting}>
        <Modal.Title>Service Order Report</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <ServiceOrderReport
          preFillReport={activeContract['serviceOrderReport']}
          onRowsChange={onServiceOrderRowsChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 text-right">
          {serviceRowSubmitting && (
            <Col xs={12}>
              <ProgressBar className="mb-2" animated now={100} label="Saving report..." />
            </Col>
          )}
          <Col xs={12}>
            <Button size="sm" disabled={serviceRowSubmitting} variant="primary" onClick={submitServiceReport}>
              Save
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );

  const submitServiceReport = async () => {
    setServiceRowSubmitting(true);
    const submitRows = serviceOrderRows.map(r => {
      const obj = {
        ...r,
        Description: r['Description'] === 'Other' ? r['Description Other'] : r['Description'],
        Cause: r['Cause'] === 'Other' ? r['Cause Other'] : r['Cause'],
        'Repair Method': r['Repair Method'] === 'Other' ? r['Repair Method Other'] : r['Repair Method']
      };

      ['Repair Method Other', 'Cause Other', 'Description Other'].forEach(f => delete obj[f]);

      return obj;
    });

    const { response, error } = await makeApiRequests({
      requestBody: {
        serviceOrderReport: submitRows
      },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(activeContract._id)
    });

    if (error) {
      setServiceRowSubmitting(false);
      toast.error(error);
      return;
    }

    toast.success('Service Order Report submitted successfully!');
    setServiceRowSubmitting(false);
    updateContractList(response);
    handleServiceReportClose();
  };

  const onContractInfoClick = () => {
    setSideBarMode('view');
  };

  const onContractEditClick = () => {
    setSideBarMode('edit');
  };

  const onContractDelete = contractId => {
    const index = contracts.findIndex(c => c['_id'] === contractId);
    if (index !== -1) {
      contracts.splice(index, 1);
      setContracts([...contracts]);
    }
    setSideBarMode(null);
  };

  const onContractInfoSideBarHide = () => {
    setSideBarMode(null);
  };

  const getRebateBadge = () => {
    let badgeText = '';

    if (activeContract['dateRebateRequested']) {
      badgeText = 'Rebate Requested';
    }

    if (activeContract['dateRebateReceived']) {
      badgeText = 'Rebate Received';
    }

    if (activeContract['rebateReportedDate']) {
      badgeText = 'Rebate Reported';
    }

    return badgeText ? (
      <Badge className="align-text-bottom ml-2" variant={'warning'}>
        {badgeText}
      </Badge>
    ) : (
      ''
    );
  };

  const getACRebateBadge = () => {
    let badgeText = '';

    if (activeContract['dateACRebateRequested']) {
      badgeText = 'AC Rebate Requested';
    }

    if (activeContract['dateACRebateReceived']) {
      badgeText = 'AC Rebate Received';
    }

    if (activeContract['acRebateReportedDate']) {
      badgeText = 'AC Rebate Reported';
    }

    return badgeText ? (
      <Badge className="align-text-bottom ml-2" variant={'warning'}>
        {badgeText}
      </Badge>
    ) : (
      ''
    );
  };

  const handleNotificationModalClose = () => {
    setActiveNotificationType(null);
  };

  const onSendNotificationClick = dateType => {
    if (!activeContract[dateType.start]) {
      toast.error(`Please provide ${dateType.key} date of the contract before sending notification!`);
      return;
    }

    setActiveNotificationType(dateType.key);
  };

  const onNotificationFormSubmit = async form => {
    const sendWalkthrough = form.notificationActions.includes('Send Walkthrough Report');
    const sendServiceOrderReport = form.notificationActions.includes('Send Service Order Report');

    if (sendServiceOrderReport) {
      if (!activeContract['serviceOrderReport']) {
        return toast.error(
          `You're choosing to attach a service order report, but it hasn't started yet. You can remove the attachment, or start the report before trying again.`
        );
      }

      if (!activeContract['inventoryUnit']) {
        return toast.error(
          `A inventory unit must be assigned to this contract before a Service Order Report can be sent. You can remove the attachment, or assign an inventory unit before trying again.`
        );
      }
    }

    setSendingNotification(true);
    toast.info(`Sending ${activeNotificationType} notification...`);

    const { error, response } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_NOTIFICATION,
      requestBody: {
        dateType: activeNotificationType,
        contractId: activeContract._id,
        attachments: form.attachmentFiles,
        sendEmail: form.notificationActions.includes('Send Email'),
        createCalendarEvent: form.notificationActions.includes('Create Calendar Event'),
        sendWalkthrough,
        sendServiceOrderReport
      }
    });

    setSendingNotification(false);

    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Notification sent successfully!');
    setSendingNotification(false);
    setActiveNotificationType(null);
  };

  window['onNotificationFormSubmit'] = onNotificationFormSubmit;

  const onLinkUnitClick = () => {
    setInventoryPopupMeta({});
  };

  const onInventorySubmit = async inventoryUnits => {
    const invetoryUnit = inventoryUnits[0];
    setAssigningInventory(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(activeContract['_id']),
      requestBody: { inventoryUnit: invetoryUnit._id },
      method: 'PUT'
    });

    setAssigningInventory(false);

    if (error) {
      toast(error, {
        type: 'error'
      });
      return;
    }

    toast(`Inventory unit assigned successfully!`, {
      type: 'success'
    });

    updateContractList(response);
    setInventoryPopupMeta(null);
  };

  const onRemoveUnitClick = () => {
    setShowRemoveUnitAlertModal(true);
  };

  // Deleting The Payroll

  const deleteItem = async () => {
    setDeleteInProgress(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.PAYROLLS_WITH_ID(toBeDeletedBill._id),
      method: 'DELETE'
    });

    setDeleteInProgress(false);
    if (error) {
      toast.error(error);
      return;
    }

    payrolls.splice(
      payrolls.findIndex(i => i._id === toBeDeletedBill._id),
      1
    );

    const toBeUpdatedContract = contracts.findIndex(contract => contract?._id === activeContract?._id);

    contracts[toBeUpdatedContract].payrolls.splice(
      contracts[toBeUpdatedContract]?.payrolls?.findIndex(payroll => payroll?._id === toBeDeletedBill?._id),
      1
    );

    setContracts([...contracts]);

    setPayrolls([...payrolls]);
    setToBeDeletedBill(null);
  };

  const removeInventoryUnit = async () => {
    setRemovingInventory(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(activeContract['_id']),
      requestBody: { inventoryUnit: null },
      method: 'PUT'
    });
    setRemovingInventory(false);

    if (error) {
      toast(error, {
        type: 'error'
      });
      return;
    }

    toast(`Inventory unit removed successfully!`, {
      type: 'success'
    });

    updateContractList(response);
    setShowRemoveUnitAlertModal(false);
  };

  const onInventoryEdit = updatedInventory => {
    activeContract['inventoryUnit'] = updatedInventory;
    updateContractList(activeContract);
    setEditingInventory(null);
  };

  const onInventoryDelete = deletedInventoryId => {
    activeContract['inventoryUnit'] = null;
    updateContractList(activeContract);
    setEditingInventory(null);
  };

  const onUnitSelect = unit => {
    setInventoryPopupMeta({ ...inventoryPopupMeta, selectedUnit: unit });
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });
    let newFileArray;

    if (!deleteFileModalMeta?.file?.payrollId) {
      newFileArray = activeContract.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);
    } else {
      newFileArray = activeContract?.payrolls
        .find(payroll => payroll?._id === deleteFileModalMeta?.file?.payrollId)
        ?.files?.filter(file => file?._id !== deleteFileModalMeta?.file?._id);
    }

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint: !deleteFileModalMeta?.file?.payrollId
        ? ENDPOINTS.CONTRACTS_WITH_ID(activeContract._id)
        : ENDPOINTS.PAYROLLS_WITH_ID(deleteFileModalMeta?.file?.payrollId),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    if (deleteFileModalMeta?.file?.payrollId) {
      // updating Contract

      const contractToBeUpdated = contracts.findIndex(contract => contract?._id === activeContract?._id);
      const payrollToBeUpdated = contracts[contractToBeUpdated]?.payrolls?.findIndex(
        payroll => payroll?._id === deleteFileModalMeta?.file?.payrollId
      );
      contracts[contractToBeUpdated].payrolls[payrollToBeUpdated] = response;
      setContracts([...contracts]);

      // Updating Payrolls

      setPayrolls([...payrolls?.filter(payroll => payroll?._id !== deleteFileModalMeta?.file?.payrollId), response]);
    } else {
      updateContractList(response);
    }
    setDeleteFileModalMeta(null);
    deleteFileFromServer(toBeDeletedFile);
  };

  const onInternalPayrollFileDeletion = (payrollId, fileId) => {
    const contractToBeUpdated = contracts.findIndex(contract => contract?._id === activeContract?._id);
    const payrollToBeUpdated = contracts[contractToBeUpdated]?.payrolls?.findIndex(
      payroll => payroll?._id === payrollId
    );

    contracts[contractToBeUpdated].payrolls[payrollToBeUpdated].files.splice(
      contracts[contractToBeUpdated]?.payrolls[payrollToBeUpdated]?.files?.findIndex(file => file?._id === fileId),
      1
    );
    setContracts([...contracts]);
  };

  const onAppChoicesUpdate = appChoices => {
    setAppChoices(appChoices);
    setShowAppChoiceUpdatePopup(false);
  };

  const onDuplicateClick = () => {
    setCreateContractMeta({ prefillContract: { ...activeContract, comments: '', fromContract: true } });
  };
  const updatePayroll = newPayroll => {
    const existingPayrollIndex = payrolls.findIndex(p => p['_id'] === newPayroll['_id']);
    if (existingPayrollIndex !== -1) {
      payrolls[existingPayrollIndex] = newPayroll;
    } else {
      payrolls.push(newPayroll);
    }

    setPayrolls([...payrolls]);
  };

  const submitPayroll = async (payroll, files) => {
    const { payrollBill: payrollToBeUpdated } = payrollBillMeta;

    const newPayroll = {
      ...payroll,
      files: [...payrollToBeUpdated.files.map(f => f._id), ...files]
    };

    toast.info('Updating payroll bill...');
    setPayrollBillMeta({ ...payrollBillMeta, submittingPayroll: true });

    const { response, error } = await makeApiRequests({
      requestBody: newPayroll,
      endpoint: ENDPOINTS.PAYROLLS_WITH_ID(payrollToBeUpdated._id),
      method: 'PUT'
    });

    setPayrollBillMeta({ ...payrollBillMeta, submittingPayroll: false });

    if (error) {
      return toast.error(error);
    }

    toast.success('Payroll bill updated successfully...');
    setPayrollBillMeta(null);
    updatePayroll(response);
    updateContract(response);
  };

  const updateContract = updatedPayroll => {
    const contractToBeUpdated = contracts.findIndex(contract => contract?._id === activeContract?._id);
    const payrollToBeUpdated = contracts[contractToBeUpdated]?.payrolls?.findIndex(
      payroll => payroll?._id === payrollBillMeta?.payrollBill?._id
    );

    if (payrollToBeUpdated !== -1) {
      contracts[contractToBeUpdated].payrolls[payrollToBeUpdated] = updatedPayroll;
    } else {
      contracts[contractToBeUpdated].payrolls.push(updatedPayroll);
    }
    setContracts([...contracts]);
  };

  const onPayrollAdd = payroll => {
    setPayrolls([payroll, ...payrolls]);
    updateContract(payroll);
    setShowRecordPayrollSidebar(false);
  };

  const fetchPayrolls = async () => {
    if (isObjectIncomplete(activeContract, RETURN_PATHS.CONTRACT)) return;

    setPayrollLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.PAYROLLS_SEARCH,
      requestBody: {
        sortBy: '-date',
        filter: activeContract
          ? {
              contract: activeContract._id
            }
          : undefined
      }
    });

    setPayrollLoading(false);
    if (error) {
      return toast.error(error);
    }

    setPayrolls(
      response.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      })
    );
    setPayrollLoading(false);
  };

  useEffect(() => {
    fetchPayrolls();
  }, [activeContract]);

  const payrollFiles = useMemo(() => {
    if (activeContract && activeContract?.payrolls) {
      const allPayrollFiles =
        activeContract?.payrolls?.reduce((totalFiles, currentPayroll) => {
          return [
            ...totalFiles,
            ...(currentPayroll?.files?.map(file => ({ ...file, payrollId: currentPayroll?._id })) || [])
          ];
        }, []) ?? [];
      if (allPayrollFiles && allPayrollFiles[allPayrollFiles?.length - 1]) {
        allPayrollFiles[allPayrollFiles?.length - 1].classifier = 'Payroll Files';
      }
      return allPayrollFiles;
    } else {
      return [];
    }
  }, [activeContract, contracts, payrolls]);

  useEffect(() => {
    if (activeContract && activeContract?.files) {
      if (activeContract?.files && activeContract.files[activeContract.files.length - 1]) {
        activeContract.files[activeContract.files.length - 1].classifier = 'Contract Files';
      }
    }
  }, [activeContract, contracts, payrolls]);

  const handleOpenDochub = () => {
    setOpenDochubMeta({ contractId: activeContract?._id });
  };

  return (
    <Container fluid className={'h-100 pt-2 pb-3 px-md-2'}>
      <SearchBox
        appChoices={appChoices}
        filterCreationOptions={contractSearchFilterOptions}
        sortByOptions={contractSearchSortByOptions}
        maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
        onSearchOptionsChange={fetchContracts}
        disabled={loading}
      />
      {loading && <HorizontalProgress text={'Fetching contracts...'} />}

      <hr />

      {contracts.length > 0 ? (
        <>
          <Row className="h-100 px-md-3">
            <Col xs={4} md={2} className="p-0 pb-5 overflow-auto h-100">
              <ListFilterText allListMode={allListMode} onClearFilterClick={() => setAllListMode(true)} />
              <ContractList
                contracts={filteredContracts}
                activeId={activeContract ? activeContract['_id'] : ''}
                onSelect={onContractSelect}
                appChoices={appChoices}
              />
            </Col>
            <Col xs={8} md={10} className="h-100 overflow-auto px-1 mb-5">
              <StatusTabs
                statuses={roleWiseStatuses}
                activeStatusObject={activeStatusObject}
                onStatusClick={s => {
                  setAllListMode(false);
                  setActiveStatusObject(s);
                }}
              />
              <Card className="mt-2 mb-5">
                {activeContract ? (
                  isObjectIncomplete(activeContract, RETURN_PATHS.CONTRACT) ? (
                    <PipelineLoaderCard
                      loadingError={activeContract?.loadingError}
                      title={[activeContract['buyer'], activeContract['coBuyer']].filter(Boolean).join(', ')}
                      onRetryClick={() => fetchCompleteContract(activeContract)}
                    />
                  ) : (
                    <>
                      <Card.Header className="bg-primary text-white px-2 py-1 smallFont">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h6 className="mb-0 d-inline-block midFont hover-underline" onClick={onContractEditClick}>
                              {[activeContract['buyer'], activeContract['coBuyer']].filter(Boolean).join(', ')}
                            </h6>
                            {role !== 'installer' && (
                              <InfoSquareFill
                                className="align-text-bottom ml-2 hover-light"
                                onClick={onContractInfoClick}
                              />
                            )}

                            {activeContract['inventoryUnit'] &&
                              activeContract['inventoryUnit']['manufacturer'] &&
                              activeContract['inventoryUnit']['manufacturer'].isNPS && (
                                <Badge className="align-text-bottom ml-2" variant={'info'}>
                                  NPS Sale
                                </Badge>
                              )}
                            {activeContract?.['inventoryUnit']?.['manufacturer']?.isNPS &&
                              (!showNpsCallDate ? (
                                <Badge className="align-text-bottom ml-2 mt-1" variant={'info'} title="NPS Call Date">
                                  <div className="d-flex justify-content-between align-items-center">
                                    {/* todo Update */}
                                    {activeContract?.npsCallDate
                                      ? moment(activeContract?.npsCallDate)?.format('MMMM Do YYYY')
                                      : 'No NPS Call Date'}
                                    {/* todo Update */}
                                    <Pen
                                      className="ml-2 text-light smallFont pointer"
                                      size={8}
                                      onClick={() =>
                                        setShowNpsCallDate(
                                          activeContract?.npsCallDate
                                            ? moment(activeContract?.npsCallDate)?.format('YYYY-MM-DD')
                                            : moment()?.format('YYYY-MM-DD')
                                        )
                                      }
                                    />
                                  </div>
                                </Badge>
                              ) : (
                                <Badge className=" ml-2 ">
                                  <span className="d-flex justify-content-start align-items-center ">
                                    <Form.Control
                                      type="date"
                                      placeholder="Call Date"
                                      size="sm"
                                      className="tinyFont w-100"
                                      value={showNpsCallDate || null}
                                      onChange={e => setShowNpsCallDate(e.target.value)}
                                      disabled={callDateInUpdate}
                                    />
                                    <Button
                                      className="p-0 ml-1"
                                      variant="success"
                                      onClick={() => editNpsCallDate()}
                                      disabled={callDateInUpdate}
                                    >
                                      <Check />
                                    </Button>
                                    <Button
                                      className="p-0 ml-1"
                                      variant="danger"
                                      onClick={() => setShowNpsCallDate(null)}
                                      disabled={callDateInUpdate}
                                    >
                                      <X />
                                    </Button>
                                  </span>
                                </Badge>
                              ))}

                            {!showDateOfDepositeDateCell ? (
                              <Badge
                                className="align-text-bottom ml-2 mt-1"
                                variant={'success'}
                                title="Date of Deposit"
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  {/* todo Update */}
                                  {activeContract?.dateOfDeposit
                                    ? `Deposit : ${moment(activeContract?.dateOfDeposit)?.format('MMMM Do YYYY')}`
                                    : 'No Date of Deposit'}
                                  {/* todo Update */}
                                  <Pen
                                    className="ml-2 text-light smallFont pointer"
                                    size={8}
                                    onClick={() =>
                                      setShowDateOfDepositeDateCell(
                                        activeContract?.dateOfDeposit
                                          ? moment(activeContract?.dateOfDeposit)?.format('YYYY-MM-DD')
                                          : moment()?.format('YYYY-MM-DD')
                                      )
                                    }
                                  />
                                </div>
                              </Badge>
                            ) : (
                              <Badge className=" ml-2 ">
                                <span className="d-flex justify-content-start align-items-center ">
                                  <Form.Control
                                    type="date"
                                    placeholder="Call Date"
                                    size="sm"
                                    className="tinyFont w-100"
                                    value={showDateOfDepositeDateCell || null}
                                    onChange={e => setShowDateOfDepositeDateCell(e.target.value)}
                                    disabled={dateOfDepositeInUpdate}
                                  />
                                  <Button
                                    className="p-0 ml-1"
                                    variant="success"
                                    onClick={() => editDateOfDeposite()}
                                    disabled={dateOfDepositeInUpdate}
                                  >
                                    <Check />
                                  </Button>
                                  <Button
                                    className="p-0 ml-1"
                                    variant="danger"
                                    onClick={() => setShowDateOfDepositeDateCell(null)}
                                    disabled={dateOfDepositeInUpdate}
                                  >
                                    <X />
                                  </Button>
                                </span>
                              </Badge>
                            )}

                            {activeContract['dateFormTSent'] && (
                              <Badge className="align-text-bottom ml-2" variant={'warning'}>
                                Form T Sent
                              </Badge>
                            )}
                            {activeContract['dateSolReportFiled'] && (
                              <Badge className="align-text-bottom mr-2" variant={'warning'}>
                                Sol Filed
                              </Badge>
                            )}
                            {getRebateBadge()}
                            {getACRebateBadge()}
                            {activeContract['stateInspection'] &&
                              activeContract['stateInspection'].toLowerCase().includes('yes') && (
                                <Badge className="align-text-bottom ml-2" variant="dark">
                                  State Inspection
                                </Badge>
                              )}
                            {activeContract.inventoryUnit?.manufacturer?.isFactoryMakeReady && (
                              <Badge className="align-text-bottom ml-2" variant="dark">
                                Factory Makeready
                              </Badge>
                            )}
                            {activeContract.inventoryUnit?.hasFurnitureInside?.toLowerCase() === 'yes' && (
                              <Badge className="align-text-bottom ml-2" variant="dark">
                                Has Furniture
                              </Badge>
                            )}
                          </div>

                          <div>
                            <Button
                              className="ml-2 px-1 py-0"
                              size="sm"
                              variant="warning"
                              onClick={() => setPayrollViewContract(activeContract)}
                            >
                              <Receipt className="align-text-top mr-1" />
                              Payroll
                            </Button>
                            {activeStatusObject && activeStatusObject.notify && (
                              <>
                                <Button
                                  className="ml-2 px-1 py-0"
                                  size="sm"
                                  variant="light"
                                  onClick={() => onSendNotificationClick(activeStatusObject.notify)}
                                >
                                  <Mailbox className="align-text-top mr-1" />
                                  Notify {activeStatusObject.notify.name}
                                </Button>
                                <NotificationModal
                                  activeNotificationType={activeNotificationType}
                                  onHide={handleNotificationModalClose}
                                  sendingNotification={sendingNotification}
                                />
                              </>
                            )}
                            <Dropdown className="d-inline-block">
                              <Dropdown.Toggle className=" ml-2 px-1 py-0" size="sm" variant="warning">
                                Copy
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={e => {
                                    copyContractDetails(activeContract);
                                    toast.success('Copied Contract!');
                                  }}
                                >
                                  Contract
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={e => {
                                    copyContractAddress(activeContract);
                                    toast.success('Copied Address!');
                                  }}
                                >
                                  Address
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            <Button
                              onClick={() => setShowFileViewer(true)}
                              variant="dark"
                              size="sm"
                              className="ml-2 px-1 py-0"
                            >
                              <Files className="align-text-top mr-1" />
                              Files
                            </Button>

                            {role !== 'installer' && (
                              <Dropdown className="d-inline-block">
                                <Dropdown.Toggle className=" ml-2 px-1 py-0" size="sm" variant="info">
                                  Print
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={handleOpenDochub}>Commission Sheet</Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={e => {
                                      openLinkInNewTab(
                                        `/dochub/print-groups?contractId=${activeContract['_id']}&source=Contract`
                                      );
                                    }}
                                  >
                                    Open Dochub
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}

                            <Button onClick={onDuplicateClick} size="sm" className="ml-2 px-1 py-0" variant="success">
                              Duplicate
                            </Button>
                          </div>
                        </div>
                      </Card.Header>
                      {!hideForm && !createContractMeta && !sidebarMode && !openDochubMeta && (
                        <Card.Body className="p-2 fade-in">
                          <div class="border border-dark p-2 rounded bg-light mb-2">
                            <h6 className="underline">Calculated Values:</h6>
                            <div class="row text-dark midFont">
                              <div class="col col-12 col-md-4 mb-1">
                                Net Commission: <b>${calculatedValues?.netCommission}</b>
                              </div>
                              <div class="col col-12 col-md-4 mb-1">
                                Total of Options: <b>${calculatedValues?.totalOfOptions}</b>
                              </div>
                              <div class="col col-12 col-md-4 mb-1">
                                Gross Profit: <b>${calculatedValues?.grossProfit}</b>
                              </div>
                              <div class="col col-12 col-md-4 mb-1">
                                Commission Due: <b>${calculatedValues?.commissionDue}</b>
                              </div>
                              <div class="col col-12 col-md-4 mb-1">
                                Commission Due with Bonus: <b>${calculatedValues?.commissionDueWithBonus}</b>
                              </div>
                              <div class="col col-12 col-md-4 mb-1">
                                Front End: <b>${calculatedValues?.frontEnd}</b>
                              </div>
                              <div class="col col-12 col-md-4 mb-1">
                                Back End: <b>${calculatedValues?.backEnd}</b>
                              </div>
                              <div class="col col-12 col-md-4 mb-1">
                                Balance to be Paid: <b>${calculatedValues?.balanceToBePaid}</b>
                              </div>
                              <div class="col col-12 col-md-4 mb-1">
                                Balance after Advance: <b>${calculatedValues?.balanceAfterAdvance}</b>
                              </div>
                            </div>
                          </div>
                          <OverViewColumns
                            className="px-3"
                            fontSize="smallFont"
                            md={3}
                            dateFields={staticSectionDateFields}
                            dateTimeFields={staticSectionDateTimeFields}
                            priceFields={staticSectionPriceFields}
                            emailRows={staticSectionEmailFields}
                            numRows={staticSectionPhoneFields}
                            dropdownFields={staticSectionDropdownFields}
                            urlFields={staticSectionUrlFields}
                            data={activeContract}
                            fieldsToShow={staticSectionFields}
                            editable
                            showProgress={overviewColumnEditMeta?.showProgress}
                            isEditing={overviewColumnEditMeta?.isEditing}
                            onToggleEditMode={() =>
                              setOverviewColumnEditMeta({
                                ...overviewColumnEditMeta,
                                isEditing: !overviewColumnEditMeta.isEditing
                              })
                            }
                            onSubmit={onOverviewColumnsSubmit}
                          />

                          <hr className="my-2" />
                          <div className="mx-2">
                            <QuickToggles
                              data={activeContract}
                              toggleOptions={contractToggleOptions({ contract: activeContract, appChoices })}
                              onToggle={toggleContract}
                              toggling={toggling}
                            />
                          </div>
                          <hr className="my-2" />
                          <div className="m-3">
                            <ContractedUnitOverview
                              onEditUnitClick={() => setEditingInventory(activeContract['inventoryUnit'])}
                              onLinkUnitClick={onLinkUnitClick}
                              onRemoveUnitClick={onRemoveUnitClick}
                              onChangeUnitClick={onLinkUnitClick}
                              inventoryUnit={activeContract['inventoryUnit']}
                            />
                          </div>

                          {form && !createContractMeta && !sidebarMode && !openDochubMeta && (
                            <>
                              {(['super admin', 'admin'].includes(role) ||
                                activeStatusObject.editRoles.includes(role)) && (
                                <File
                                  uploadedFiles={uploadedFiles}
                                  onUploadedFilesChange={setUploadedFiles}
                                  containerClassName="mx-3"
                                />
                              )}
                              <FormGenerator
                                prefix="pipeline"
                                formJson={form}
                                formValues={{ Pipeline: getObjectForPrefill(activeContract) }}
                                formDisabled={
                                  !['super admin', 'admin'].includes(role) &&
                                  !activeStatusObject.editRoles.includes(role)
                                }
                              />
                            </>
                          )}
                          <hr />

                          <FileViewer
                            show={showFileViewer}
                            onHide={() => setShowFileViewer(false)}
                            files={[...activeContract['files'], ...payrollFiles]}
                            onFileDeleteClick={onFileDeleteClick}
                            driveFolder={activeContract['driveFolder']}
                          />
                          <AlertModal
                            show={deleteFileModalMeta !== null}
                            alertText={`Are you sure to delete this file (${
                              deleteFileModalMeta
                                ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                                  ? deleteFileModalMeta.file.metadata.originalName
                                  : deleteFileModalMeta.file.filename
                                : ''
                            }). You will lose access to this file permanently?`}
                            showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
                            progressText="Deleting file..."
                            continueButtonText="Delete"
                            onDismissClick={() => setDeleteFileModalMeta(null)}
                            onHide={() => setDeleteFileModalMeta(null)}
                            onContinueClick={deleteFile}
                          />
                          {activeStatusObject && activeStatusObject.showServiceOrderReport && (
                            <>
                              <Alert variant="primary" className="mx-3 p-2">
                                <div className="d-flex">
                                  <div className="flex-grow-1 align-self-center">
                                    <h6 className="mb-0">
                                      <b>Service Order Report</b>
                                    </h6>
                                  </div>
                                  <div>
                                    {activeContract['serviceOrderReport'] ? (
                                      <>
                                        <Button
                                          variant="primary"
                                          size="sm"
                                          className=" m-0 py-0"
                                          onClick={onServiceOrderReportClick}
                                        >
                                          View
                                        </Button>
                                        <Link
                                          target="_blank"
                                          to={`/dochub/print-groups?contractId=${activeContract['_id']}&document=Service Request&source=Contract`}
                                        >
                                          <Button variant="primary" size="sm" className=" m-0 py-0 ml-1">
                                            Print
                                          </Button>
                                        </Link>
                                      </>
                                    ) : (
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        className=" m-0 py-0"
                                        onClick={onServiceOrderReportClick}
                                      >
                                        Start
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </Alert>

                              {ServiceReportModal()}

                              <hr />
                            </>
                          )}
                          <div className="m-3">
                            <Comments
                              comments={activeContract['comments']}
                              disabled={buttonsDisabled}
                              onCommentSubmit={submitComment}
                              onCommentDeleteClick={onCommentDeleteClick}
                              commentText={commentText}
                              onCommentTextChange={setCommentText}
                            />
                          </div>
                        </Card.Body>
                      )}
                      <InventoryPicker
                        excludedUnitIds={
                          activeContract['inventoryUnit'] ? [activeContract['inventoryUnit']['_id']] : []
                        }
                        selectedUnits={
                          inventoryPopupMeta && inventoryPopupMeta.selectedUnit ? [inventoryPopupMeta.selectedUnit] : []
                        }
                        onUnitSelect={onUnitSelect}
                        show={inventoryPopupMeta !== null}
                        submitting={assigningInventory}
                        onSubmit={onInventorySubmit}
                        fromContracts
                        onInventoryPickerClose={() => setInventoryPopupMeta(null)}
                      />
                      <AlertModal
                        show={showRemoveUnitAlertModal}
                        alertText="Are you sure to remove this inventory unit from contract?"
                        showProgress={removingInventory}
                        progressText="Removing unit..."
                        onDismissClick={() => setShowRemoveUnitAlertModal(false)}
                        onHide={() => setShowRemoveUnitAlertModal(false)}
                        onContinueClick={removeInventoryUnit}
                      />
                      {role === 'super admin' && (
                        <AlertModal
                          show={deleteCommentModalMeta !== null}
                          alertText="Are you sure to remove this comment?"
                          showProgress={deleteCommentModalMeta?.showProgress}
                          progressText="Deleting comment..."
                          onDismissClick={() => setDeleteCommentModalMeta(null)}
                          onHide={() => setDeleteCommentModalMeta(null)}
                          onContinueClick={deleteComment}
                        />
                      )}
                      {activeContract['inventoryUnit'] && (
                        <InventoryEditSideBar
                          appChoices={appChoices}
                          onHide={() => setEditingInventory(null)}
                          editingInventory={editingInventory}
                          onInventoryEdit={onInventoryEdit}
                          onInventoryDelete={onInventoryDelete}
                        />
                      )}
                      <PayrollViewSidebar
                        show={payrollViewContract !== null}
                        activeContract={payrollViewContract}
                        onHide={() => setPayrollViewContract(null)}
                        payrollBillMeta={payrollBillMeta}
                        onPayrollBillMetaChange={setPayrollBillMeta}
                        submitPayroll={submitPayroll}
                        updatePayroll={updatePayroll}
                        payrolls={payrolls}
                        onPayrollsChange={setPayrolls}
                        loading={payrollLoading}
                        setToBeDeletedBill={setToBeDeletedBill}
                        deleteItem={deleteItem}
                        deleteInProgress={deleteInProgress}
                        toBeDeletedBill={toBeDeletedBill}
                        onPayrollAdd={onPayrollAdd}
                        onShowRecordPayrollSidebarChange={setShowRecordPayrollSidebar}
                        showRecordPayrollSidebar={showRecordPayrollSidebar}
                        onInternalPayrollFileDeletion={onInternalPayrollFileDeletion}
                      />
                    </>
                  )
                ) : (
                  <>
                    <Card.Header className="bg-primary text-white p-2">
                      <h6 className="mb-0">Select a contract</h6>
                    </Card.Header>
                    <Card.Body>
                      <h6 className="p-5 text-center">No contract selected. Selected contract will appear here...</h6>
                    </Card.Body>
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <ContractInfoSideBar
            appChoices={appChoices}
            contract={activeContract}
            sidebarMode={sidebarMode}
            onContractDelete={onContractDelete}
            onContractEdit={updateContractList}
            onContractInfoSideBarHide={onContractInfoSideBarHide}
          />
        </>
      ) : (
        !loading && <NotFound text="No contracts to show!" />
      )}

      <FloatingButton
        variant="success"
        text={'Add New Contract'}
        Icon={PlusCircle}
        onClick={() => setCreateContractMeta({})}
      />
      <CreateSideBar
        onContractAdd={updateContractList}
        appChoices={appChoices}
        show={createContractMeta !== null}
        onHide={() => setCreateContractMeta(null)}
        prefillContract={createContractMeta?.prefillContract}
      />
      <AppChoicesSidebar
        entityToExclude={['Print Groups', 'Sales Locations']}
        onAppChoicesUpdate={onAppChoicesUpdate}
        show={showAppChoiceUpdatePopup}
        onHide={() => setShowAppChoiceUpdatePopup(false)}
      />
      <DochubSidebar
        show={openDochubMeta}
        onHide={() => setOpenDochubMeta(null)}
        appChoices={appChoices}
        source={'Contract'}
        contractId={openDochubMeta?.contractId}
      />
    </Container>
  );
};

export default Pipeline;
