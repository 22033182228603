import React from 'react';
import { Clipboard, Pen, Pencil } from 'react-bootstrap-icons';

class ActionCellTemplate {
  getCompatibleCell(uncertainCell) {
    return {
      ...uncertainCell,
      onClick: uncertainCell.onClick || (() => {}),
      text: '',
      value: 0
    };
  }

  handleKeyDown() {
    return { cell: {}, enableEditMode: false };
  }

  update(cell, cellToMerge) {
    return { ...cell, ...cellToMerge };
  }

  render(cell) {
    return (
      <div className="d-flex justify-content-center w-100 gap-2">
        <Pencil
          className={`hover-light`}
          size={12}
          onClick={e => {
            e.stopPropagation();
            cell.onEditClick();
          }}
        />

        <Clipboard
          className="hover-light"
          onClick={e => {
            e.stopPropagation();
            cell.onPrintClick();
          }}
        />
      </div>
    );
  }
}

export const actionCellTemplate = new ActionCellTemplate();
