import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Container, Modal, ProgressBar } from 'react-bootstrap';
import { ChevronDown, ChevronUp, Clipboard, Pen, PeopleFill, PlusCircle, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { setAttribute } from '../../form-generator/helpers/utility';
import { makeApiRequests } from '../../helpers/api';
import { APP_ROLES, ENDPOINTS } from '../../helpers/constants';
import { getObjectForPrefill } from '../../helpers/formHelpers';
import { copyToClipboard, filterUsersRespectedToRoles } from '../../helpers/global';
import AlertModal from '../AlertModal';
import EntityTable from '../common/EntityTable';
import Loader from '../Loader';
import { getUserAddRows, getUserUpdateRows, userHeaders } from './constants';

const userTableSearchKeys = ['name', 'email', 'phone', 'role'];

const userSalesRepRow = salesRepsOptions => {
  const groupedByAddress = salesRepsOptions.reduce((acc, { name, _id, salesLocation }) => {
    const { location } = salesLocation;

    if (!acc[location]) {
      acc[location] = [];
    }

    acc[location].push({ label: name, value: _id });

    return acc;
  }, {});

  return [
    {
      columns: Object.keys(groupedByAddress).map(location => ({
        default: 6,
        field: {
          id: 'salesReps',
          type: 'checkbox-group',
          title: location,
          options: groupedByAddress[location].map(user => user.label),
          optionValues: groupedByAddress[location].map(user => user.value)
        }
      }))
    }
  ];
};

const getForm = ({ rows, type = 'Add', salesLocationOptions = [], installerOptions = [] }) => {
  const form = {
    forms: [
      {
        name: `${type} user`,
        markCompulsoryFields: true,
        hideFormName: true,
        compact: true,
        submit: {
          name: type,
          show: true,
          onSubmit: type === 'Add' ? 'onNewUserFormSubmit' : 'onUpdateUserFormSubmit'
        },
        rows
      }
    ]
  };

  setAttribute(
    form,
    'salesLocation',
    'options',
    salesLocationOptions.map(s => s.location)
  );
  setAttribute(
    form,
    'salesLocation',
    'optionValues',
    salesLocationOptions.map(s => s._id)
  );
  setAttribute(
    form,
    'installer',
    'options',
    installerOptions.map(s => s.name)
  );
  setAttribute(
    form,
    'installer',
    'optionValues',
    installerOptions.map(s => s._id)
  );
  return form;
};

const AddEditModal = ({ show, toBeEditedUser, onHide, showProgress, addForm, updateForm, isForSalesReps }) => (
  <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
    <Modal.Header closeButton={!showProgress}>
      <Modal.Title>
        <h6 className="mb-0">
          {isForSalesReps ? 'Select Sales Representatives' : toBeEditedUser ? 'Update User' : 'Add New User'}
        </h6>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-auto px-1">
      <FormGenerator
        formJson={toBeEditedUser ? updateForm : addForm}
        formValues={toBeEditedUser ? { 'Update user': getObjectForPrefill(toBeEditedUser) } : {}}
      />
      {showProgress && (
        <ProgressBar now={100} animated striped label={toBeEditedUser ? `Updating user...` : `Saving user....`} />
      )}
    </Modal.Body>
  </Modal>
);

const Users = ({ appChoices = [] }) => {
  const [loggedInRole] = useState(localStorage.getItem('user-role'));
  const [salesRepsOptions, setSalesRepsOptions] = useState([]);
  const [salesLocationOptions] = useState(appChoices.find(ac => ac.key === 'salesLocation').values);
  const [installerOptions] = useState(appChoices.find(ac => ac.key === 'installer').values);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [toBeDeletedUser, setToBeDeletedUser] = useState(null);
  const [userActionMeta, setUserActionMeta] = useState(null);
  const [userAddingUpdating, setUserAddingUpdating] = useState(false);
  const [toggleShowMoreUsersSectionWise, setToggleShowMoreUsersSectionWise] = useState({});
  const userAddRows = useMemo(() => getUserAddRows(loggedInRole === 'super admin'), [loggedInRole]);
  const userUpdateRows = useMemo(() => getUserUpdateRows(loggedInRole === 'super admin'), [loggedInRole]);

  const fetchUsers = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_LIST,
      method: 'POST'
    });

    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setUsers(response);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setSalesRepsOptions(users.filter(user => user.role === 'Sales'));
  }, [users]);

  useEffect(() => {
    APP_ROLES.forEach(role => {
      toggleShowMoreUsersSectionWise[role] = true;
    });
    setToggleShowMoreUsersSectionWise({ ...toggleShowMoreUsersSectionWise });
  }, []);

  const onToogleUserSectionChange = (key, value) => {
    toggleShowMoreUsersSectionWise[key] = value;
    setToggleShowMoreUsersSectionWise({ ...toggleShowMoreUsersSectionWise });
  };

  const deleteUser = async () => {
    setDeleteInProgress(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_BASE,
      requestBody: {
        email: toBeDeletedUser.email
      },
      method: 'DELETE'
    });

    setDeleteInProgress(false);
    if (error) {
      toast.error(error);
      return;
    }

    users.splice(
      users.findIndex(i => i._id === toBeDeletedUser._id),
      1
    );
    setUsers([...users]);
    setToBeDeletedUser(null);
  };

  const onNewUserFormSubmit = async form => {
    if (form['role'] === 'Installer' && !form['installer']) {
      return toast.error('Please select an installer for this user!');
    }

    if (['Sales', 'Sales Manager'].includes(form['role']) && !form['salesLocation']) {
      return toast.error('Sales location is required for sales members!');
    }

    setUserAddingUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_BASE,
      requestBody: form
    });

    setUserAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    setUsers([response, ...users]);
    setUserActionMeta(null);
  };

  const onCopyToClipboardClick = (userList, section = 'All') => {
    copyToClipboard(userList.map(user => user.email).join(' ,'));
    toast.success(`${section} Users Email Successfully Copied To Clipboard`);
  };

  const onUpdateUserFormSubmit = async form => {
    if (form['role'] === 'Installer' && !form['installer']) {
      return toast.error('Please select an installer for this user!');
    }

    if (['Sales', 'Sales Manager'].includes(form['role']) && !form['salesLocation']) {
      return toast.error('Sales location is required for sales members!');
    }

    setUserAddingUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: { ...form, email: userActionMeta.user.email }
    });

    setUserAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    const userIndex = users.findIndex(i => i._id === response._id);
    users[userIndex] = { ...users[userIndex], ...response };
    setUsers([...users]);
    setUserActionMeta(null);
  };

  window['onNewUserFormSubmit'] = onNewUserFormSubmit;
  window['onUpdateUserFormSubmit'] = onUpdateUserFormSubmit;

  return (
    <Container fluid className="h-100 py-3 px-md-3">
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h6>Users</h6>
            {!loading && !userAddingUpdating && (
              <Clipboard
                className="pointer"
                title="Copy All Users Email To Clipboard"
                size={20}
                onClick={() => onCopyToClipboardClick(users)}
              />
            )}
          </div>
          <hr className="my-3" />
          {loading ? (
            <Loader />
          ) : (
            <>
              {' '}
              <EntityTable
                rowKeyField={'_id'}
                data={users}
                headers={userHeaders}
                searchQueryKeys={userTableSearchKeys}
                additionalComponents={() => (
                  <Button size="sm" variant="success" onClick={() => setUserActionMeta({ mode: 'add' })}>
                    <PlusCircle className="mr-1" /> New user
                  </Button>
                )}
                // Component That Renders The Group Header
                additionalHeaderComponentForGroupValues={currentGroupValue => (
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start align-items-center">
                      <h6 className="midFont my-0">{currentGroupValue}</h6>
                      {toggleShowMoreUsersSectionWise[currentGroupValue] ? (
                        <ChevronUp
                          className="ml-2 pointer mb-1"
                          title="Show Less"
                          onClick={() => onToogleUserSectionChange(currentGroupValue, false)}
                          size={17}
                        />
                      ) : (
                        <ChevronDown
                          className="ml-2 pointer"
                          title="Show More"
                          onClick={() => onToogleUserSectionChange(currentGroupValue, true)}
                          size={17}
                        />
                      )}
                    </div>
                    <Clipboard
                      size={17}
                      title={`Copy All ${currentGroupValue} Users Email`}
                      className="pointer mr-4"
                      onClick={() =>
                        onCopyToClipboardClick(
                          users.filter(user => user?.role === currentGroupValue),
                          currentGroupValue
                        )
                      }
                    />
                  </div>
                )}
                groupBy="role" // This Is The Factor According To Which Data Groups
                groupValues={APP_ROLES} // These Are Those Values According To Which Data Groups Eg : ["Super Admin","Admin"]
                toggleShowMoreSection={toggleShowMoreUsersSectionWise} // Toggle Can Only Be Implemented When groupBy Value Is Passed
                actionCell={user => (
                  <div>
                    {user.role.toLowerCase() === 'sales manager' && (
                      <Button
                        variant="outline-primary"
                        className="px-1 py-0 mr-1"
                        onClick={() => setUserActionMeta({ user, mode: 'salesrep-edit' })}
                      >
                        <PeopleFill size={10} />
                      </Button>
                    )}
                    {(loggedInRole === 'super admin' ||
                      !['super admin', 'admin'].includes(user.role.toLowerCase())) && (
                      <>
                        <Button
                          variant="outline-primary"
                          className="px-1 py-0 mr-1"
                          onClick={() => setUserActionMeta({ user, mode: 'edit' })}
                        >
                          <Pen size={10} />
                        </Button>
                        <Button
                          variant="outline-danger"
                          className="px-1 py-0 "
                          onClick={() => setToBeDeletedUser(user)}
                        >
                          <Trash size={10} />
                        </Button>
                      </>
                    )}
                  </div>
                )}
              />
              {users.length > 0 && (
                <AlertModal
                  show={toBeDeletedUser !== null}
                  alertText={`Are you sure you want to delete this user? This action cannot be undone!`}
                  onHide={() => setToBeDeletedUser(null)}
                  onDismissClick={() => setToBeDeletedUser(null)}
                  onContinueClick={deleteUser}
                  progressText={`Deleting user...`}
                  showProgress={deleteInProgress}
                />
              )}
              <AddEditModal
                show={userActionMeta !== null}
                onHide={() => setUserActionMeta(null)}
                toBeEditedUser={userActionMeta ? userActionMeta.user : null}
                showProgress={userAddingUpdating}
                isForSalesReps={userActionMeta && userActionMeta.mode === 'salesrep-edit'}
                addForm={getForm({ rows: userAddRows, salesLocationOptions, installerOptions })}
                updateForm={getForm({
                  rows:
                    userActionMeta && userActionMeta.mode === 'salesrep-edit'
                      ? userSalesRepRow(salesRepsOptions)
                      : userUpdateRows,
                  type: 'Update',
                  salesLocationOptions,
                  installerOptions
                })}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Users;
