import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, FormControl, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, isMicasa, months } from '../../helpers/constants';
import { copyToClipboard, downloadFileFromBase64 } from '../../helpers/global';
import Loader from '../Loader';
import PnlEditSidebar from '../main/PnlEditSidebar';
import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import { debounce } from 'lodash';
import { actionCellTemplate } from '../common/PnLActionCell';

const getYears = () => {
  const nowYear = new Date().getFullYear();
  const years = [nowYear];

  for (let index = 1; index <= 5; index++) {
    years.push(nowYear - index);
  }

  return years.reverse();
};

const calculateRebate = contract => {
  if (contract.pnlRebate !== null && contract.pnlRebate !== undefined) {
    return contract.pnlRebate;
  }

  const inventoryUnit = contract.newStatus === 'Contracted' ? contract?.inventoryUnit : contract?.inventoryUnit[0];

  const val = 0.05 * (Number(inventoryUnit?.basePrice || 0) + Number(inventoryUnit?.options || 0));
  return val.toFixed(2);
};

const calculateCommissionDue = c => {
  const inventoryInvoice = Number(
    c.newStatus === 'Contracted' ? c?.inventoryUnit?.invoice : c?.inventoryUnit[0]?.invoice || 0
  );
  const salesPrice = Number(c?.salesPrice || 0);
  // active contact is converted to formgenerator needed form so we are taking value from array
  const commissionLessValue = c?.commissionSheetLessOptions?.reduce((a, b) => a + Number(b?.value || 0), 0);

  const netCommissionsTotal = salesPrice - commissionLessValue;

  const optionsTotal = inventoryInvoice + c?.commissionSheetOptions?.reduce((a, b) => a + Number(b?.value || 0), 0);
  const grossProfit = netCommissionsTotal - optionsTotal;

  let commissionDue;

  if (isMicasa) {
    commissionDue = grossProfit >= 20000 ? 0.25 * grossProfit : 0.2 * grossProfit;
  } else {
    commissionDue = grossProfit / 2;
  }

  const curtailmentBonus = Number(c?.additionalFields?.curtailmentBonus || 0);
  const teamLeaderBonus = Number(c?.additionalFields?.teamLeaderBonus || 0);

  const commissionDueWithBonus = commissionDue + curtailmentBonus + teamLeaderBonus;

  return commissionDueWithBonus;
};

const expenseFields = [
  { isPnLOption: true, key: 'Doc Prep' },
  { isPnLOption: true, key: 'Freight Reimbursement' },
  { isPnLOption: true, key: 'NPS' },
  { key: 'pnlRebate', valueGetter: calculateRebate },
  { key: 'SP/Com', valueGetter: c => Number(c.salesPrice || 0) - 499 }
];

const incomeFields = [
  {
    label: 'Invoice',
    key: 'invoice',
    valueGetter: c => (c.newStatus === 'Working' ? c?.inventoryUnit[0]?.invoice : c?.inventoryUnit?.invoice)
  },
  { label: 'Del & set', isPnLOption: true, key: 'Delivery/Set Up' },
  { label: 'Air Condition', isPnLOption: true, key: 'Air Condition' },
  { label: 'Skirting', isPnLOption: true, key: 'Skirting' },
  { label: 'Utilities', isPnLOption: true, key: 'Utilities' },
  { label: 'Commission Due', key: 'Commission Due', valueGetter: calculateCommissionDue },
  { label: 'Team Leader', isPnLOption: true, key: 'Team Leader' },
  { label: 'Admin', isPnLOption: true, key: 'Admin' },
  { label: 'Service', isPnLOption: true, key: 'Service' },
  { label: 'Bank fees', isPnLOption: true, key: 'Bank Fees' }
];

const getPnlValue = (contract, key, appChoices) => {
  //first look in pnl
  const value = contract['pnlOptions']?.find(o => o.key === key)?.value;
  let pnlValue;
  if (value != null || value !== undefined) {
    pnlValue = Number(value);
  } else {
    //if not in pnl use pnlAppChoices
    const pnlAppChoices = appChoices?.find(o => o.key === 'PnL Options')?.values;
    const defaultValue = pnlAppChoices?.find(o => o.name === key)?.defaultValue;
    pnlValue = defaultValue ? Number(defaultValue) : 0;
  }

  return isNaN(pnlValue) ? 0 : pnlValue;
};

const pnlHeaders = [
  { label: 'Buyer', key: 'buyer' },
  { label: 'Salesperson', key: 'salesperson' },
  { label: 'Status', key: 'newStatus' },
  { label: 'Funding status', key: 'fundingStatus' },
  {
    label: 'Size Overall',
    key: 'sizeOverall',
    valueGetter: c => (c.newStatus === 'Working' ? c?.inventoryUnit[0]?.sizeOverall : c?.inventoryUnit?.sizeOverall)
  },
  {
    label: 'Invoice',
    key: 'invoice',
    valueGetter: c => (c.newStatus === 'Working' ? c?.inventoryUnit[0]?.invoice : c?.inventoryUnit?.invoice)
  },
  { label: 'Del & set', isPnLOption: true, key: 'Delivery/Set Up' },
  { label: 'Air Condition', isPnLOption: true, key: 'Air Condition' },
  { label: 'Skirting', isPnLOption: true, key: 'Skirting' },
  { label: 'Utilities', isPnLOption: true, key: 'Utilities' },
  { label: 'Commission Due', key: 'Commission Due', valueGetter: calculateCommissionDue },
  { label: 'Team Leader', isPnLOption: true, key: 'Team Leader' },
  { label: 'Admin', isPnLOption: true, key: 'Admin' },
  { label: 'Service', isPnLOption: true, key: 'Service' },
  { label: 'Bank fees', isPnLOption: true, key: 'Bank Fees' },

  { label: 'SP/Comm', key: 'SP/Com', valueGetter: c => Number(c.salesPrice || 0) - 499 },
  { label: 'Rebate', key: 'pnlRebate', valueGetter: calculateRebate, isEditable: true },
  { label: 'Frg. Reim', isPnLOption: true, key: 'Freight Reimbursement' },
  { label: 'NPS', isPnLOption: true, key: 'NPS' },
  { label: 'Doc Prep', isPnLOption: true, key: 'Doc Prep' },
  { label: 'TOTAL GP', key: 'TOTAL GP' },
  { label: 'Bank', key: 'lender', valueGetter: c => c?.lender?.shortName || '-' },
  {
    label: 'Factory',
    key: 'tradeInManufacturer',
    valueGetter: c =>
      c.newStatus === 'Contracted'
        ? c?.inventoryUnit?.manufacturer?.name
        : c?.inventoryUnit?.[0]?.manufacturer?.name || '-'
  },
  {
    label: 'Date of Deposit',
    key: 'dateOfDeposit',
    valueGetter: c => moment(c?.dateOfDeposit).format('MMMM Do YYYY')
  }
];

const calculateTotalGP = (contract, appChoices) => {
  const reduceTotalValue = (prev, { key, valueGetter, isPnLOption }) => {
    let value;
    if (isPnLOption) {
      value = getPnlValue(contract, key, appChoices);
    } else {
      value = (valueGetter ? valueGetter(contract) : contract[key]) || 0;
    }

    return prev + Number(value);
  };

  const totalGPMinuend = expenseFields.reduce(reduceTotalValue, 0);
  const totalGPSubtrahend = incomeFields.reduce(reduceTotalValue, 0);

  return totalGPMinuend - totalGPSubtrahend;
};

const SelectionBox = ({
  selectedMonth,
  onMonthChange,
  selectedYear,
  onYearChange,
  years,
  salesLocations,
  selectedSalesLocation,
  onSalesLocationChange,
  headers,
  filteredContracts,
  printDocument,
  printing,
  appChoices,
  searchQuery,
  setSearchQuery
}) => {
  const dataForCsv = useMemo(() => {
    const data = filteredContracts.map(c => {
      let returnObj = {};
      headers.forEach(({ key, isPnLOption, valueGetter }) => {
        if (key === 'TOTAL GP') {
          returnObj[key] = calculateTotalGP(c, appChoices);
        } else {
          returnObj[key] = valueGetter ? valueGetter(c) : isPnLOption ? getPnlValue(c, key, appChoices) : c[key];
        }
      });
      return returnObj;
    });

    return [
      ...data,
      {
        'Doc Prep': 'Total Gp',
        'TOTAL GP': `${filteredContracts.reduce((prev, c) => prev + calculateTotalGP(c, appChoices), 0).toFixed(2)}`
      },
      {
        'Doc Prep': 'AVERAGE GP',
        'TOTAL GP': `${(
          filteredContracts.reduce((prev, c) => prev + calculateTotalGP(c, appChoices), 0) / filteredContracts.length
        ).toFixed(2)}`
      }
    ];
  }, [headers, filteredContracts, appChoices]);

  return (
    <>
      <Card className="border rounded">
        <Card.Header className="bg-primary">
          <h5 className="mb-0 text-white midFont">Dealboard (P&L)</h5>
        </Card.Header>
        <Card.Body className="p-1">
          <Row>
            {[
              {
                title: 'Search',
                type: 'text',
                value: searchQuery,
                onChange: setSearchQuery,
                hint: 'Search by buyer name...'
              },
              {
                title: 'Sales Locations',
                options: salesLocations.map(s => s.location),
                optionValues: salesLocations.map(s => s._id),
                value: selectedSalesLocation,
                onChange: onSalesLocationChange
              },
              { title: 'Select Month', options: ['', ...months], value: selectedMonth, onChange: onMonthChange },
              { title: 'Select Year', options: ['', ...years], value: selectedYear, onChange: onYearChange }
            ].map(({ title, options, optionValues, value, onChange, multiple, md = 3, type, hint }) => (
              <Col xs={6} md={md} className="px-4 mt-3">
                <h6 className="mb-1 smallFont">{title}</h6>
                {multiple ? (
                  <MultiSelect
                    showCheckbox
                    closeOnChangedValue={false}
                    className="midFont"
                    value={value.map(v => ({ label: v, value: v }))}
                    options={options.map(o => ({ label: o, value: o === 'Unassigned' ? null : o }))}
                    onChange={list => onChange(list.map(listOption => listOption.value))}
                  />
                ) : type === 'text' ? (
                  <FormControl
                    value={value}
                    size="sm"
                    onChange={e => onChange(e.target.value)}
                    required
                    placeholder={hint}
                  />
                ) : (
                  <FormControl as="select" value={value} size="sm" onChange={e => onChange(e)} required>
                    {options.map((option, index) => (
                      <option key={option} value={optionValues ? optionValues[index] : option}>
                        {option}
                      </option>
                    ))}
                  </FormControl>
                )}
              </Col>
            ))}
            <Col xs={12} className="mt-3 mb-1 text-right">
              <Button
                size="md"
                className="mx-2"
                onClick={printDocument}
                disabled={printing || filteredContracts.length === 0}
              >
                Print
              </Button>
              <CSVLink data={dataForCsv} headers={headers} filename="data.csv" className="btn btn-primary">
                Export as CSV
              </CSVLink>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

const PnL = ({ appChoices }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [contacts, setContacts] = useState([]);

  const [lists, setLists] = useState([]);

  const [years] = useState(getYears());
  const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [salesLocations] = useState(appChoices.find(ac => ac.key === 'salesLocation').values);
  const [selectedSalesLocation, setSelectedSalesLocation] = useState(salesLocations[0]._id);
  const [editingContract, setEditingContract] = useState(null);

  const [printing, setPrinting] = useState(false);
  const [excludedContracts, setExcludedContracts] = useState([]);

  const [selectedAreas, setSelectedAreas] = useState([]);
  const [columnWidths, setColumnWidths] = useState({
    print: 80,
    ...Object.fromEntries(pnlHeaders.map(header => [header.label, 150])),
    action: 100
  });
  const [pendingCells, setPendingCells] = useState({});

  const handleColumnResized = (columnId, newWidth) => {
    setColumnWidths(prev => ({
      ...prev,
      [columnId]: Math.max(80, Math.min(500, newWidth))
    }));
  };

  useEffect(() => {
    if (searchQuery) {
      setSelectedYear('');
      setSelectedMonth('');
    } else {
      setSelectedYear(years[years.length - 1]);
      setSelectedMonth(months[new Date().getMonth()]);
    }
  }, [searchQuery]);

  const onExcludeContractChange = (id, checked) => {
    if (checked) {
      const exisiting = excludedContracts.indexOf(id);
      if (exisiting !== -1) excludedContracts.splice(exisiting, 1);
    } else {
      if (!excludedContracts.includes(id)) excludedContracts.push(id);
    }
    setExcludedContracts([...excludedContracts]);
  };

  const printDocument = async () => {
    toast.info('Submitting report, please wait...');
    setPrinting(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.DOCHUB_PRINT_TABLE,
      requestBody: {
        table: [
          pnlHeaders.map(h => h.label),
          ...filteredContracts
            .filter(c => !excludedContracts.includes(c['_id']))
            .map(c =>
              pnlHeaders.map(({ key, label, valueGetter, isPnLOption }) => {
                if (valueGetter) return valueGetter(c);
                if (label === 'TOTAL GP') return `$${calculateTotalGP(c, appChoices).toFixed(2)}`;

                return isPnLOption ? getPnlValue(c, key, appChoices) : c[key] ? c[key] : '-';
              })
            ),
          pnlHeaders.map((h, i) =>
            h.label !== 'TOTAL GP'
              ? pnlHeaders[i + 1]?.label === 'TOTAL GP'
                ? 'TOTAL GP'
                : ''
              : `$${filteredContracts.reduce((prev, c) => prev + calculateTotalGP(c, appChoices), 0).toFixed(2)}`
          ),
          pnlHeaders.map((h, i) =>
            h.label !== 'TOTAL GP'
              ? pnlHeaders[i + 1]?.label === 'TOTAL GP'
                ? 'AVERAGE GP'
                : ''
              : `$${(
                  filteredContracts.reduce((prev, c) => prev + calculateTotalGP(c, appChoices), 0) /
                  filteredContracts.length
                ).toFixed(2)}`
          )
        ],
        fileName: `pnl-${new Date().toDateString()}.pdf`
      }
    });

    setPrinting(false);
    if (error) return toast.error(error);

    toast.success('Document created successfully, file is being downloaded...');
    const { fileName, data, mimeType } = response;
    downloadFileFromBase64({ fileName, data, mimeType });
  };

  const fetchContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH,
      requestBody: {
        filter: {
          dateOfDeposit: { $ne: null },
          inventoryUnit: { $ne: null }
        },
        sort: '-dateOfDeposit'
      }
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(response);
  };

  const fetchContacts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_SEARCH,
      requestBody: {
        filter: {
          dateOfDeposit: { $ne: null },
          inventoryUnit: { $ne: null }
        },
        sort: '-dateOfDeposit'
      }
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContacts(response);
  };

  useEffect(() => {
    fetchContracts();
    fetchContacts();
  }, []);

  useEffect(() => {
    const allItems = [
      ...contracts.map(c => ({ newStatus: 'Contracted', ...c })),
      ...contacts
        .filter((c, a) => {
          const isContactContracted = contracts.find(
            cO => cO._id === c?.exportedContract || cO._id === c?.exportedContract?._id
          );

          return !isContactContracted && c?.inventoryUnit?.length === 1;
        })
        .map(c => ({ newStatus: 'Working', ...c }))
    ].sort((a, b) => new Date(b.dateOfDeposit) - new Date(a.dateOfDeposit));
    setLists(allItems);
  }, [contacts, contracts]);

  const filterContracts = () => {
    const tempContracts = lists.filter(contract => {
      if (contract?.salesLocation?._id !== selectedSalesLocation) return false;

      if (searchQuery && !contract?.buyer?.toLowerCase().includes(searchQuery.toLowerCase())) return false;

      const dateToFilter = new Date(contract?.dateOfDeposit);

      if (selectedMonth) {
        const month = months.indexOf(selectedMonth);
        if (month !== dateToFilter.getMonth()) return false;
      }

      if (selectedYear) {
        if (selectedYear !== dateToFilter.getFullYear()) return false;
      }

      return true;
    });

    setFilteredContracts(tempContracts);
  };

  const updateContract = useCallback(async (contract, field, value, key) => {
    const isContact = contract?.newStatus === 'Working';

    const updateBody = {
      _id: contract['_id'],
      [field]: value
    };

    const { response, error } = await makeApiRequests({
      endpoint: isContact ? ENDPOINTS.CONTACTS_WITH_ID(contract['_id']) : ENDPOINTS.CONTRACTS_WITH_ID(contract['_id']),
      requestBody: updateBody,
      method: 'PUT'
    });

    if (error) return toast.error(error);

    if (isContact) {
      setContacts(prev => prev.map(c => (c['_id'] === contract['_id'] ? response : c)));
    } else {
      setContracts(prev => prev.map(c => (c['_id'] === contract['_id'] ? response : c)));
    }
    toast.success('Updated Successfully');
    setPendingCells(prev => {
      const newState = { ...prev };
      delete newState[key];
      return newState;
    });
  }, []);

  const debouncedUpdateContract = useMemo(() => debounce(updateContract, 500), [updateContract]);

  const handleCellsChanged = changes => {
    if (changes.length !== 1) return;
    changes.forEach(change => {
      const { rowId, columnId, newCell } = change;

      if (columnId === 'print') {
        const isChecked = newCell.checked;
        const contractId = filteredContracts[parseInt(rowId.split('-')[1])]?._id;
        if (contractId) onExcludeContractChange(contractId, isChecked);
        return;
      }

      if (rowId.startsWith('data-')) {
        const index = parseInt(rowId.split('-')[1]);
        const contract = filteredContracts[index];
        if (!contract) return;

        const header = pnlHeaders.find(h => h.label === columnId);
        if (!header) return;

        if (newCell.nonEditable || !(header.isEditable || header.isPnLOption)) return;

        const value = newCell.text;
        const numericValue = parseFloat(value);

        if (isNaN(numericValue)) {
          toast.error(`Invalid number for ${header.label}`);
          return;
        }

        if (header.isEditable) {
          const key = `${contract._id}-${header.key}`;
          setPendingCells(prev => ({ ...prev, [key]: true }));
          debouncedUpdateContract(contract, header.key, numericValue, key);
        } else if (header.isPnLOption) {
          const currentOptions = contract.pnlOptions || [];
          const existingIndex = currentOptions.findIndex(o => o.key === header.key);
          const newOptions = [...currentOptions];

          if (existingIndex !== -1) {
            newOptions[existingIndex].value = numericValue;
          } else {
            newOptions.push({ key: header.key, value: numericValue });
          }
          const key = `${contract._id}-${header.key}`;
          setPendingCells(prev => ({ ...prev, [key]: true }));

          debouncedUpdateContract(contract, 'pnlOptions', newOptions, key);
        }
      }
    });
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    filterContracts();
  }, [lists, selectedYear, selectedMonth, selectedSalesLocation, searchQuery]);

  const onYearChange = e => {
    setSelectedYear(e.target.value ? Number(e.target.value) : null);
  };

  const onMonthChange = e => {
    setSelectedMonth(e.target.value);
  };

  const onSalesLocationChange = e => {
    setSelectedSalesLocation(e.target.value);
  };

  const updateList = co => {
    const { type } = editingContract;
    const stateUpdater = type === 'Contact' ? setContacts : setContracts;
    stateUpdater(prev => prev.map(c => (c._id === co._id ? co : c)));
  };

  const handleCopyRow = contract => {
    const values = pnlHeaders.map(header => {
      let value;

      if (header.label === 'TOTAL GP') {
        value = calculateTotalGP(contract, appChoices);
      } else if (header.valueGetter) {
        value = header.valueGetter(contract);
      } else if (header.isPnLOption) {
        value = getPnlValue(contract, header.key, appChoices);
      } else {
        value = contract[header.key];
      }

      if (typeof value === 'number') {
        value = value.toFixed(2);
      }

      if (!value) {
        value = '-';
      }

      return value;
    });

    const tsvLine = values.join('\t');
    copyToClipboard(tsvLine);
    toast.success('Row copied to clipboard');
  };

  const columns = useMemo(
    () => [
      {
        columnId: 'print',
        width: columnWidths['print'],
        resizable: true,
        headerCell: () => 'Print?'
      },
      ...pnlHeaders.map(header => ({
        columnId: header.label,
        width: columnWidths[header.label],
        resizable: true,
        headerCell: () => header.label
      })),
      {
        columnId: 'action',
        width: columnWidths['action'],
        resizable: false,
        headerCell: () => 'Action'
      }
    ],
    [columnWidths]
  );

  const getRows = () => {
    const headerRow = {
      rowId: 'header',
      height: 60,
      cells: [
        {
          type: 'header',
          text: 'Print?',
          className: 'bg-dark text-light text-center d-flex justify-content-center',
          style: { fontSize: '12px', fontWeight: 'bold' }
        },
        ...pnlHeaders.map(h => ({
          type: 'header',
          text: h.label,
          className: 'bg-dark text-light d-flex justify-content-center',
          style: { fontSize: '12px', fontWeight: 'bold' }
        })),
        {
          type: 'header',
          text: 'Action',
          className: 'bg-dark text-light d-flex justify-content-center',
          style: { fontSize: '12px', fontWeight: 'bold' }
        }
      ]
    };

    const commonCellStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '12px',
      fontWeight: 'semibold'
    };

    const dataRows = filteredContracts.map((contract, idx) => ({
      rowId: `data-${idx}`,
      height: 60,
      cells: [
        {
          type: 'checkbox',
          checked: !excludedContracts.includes(contract._id),
          onClick: checked => onExcludeContractChange(contract._id, checked),
          style: commonCellStyle
        },
        ...pnlHeaders.map(header => {
          const { label, key, isPnLOption, valueGetter, isEditable } = header;

          const pendingKey = `${contract._id}-${header.key}`;
          const isPending = pendingCells[pendingKey];

          if (label === 'TOTAL GP') {
            return {
              type: 'text',
              text: `$${calculateTotalGP(contract, appChoices).toFixed(2)}`,
              className: 'font-weight-bold bg-light',
              nonEditable: true,
              style: commonCellStyle
            };
          }

          let value = isPnLOption ? getPnlValue(contract, key, appChoices) : contract[key];
          if (valueGetter) value = valueGetter(contract);
          const displayValue = typeof value === 'number' ? value.toFixed(2) : value || '-';

          return {
            type: 'text',
            text: displayValue,
            nonEditable: !isPnLOption && !isEditable,
            className: !isPnLOption && !isEditable ? 'bg-light' : '',
            style: {
              ...commonCellStyle,
              opacity: isPending ? 0.5 : 1,
              backgroundColor: isPending ? '#fff3cd' : 'inherit'
            }
          };
        }),
        {
          type: 'action',
          onEditClick: () =>
            setEditingContract({
              contract,
              type: contract.newStatus === 'Contracted' ? 'Contract' : 'Contact'
            }),
          onPrintClick: () => handleCopyRow(contract)
        }
      ]
    }));

    if (!filteredContracts?.length) {
      return [headerRow, ...dataRows];
    }

    const totalGP = filteredContracts.reduce((prev, c) => prev + calculateTotalGP(c, appChoices), 0);
    const averageGP = filteredContracts.length > 0 ? totalGP / filteredContracts.length : 0;

    const totalGPColIndex = pnlHeaders.findIndex(h => h.label === 'TOTAL GP') + 1;

    const totalRow = {
      rowId: 'total-gp',
      height: 40,
      cells: [
        {
          type: 'text',
          text: '',
          nonEditable: true,
          className: 'border-right-0 user-select-none'
        },

        ...Array(totalGPColIndex - 2).fill({
          type: 'text',
          text: '',
          nonEditable: true,
          className: 'border-right-0'
        }),

        {
          type: 'text',
          text: 'TOTAL GP',
          className: 'text-end fw-bold ',
          nonEditable: true,
          style: commonCellStyle
        },

        {
          type: 'text',
          text: `$${totalGP.toFixed(2)}`,
          className: 'fw-bold ',
          nonEditable: true,
          style: commonCellStyle
        }
      ]
    };

    const averageRow = {
      rowId: 'average-gp',
      height: 40,
      cells: [
        {
          type: 'text',
          text: '',
          nonEditable: true,
          className: 'border-right-0 user-select-none'
        },

        ...Array(totalGPColIndex - 2).fill({
          type: 'text',
          text: '',
          nonEditable: true,
          className: 'border-right-0 user-select-none'
        }),

        {
          type: 'text',
          text: 'AVERAGE GP',
          className: 'text-end fw-bold',
          nonEditable: true,
          style: commonCellStyle
        },

        {
          type: 'text',
          text: `$${averageGP.toFixed(2)}`,
          className: 'fw-bold',
          nonEditable: true,
          style: commonCellStyle
        }
      ]
    };

    return [headerRow, ...dataRows, totalRow, averageRow];
  };

  const rows = getRows();

  useEffect(() => {
    const handleCopy = e => {
      if (e.ctrlKey && e.key === 'c') {
        e.preventDefault();
        const text = selectedAreas
          .flatMap(area => {
            const rows = [];
            for (let rowIdx = area.top; rowIdx <= area.bottom; rowIdx++) {
              const rowCells = [];
              for (let colIdx = area.left; colIdx <= area.right; colIdx++) {
                const cell = rows[rowIdx]?.cells[colIdx];
                rowCells.push(cell?.text || '');
              }
              rows.push(rowCells.join('\t'));
            }
            return rows;
          })
          .join('\n');
        navigator.clipboard.writeText(text);
      }
    };

    document.addEventListener('keydown', handleCopy);
    return () => document.removeEventListener('keydown', handleCopy);
  }, [selectedAreas, rows]);

  return (
    <div className="px-1 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          <SelectionBox
            years={years}
            selectedMonth={selectedMonth}
            onMonthChange={onMonthChange}
            selectedYear={selectedYear}
            onYearChange={onYearChange}
            salesLocations={salesLocations}
            selectedSalesLocation={selectedSalesLocation}
            onSalesLocationChange={onSalesLocationChange}
            printing={printing}
            filteredContracts={filteredContracts}
            printDocument={printDocument}
            headers={pnlHeaders}
            appChoices={appChoices}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <hr />
          <h6>P&L:</h6>

          <div className="overflow-auto hide-scrollbar">
            <ReactGrid
              rows={rows}
              columns={columns}
              onCellsChanged={handleCellsChanged}
              onColumnResized={handleColumnResized}
              enableRangeSelection
              enableRowSelection
              stickyTopRows={1}
              selectedAreas={selectedAreas}
              onSelectionChanged={setSelectedAreas}
              customCellTemplates={{ action: actionCellTemplate }}
              style={{ minWidth: `${Object.values(columnWidths).reduce((a, b) => a + b, 0)}px` }}
            />
          </div>
          {!filteredContracts?.length ? <h6 className="my-2 text-center">No contracts to show</h6> : null}

          <h6 className="text-right text-muted mt-2">
            Total Reports: <span className="text-dark">{filteredContracts.length}</span>
          </h6>
          {filteredContracts.length > 0 && (
            <PnlEditSidebar
              show={editingContract !== null}
              appChoices={appChoices}
              contract={editingContract?.contract}
              type={editingContract?.type}
              onContractEdit={updateList}
              onHide={() => setEditingContract(null)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PnL;
