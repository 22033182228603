import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, FormControl, Row, Table } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import DropDownInput from '../../form-generator/components/DropDownInput';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { downloadFileFromBase64 } from '../../helpers/global';
import Loader from '../Loader';
import Toggler from '../Toggler';
import CommentsCompact from '../comments/CommentCompact';

const headers = ['Buyer', 'Status', 'Tech', 'Sales Location', 'Manufacturer', 'Comments'];

const headerKeys = ['buyer', 'status', 'tech', 'salesLocation', 'manufacturer', 'comments'];

const getStatusesForService = appChoices => {
  const allStatuses = appChoices.find(choice => choice.key === 'Status').values;
  let startIndex = allStatuses.findIndex(status => status.toLowerCase().includes('needs utilities'));

  if (startIndex === -1) {
    startIndex = allStatuses.findIndex(status => status.toLowerCase().includes('needs service'));
  }

  return startIndex === -1 ? [] : allStatuses.slice(startIndex);
};

const Service = ({ appChoices }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const statusOptions = useMemo(() => getStatusesForService(appChoices), [appChoices]);
  const [status, setSelectedStatus] = useState([...statusOptions]);

  const [salesLocationOptions] = useState([
    { location: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'salesLocation').values
  ]);

  const [salesLocation, setSelectedSalesLocation] = useState(salesLocationOptions[0]);

  const [manufacturerOptions] = useState([
    { name: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'manufacturer').values
  ]);

  const [manufacturer, setSelectedManufacterer] = useState(manufacturerOptions[0]);

  const [techOptions] = useState([{ name: 'All', _id: 'All' }, ...appChoices.find(ac => ac.key === 'tech').values]);
  const [techFilter, setTechFilter] = useState(techOptions[0]);
  const [numberOfSectionsOptions] = useState(['SW', 'DW']);
  const [numberOfSectionFilter, setNumberOfSectionFilter] = useState([...numberOfSectionsOptions]);

  const [query, setQuery] = useState('');

  const [filteredContracts, setFilteredContracts] = useState([]);
  const [excludedContracts, setExcludedContracts] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [contractUpdating, setContractUpdating] = useState(false);

  const fetchContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW,
      requestBody: {
        filter: {
          status: { $in: statusOptions.filter(s => !['Complete'].includes(s)) }
        },
        returnPaths: ['buyer', 'salesLocation', 'inventoryUnit', 'status', 'comments', 'tech']
      }
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(
      response.results
        .filter(c => c['salesLocation'])
        .map(c => {
          const { inventoryUnit } = c;
          if (!inventoryUnit) return c;

          inventoryUnit['inventoryId'] = inventoryUnit['_id'];
          const { _id, comments, ...rest } = inventoryUnit;
          return { ...c, ...rest };
        })
    );
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const filterContracts = () => {
    const queryToSearch = query.trim().toLowerCase();
    const tempContracts = contracts.filter(c => {
      if (queryToSearch && !c.buyer.toLowerCase().includes(queryToSearch)) {
        return false;
      }

      if (!numberOfSectionFilter.length) return false;

      const sectionFilter =
        numberOfSectionFilter.length === numberOfSectionsOptions.length
          ? true
          : numberOfSectionFilter.includes('DW')
          ? c.inventoryUnit?.numberOfSections > 1
          : numberOfSectionFilter.includes('SW')
          ? c.inventoryUnit?.numberOfSections === 1
          : false;

      return (
        sectionFilter &&
        [
          { title: 'status', value: status },
          {
            title: 'salesLocation',
            value: salesLocation,
            isObject: true
          },
          {
            title: 'manufacturer',
            value: manufacturer,
            isObject: true
          },
          {
            title: 'tech',
            value: techFilter,
            isObject: true
          }
        ].every(({ title, value, isObject }) => {
          if (!Array.isArray(value)) value = [value];

          if (isObject) {
            value = value.map(v => v._id);
          }

          return value.includes('All') || value.includes(isObject ? c[title]?._id : c[title]);
        })
      );
    });

    const groupedContracts = [];
    for (const location of salesLocationOptions) {
      groupedContracts.push(
        ...tempContracts
          .filter(c => c['salesLocation']._id === location._id)
          .sort((a, b) => {
            return a['dateSigned'] && b['dateSigned'] ? Date.parse(b['dateSigned']) - Date.parse(a['dateSigned']) : -1;
          })
      );
    }

    setFilteredContracts(groupedContracts);
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    setExcludedContracts([]);
    filterContracts();
  }, [contracts, status, manufacturer, salesLocation, query, techFilter, numberOfSectionFilter]);

  const SelectionBox = () => {
    return (
      <>
        <Card className="border rounded">
          <Card.Header className="bg-primary">
            <h5 className="mb-0 text-white">Service Report</h5>
          </Card.Header>
          <Card.Body className="py-3">
            <Row>
              {[
                {
                  title: 'Status',
                  value: status,
                  options: statusOptions,
                  onChange: setSelectedStatus,
                  multiple: true
                },
                {
                  title: 'Sales Location',
                  value: salesLocation,
                  options: salesLocationOptions,
                  onChange: setSelectedSalesLocation,
                  isObject: true,
                  objectLabelKey: 'location'
                },
                {
                  title: 'Manufacturer',
                  value: manufacturer,
                  options: manufacturerOptions,
                  onChange: setSelectedManufacterer,
                  isObject: true,
                  objectLabelKey: 'name'
                },
                {
                  title: 'Tech',
                  value: techFilter,
                  options: techOptions,
                  onChange: setTechFilter,
                  isObject: true,
                  objectLabelKey: 'name'
                },
                {
                  title: 'Number of Sections',
                  value: numberOfSectionFilter,
                  options: numberOfSectionsOptions,
                  onChange: setNumberOfSectionFilter,
                  multiple: true
                }
              ].map(dropdownMeta => (
                <Col key={dropdownMeta.title} xs={12} md={4} className="px-4 mt-3">
                  <h6 className="mb-2">{dropdownMeta.title}</h6>
                  {dropdownMeta.multiple ? (
                    <MultiSelect
                      showCheckbox
                      closeOnChangedValue={false}
                      className="midFont"
                      value={dropdownMeta.value.map(v =>
                        dropdownMeta.isObject
                          ? { label: v[dropdownMeta.objectLabelKey], value: v._id }
                          : { label: v, value: v }
                      )}
                      options={dropdownMeta.options.map(o =>
                        dropdownMeta.isObject
                          ? { label: o[dropdownMeta.objectLabelKey], value: o._id }
                          : { label: o, value: o === 'Unassigned' ? null : o }
                      )}
                      onChange={list =>
                        dropdownMeta.onChange(
                          list.map(listOption =>
                            dropdownMeta.isObject
                              ? dropdownMeta.options.find(o => o._id === listOption.value)
                              : listOption.value
                          )
                        )
                      }
                    />
                  ) : (
                    <DropDownInput
                      value={dropdownMeta.isObject ? dropdownMeta.value._id : dropdownMeta.value}
                      options={
                        dropdownMeta.isObject
                          ? dropdownMeta.options.map(o => o[dropdownMeta.objectLabelKey])
                          : dropdownMeta.options
                      }
                      optionValues={
                        dropdownMeta.isObject
                          ? dropdownMeta.options.map(o => o._id)
                          : dropdownMeta.options.map(o => (o === 'Unassigned' ? null : o))
                      }
                      onChangeFunction={e => {
                        if (!e) return;
                        dropdownMeta.onChange(
                          dropdownMeta.isObject
                            ? dropdownMeta.options.find(o => o._id === e.target.value)
                            : e.target.value
                        );
                      }}
                      required
                    />
                  )}
                </Col>
              ))}
              <Col xs={12} className="mt-3 text-right">
                <Button onClick={printDocument} disabled={submitting || filteredContracts.length === 0}>
                  Print
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const updateContract = (contract, field, value) => {
    const existingContract = contracts.find(c => c['_id'] === contract['_id']);
    if (existingContract) {
      existingContract[field] = value;
    }

    setContracts([...contracts]);
  };

  const onExcludeContractChange = (id, checked) => {
    if (checked) {
      const exisiting = excludedContracts.indexOf(id);
      if (exisiting !== -1) excludedContracts.splice(exisiting, 1);
    } else {
      if (!excludedContracts.includes(id)) excludedContracts.push(id);
    }
    setExcludedContracts([...excludedContracts]);
  };

  const printDocument = async () => {
    toast.info('Submitting report, please wait...');
    setSubmitting(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.DOCHUB_PRINT_TABLE,
      requestBody: {
        table: [
          headers,
          ...filteredContracts
            .filter(c => !excludedContracts.includes(c['_id']))
            .map(c =>
              headerKeys.map(headerKey => {
                if (!c[headerKey]) return '';

                if (headerKey === 'salesLocation') return c[headerKey].location;
                if (headerKey === 'comments') {
                  const comments = c[headerKey] || [];
                  if (!comments.length) return '';
                  return comments[comments.length - 1]?.comment;
                }
                return typeof c[headerKey] === 'object' ? c[headerKey].name : c[headerKey];
              })
            )
        ],
        fileName: `service-${new Date().toDateString()}.pdf`
      }
    });

    setSubmitting(false);
    if (error) return toast.error(error);

    toast.success('Document created successfully, file is being downloaded...');
    const { fileName, data, mimeType } = response;
    downloadFileFromBase64({ fileName, data, mimeType });
  };

  const submitComment = async contract => {
    toast.info('Adding comment...');
    setContractUpdating(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        comments: commentText
      },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contract?._id)
    });

    setContractUpdating(false);

    if (error) {
      return toast.error(error);
    }

    toast.success(`Comment added successfully!`);
    setCommentText('');

    updateContract(response, 'comments', response.comments);
  };

  return (
    <div className="px-0 px-md-4 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          {SelectionBox()}
          <hr />
          <h6>Contracts:</h6>
          <FormControl
            size={'sm'}
            placeholder="Search by buyer..."
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
          <Table style={{ fontSize: 12 }} responsive bordered className="text-center mt-2">
            <tr className="bg-dark text-light">
              <th>Print?</th>
              {headers.map(h => (
                <th key={`header-${h}`} style={h === 'comments' ? { minWidth: 700, maxWidth: 700 } : undefined}>
                  {h}
                </th>
              ))}
            </tr>
            {filteredContracts.length > 0 ? (
              <>
                {filteredContracts.map(c => (
                  <tr key={c['_id']}>
                    <td className="justify-items-center">
                      <FormControl
                        checked={!excludedContracts.includes(c['_id'])}
                        style={{ width: 16, height: 16 }}
                        type="checkbox"
                        onChange={e => onExcludeContractChange(c['_id'], e.target.checked)}
                      />
                    </td>
                    <td>{c['buyer']}</td>
                    <td className="px-1">
                      <Toggler
                        id={c['_id']}
                        fieldName="status"
                        value={c['status']}
                        options={statusOptions}
                        onFieldUpdate={(field, value) => updateContract(c, field, value)}
                      />
                    </td>
                    <td>{c['tech']?.name || '-'}</td>
                    <td>{c['salesLocation'].location}</td>
                    <td>{c['manufacturer']?.name || '-'}</td>

                    <td className="px-1 text-left" style={{ minWidth: 700, maxWidth: 700 }}>
                      <CommentsCompact
                        expandedMode={expandedRow === c._id}
                        onCommentExpandClick={() => setExpandedRow(expandedRow === c._id ? null : c._id)}
                        comments={c.comments}
                        commentText={commentText}
                        onCommentTextChange={value => setCommentText(value)}
                        onCommentSubmit={() => submitComment(c)}
                        disabled={contractUpdating}
                      />
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={headers.length + 1}>No contracts to show</td>
              </tr>
            )}
          </Table>
          <h6 className="text-right text-muted">
            Total Reports: <span className="text-dark">{filteredContracts.length}</span>
          </h6>
        </>
      )}
    </div>
  );
};

export default Service;
