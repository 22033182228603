import React from 'react';
import { Globe, Person, Phone, Envelope, PhoneLandscape, ArrowUpRightCircle, Printer } from 'react-bootstrap-icons';
import { Row, Col, Badge, Button, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PindropURLButton from '../common/PindropURLButton';

const getDaysFromToday = iso => {
  const date = new Date(iso);
  const today = new Date();

  const diff = Math.ceil((date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

  if (!diff) return 'Today';

  return diff < 0 ? `Already due (${diff * -1}) day(s)` : `${diff} day(s)`;
};

const getDaysToToday = iso => {
  const date = new Date(iso);
  const today = new Date();

  const diff = Math.ceil((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

  return diff < 0 ? `Already due (${diff * -1})` : diff;
};

const CrmSearchItem = ({
  crm,
  onContactClick,
  onImportClick,
  onDuplicateClick,
  selected,
  fromPicker = false,
  npsSales = false,
  role = '',
  mini,
  onChangeClick,
  onRemoveClick
}) => {
  return (
    <div
      style={{ fontSize: 14 }}
      className={`d-flex ${onContactClick ? 'hover-light' : ''} p-${mini ? '1' : '2'} rounded ${
        selected ? 'border border-primary' : ''
      }`}
      onClick={e => {
        const cellText = document.getSelection();
        if (cellText.toString().length > 0) {
          return;
        }

        onContactClick && onContactClick();
      }}
    >
      <div className="text-center mb-3 p-2 rounded">
        <Person size={mini ? 25 : 34} />
      </div>

      <div className="flex-grow-1 pl-2">
        <div className="d-flex">
          <div className="flex-grow-1">
            <div>
              <h6 className="d-inline-block mr-2 mb-0">
                <Link
                  className=" text-dark"
                  to={'/crm/' + crm['_id']}
                  target="_blank"
                  onClick={e => e.stopPropagation()}
                >
                  {crm['buyer']}
                  {crm['coBuyer'] ? `, ${crm['coBuyer']}` : ''}
                  <ArrowUpRightCircle size={14} />
                </Link>
              </h6>
              <Badge className="align-text-bottom" variant={'info'}>
                {crm['status']}
              </Badge>
              {npsSales && (
                <Badge className="align-text-bottom ml-2" variant={'primary'}>
                  NPS Sale
                </Badge>
              )}
            </div>
            {['sales manager', 'super admin', 'admin'].includes(role) && crm['contactOwner'] && (
              <p style={{ fontSize: 12 }} className="mb-0 mt-1  text-secondary">
                <i>
                  <span className="text-dark">Contact Owner: </span>
                  {crm['contactOwner'].name}
                </i>
              </p>
            )}
          </div>

          <div className="align-self-center">
            {!fromPicker && (
              <>
                {' '}
                {['super admin', 'admin', 'sales', 'sales manager'].includes(role) && (
                  <Link target="_blank" to={`/dochub/print-groups?contactId=${crm['_id']}&source=Contact`}>
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      className="mr-1"
                      variant="outline-dark"
                      size="sm"
                    >
                      <Printer className="align-text-top" />
                    </Button>
                  </Link>
                )}
              </>
            )}

            {!fromPicker && (
              <>
                {' '}
                {['super admin', 'admin'].includes(role) && (
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      onImportClick && onImportClick();
                    }}
                    className="mr-1"
                    size="sm"
                    variant="success"
                  >
                    Import
                  </Button>
                )}
              </>
            )}

            <Button
              onClick={e => {
                e.stopPropagation();
                onDuplicateClick && onDuplicateClick();
              }}
              size="sm"
              variant="success mr-2"
              className={fromPicker && 'tinyFont'}
            >
              Duplicate
            </Button>
            {!fromPicker && crm['pindropUrl'] && <PindropURLButton pindropUrl={crm['pindropUrl']} />}
          </div>

          {(onChangeClick || onRemoveClick) && (
            <div>
              <Dropdown onClick={e => e.stopPropagation()}>
                <Dropdown.Toggle size="sm" className="p-1 tinyFont" variant="outline-primary" />
                <Dropdown.Menu>
                  {onChangeClick && <Dropdown.Item onClick={onChangeClick}>Change Unit</Dropdown.Item>}
                  {onRemoveClick && <Dropdown.Item onClick={onRemoveClick}>Remove Unit</Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>

        <Row className="mt-2">
          {crm['initialContactDate'] && (
            <Col xs={12} md={6}>
              <p className="mb-0  text-secondary">
                <span className="text-dark">Initial Contact Date: </span>
                {moment(crm['initialContactDate']).format('MMMM Do YYYY')}
              </p>
            </Col>
          )}
          {crm['lastContactDate'] && (
            <Col xs={12} md={6} className="mt-2 mt-md-0">
              <p className="mb-0  text-secondary">
                <span className="text-dark">Last Contact Date: </span>
                {moment(crm['lastContactDate']).format('MMMM Do YYYY')}
              </p>
            </Col>
          )}
        </Row>
        {crm['followUpDate'] && (
          <Row className="mt-1">
            <Col xs={12} md={6}>
              <p className="mb-0  text-secondary">
                <span className="text-dark">Follow Up Date: </span>
                {moment(crm['followUpDate']).format('MMMM Do YYYY')}
              </p>
            </Col>
            <Col xs={12} md={6} className="mt-2 mt-md-0">
              <p className="mb-0  text-secondary">
                <span className="text-dark">Follow up in: </span>
                {getDaysFromToday(crm['followUpDate'])}
              </p>
            </Col>
          </Row>
        )}
        {!mini && (
          <>
            <Row className="mt-2">
              {crm['buyerEmail'] && (
                <Col xs={12} md={4} className="mt-2 mt-md-0">
                  <p className="mb-0  text-secondary">
                    <Envelope className="mr-1 text-dark" />
                    <a onClick={e => e.stopPropagation()} href={`mailto:${crm['buyerEmail']}`}>
                      {crm['buyerEmail']}
                    </a>
                  </p>
                </Col>
              )}
              {(crm['propertyAddress'] || crm['zipCode']) && (
                <Col xs={12} md={4}>
                  <p className="mb-0  text-secondary">
                    <Globe className="mr-1 text-dark" />
                    {`${crm['propertyAddress'] || ''} (${crm['zipCode']})`}
                  </p>
                </Col>
              )}
              {crm['cellPhone'] && (
                <Col xs={12} md={4} className="mt-2 mt-md-0">
                  <p className="mb-0  text-secondary">
                    <Phone className="mr-1 text-dark" />
                    <a onClick={e => e.stopPropagation()} href={`tel:${crm['cellPhone']}`}>
                      {crm['cellPhone']}
                    </a>
                  </p>
                </Col>
              )}
            </Row>

            <Row className="mt-1">
              {crm['cellPhone2'] && (
                <Col xs={12} md={6} className="mt-2 mt-md-0">
                  <p className="mb-0  text-secondary">
                    <PhoneLandscape className="mr-1 align-text-top" />
                    <a onClick={e => e.stopPropagation()} href={`tel:${crm['cellPhone2']}`}>
                      {crm['cellPhone2']}
                    </a>
                  </p>
                </Col>
              )}
              {crm['newOrUsed'] && (
                <Col xs={12} md={6}>
                  <p className="mb-0  text-secondary">
                    <span className="text-dark">New / Used: </span>
                    {crm['newOrUsed']}
                  </p>
                </Col>
              )}
            </Row>
            <Row className="mt-1">
              {crm['driveFolder'] && (
                <Col xs={12} md={6}>
                  <p className="mb-0">
                    <a
                      onClick={e => e.stopPropagation()}
                      style={{ textDecoration: 'underline' }}
                      target="_blank"
                      href={`https://drive.google.com/drive/u/0/folders/${crm['driveFolder']}`}
                    >
                      Open Drive folder <ArrowUpRightCircle className="align-text-bottom" />
                    </a>
                  </p>
                </Col>
              )}

              {crm['createdAt'] && (
                <Col xs={12} md={6} className="mt-2 mt-md-0">
                  <p className="mb-0  text-secondary">
                    <span className="text-dark">Days Active: </span>
                    {getDaysToToday(crm['createdAt'])} days
                  </p>
                </Col>
              )}
            </Row>

            <Row className="mt-1">
              {crm['typeOfUnit'] && (
                <Col xs={12} md={4}>
                  <p className="mb-0  text-secondary">
                    <span className="text-dark">Type of Unit: </span>
                    {crm['typeOfUnit']}
                  </p>
                </Col>
              )}
            </Row>
            <Row className="mt-1">
              {crm['stockOrRso'] && (
                <Col xs={12} md={4}>
                  <p className="mb-0  text-secondary">
                    <span className="text-dark">Stock / RSO Consultant: </span>
                    {crm['stockOrRso']}
                  </p>
                </Col>
              )}
              {crm['consultant'] && (
                <Col xs={12} md={4}>
                  <p className="mb-0  text-secondary">
                    <span className="text-dark">Consultant: </span>
                    {crm['consultant']}
                  </p>
                </Col>
              )}
              {crm['leadSource'] && (
                <Col xs={12} md={4}>
                  <p className="mb-0  text-secondary">
                    <span className="text-dark">Lead Source: </span>
                    {crm['leadSource']}
                  </p>
                </Col>
              )}
            </Row>
            {crm['consultantsFollowUpNotes'] && (
              <Row>
                <Col className="mt-2" xs={12}>
                  <h6>
                    <b>Consultant's Follow Up Notes:</b>
                  </h6>
                  <div className="alert alert-info rounded px-3 py-2 mt-2">{crm['consultantsFollowUpNotes']}</div>
                </Col>
              </Row>
            )}
            {crm['personalNotes'] && (
              <Row>
                <Col className="mt-2" xs={12}>
                  <h6>
                    <b>Personal Notes:</b>
                  </h6>
                  <div className="alert alert-info rounded px-3 py-2 mt-2">{crm['personalNotes']}</div>
                </Col>
              </Row>
            )}
          </>
        )}
        <hr />
      </div>
    </div>
  );
};

export default CrmSearchItem;
