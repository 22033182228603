import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import Loader from '../Loader';

const getLocalResponse = endpoint => {
  const localChoices = localStorage.getItem(endpoint);
  if (localChoices) {
    try {
      return JSON.parse(localChoices);
    } catch (e) {}
  }
  return null;
};
const CachedResponse = ({ render, listEndpoint, forceFreshData }) => {
  const [response, setResponse] = useState(null);
  const [responseLoading, setResponseLoading] = useState(true);

  const getResponse = () => {
    const localResponse = getLocalResponse(listEndpoint);
    if (!forceFreshData && localResponse) {
      setResponse(localResponse);
      setResponseLoading(false);
    }

    fetchRemoteResponse(forceFreshData || !localResponse);
  };

  const fetchRemoteResponse = async passResponseToParent => {
    const { response, error } = await makeApiRequests({ endpoint: `/${listEndpoint}/list` });
    if (error) {
      toast.error(error);
      setResponseLoading(false);
      return;
    }

    if (passResponseToParent) {
      setResponse(response);
      setResponseLoading(false);
    }

    localStorage.setItem(listEndpoint, JSON.stringify(response));
  };

  useEffect(() => {
    if (localStorage.getItem('user-token')) getResponse();
  }, []);

  if (!localStorage.getItem('user-token')) {
    return <Redirect from="/" to="/login" />;
  }

  if (responseLoading) return <Loader />;

  return response ? render(response) : '';
};

export default CachedResponse;
