import React, { useEffect, useState } from 'react';
import { getProfileFromLocalStorage } from '../../../helpers/session';
import FilterSideBar from '../FilterSidebar';
import SearchArea from './SearchArea';
import * as qs from 'qs';
import { useLocation } from 'react-router-dom';

const getFilterOptions = (appChoices, filterCreationOptions = []) => {
  return filterCreationOptions.map(
    ({
      key,
      label,
      appChoiceKey,
      isMembers,
      memberRole,
      defaultChecked = true,
      optionLabelKey = 'name',
      options: userDefinedOptions,
      doNotIncludeUnassigned
    }) => {
      //if there are already options, no need to process
      if (userDefinedOptions) {
        return {
          title: label,
          key,
          defaultChecked,
          options: doNotIncludeUnassigned
            ? userDefinedOptions
            : [{ option: 'Unassigned', value: null }, ...userDefinedOptions]
        };
      }

      if (memberRole && !Array.isArray(memberRole)) memberRole = [memberRole];

      const loggedInUser = getProfileFromLocalStorage();

      let options = isMembers
        ? appChoices.find(choices => choices.key === 'members')
        : appChoices.find(choices => choices.key === (appChoiceKey || key));

      if (options && options.values.length > 0) {
        options = isMembers
          ? options.values
              .filter(m => (memberRole ? memberRole.includes(m.role) : true))
              .map(user => ({ ...user, name: `${user.name}${user._id === loggedInUser._id ? ' (me)' : ''}` }))
              .sort((m1, m2) => m1.name.localeCompare(m2.name))
          : options.values;

        const isString = typeof options[0] === 'string';
        options = options.map(o => (isString ? { value: o, option: o } : { value: o._id, option: o[optionLabelKey] }));
      } else {
        options = [];
      }

      return {
        title: label,
        key,
        defaultChecked,
        options: doNotIncludeUnassigned ? options : [{ option: 'Unassigned', value: null }, ...options]
      };
    }
  );
};

const getFilterValues = (filterOptions = []) => {
  const returnValues = {};

  filterOptions.forEach(option => {
    returnValues[option.key] = option.defaultChecked ? option.options.map(o => o.value) : [];
  });

  return returnValues;
};

/** filterCreationOptions:
 *  key [used by backend to filter]
 *  label [used by frontend to show as filter filter groups name]
 *  appChoiceKey [used to find options from appChoices, if not specified key is used]
 *  optionLabelKey [if is an object, used to show option label, value would still be _id]
 *  isMembers [if it is an member of app]
 *  memberRole [used to filter options with the options are members of app]
 *  defaultChecked [whether to check all options initially, default is true]
 */

const SearchBox = ({
  appChoices = [],
  filterCreationOptions,
  sortByOptions = [],
  maxLimitOptions = [],
  onSearchOptionsChange,
  disabled,
  containerClass = '',
  defaultMaxLimit = 'All'
}) => {
  const location = useLocation();
  const { q } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [query, setQuery] = useState(q || '');
  const [filterBarVisible, setFilterBarVisible] = useState(false);
  const [maxLimit, setMaxLimit] = useState(
    maxLimitOptions.includes(defaultMaxLimit) ? defaultMaxLimit : maxLimitOptions[0]
  );
  const [sortBy, setSortBy] = useState(sortByOptions[0]?.key);
  const [descSort, setDescSort] = useState(true);
  const [filterOptions] = useState(filterCreationOptions ? getFilterOptions(appChoices, filterCreationOptions) : []);
  const [filters, setFilters] = useState(getFilterValues(filterOptions));

  const createFilterWithBlankString = () => {
    const newFilters = { $and: [] };

    for (const filterKey in filters) {
      const filterOption = filterOptions.find(o => o.key === filterKey);
      const filterCreationOption = filterCreationOptions.find(o => o.key === filterKey);

      // includeBlankIfNull is used to ensure we do not pass blank string in case of id ref
      // because mongoose finds blank string against schema of ObjectId
      let currentFilter = [...filters[filterKey]];
      if (currentFilter.includes(null)) {
        if (filterCreationOption.includeBlankIfNull) {
          currentFilter = ['', ...currentFilter];
        }

        currentFilter = {
          $or: [
            { [filterKey]: { $in: currentFilter } },
            { [filterKey]: { $nin: filterOption.options.map(o => o.value) } }
          ]
        };
      } else {
        currentFilter = { [filterKey]: currentFilter };
      }

      newFilters.$and.push(currentFilter);
    }

    return newFilters;
  };

  useEffect(() => {
    if (onSearchOptionsChange) {
      onSearchOptionsChange({ sortBy, maxLimit, descSort, filters: createFilterWithBlankString(), query });
    }
  }, [sortBy, maxLimit, descSort, filters]);

  const onSearchClick = () => {
    if (onSearchOptionsChange) {
      onSearchOptionsChange({ sortBy, maxLimit, descSort, filters: createFilterWithBlankString(), query });
    }
  };

  const onFilterApply = filter => {
    setFilters(filter);
    setFilterBarVisible(false);
  };

  return (
    <>
      <div className={`py-3 ${containerClass}`}>
        <SearchArea
          query={query}
          onQueryChange={setQuery}
          onSearchClick={onSearchClick}
          disabled={disabled}
          maxLimit={maxLimit}
          onMaxLimitOptionChange={setMaxLimit}
          maxLimitOptions={maxLimitOptions}
          sortBy={sortBy}
          onSortByOptionChange={setSortBy}
          sortByOptions={sortByOptions}
          descSort={descSort}
          onDescSortButtonClick={() => setDescSort(!descSort)}
          onFilterButtonClick={filterCreationOptions ? () => setFilterBarVisible(true) : undefined}
        />
      </div>
      {filterCreationOptions && (
        <FilterSideBar
          show={filterBarVisible}
          initialFilterValues={filters}
          filterOptions={filterOptions}
          onFilterApply={onFilterApply}
          onSideBarClose={() => setFilterBarVisible(false)}
        />
      )}
    </>
  );
};

export default SearchBox;
