import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { getDocIdFromUrl } from '../../helpers/global';
import Loader from '../Loader';
import DocumentPrinter from './DocumentPrinter';

const getDocId = (appChoices, documentName, docSource) => {
  const salesAgreementPG = appChoices
    .find(ac => ac.key === 'printGroups')
    .values.find(pg => pg.name === documentName && pg.dataSources.includes(docSource));

  return salesAgreementPG ? getDocIdFromUrl(salesAgreementPG.url) : '';
};

const getDocumentFieldsFromLocalStorage = docId => {
  try {
    const fields = localStorage.getItem(`documentFields-${docId}`);
    return fields ? JSON.parse(fields) : null;
  } catch (error) {
    return null;
  }
};

const setDocumentFieldsToLocalStorage = (docId, documentFields) => {
  localStorage.setItem(`documentFields-${docId}`, JSON.stringify(documentFields));
};

const MockPrint = ({ appChoices, documentName }) => {
  const docId = useMemo(() => getDocId(appChoices, documentName, 'Contact'), [appChoices, documentName]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [documentFields, setDocumentFields] = useState([]);

  useEffect(() => {
    if (!docId) {
      return toast.error('Document not found!');
    }

    const getDocumentFieldsFromServer = async (docId = '', updateLoader) => {
      if (updateLoader) {
        setLoadingFields(true);
      }

      const { error, response } = await makeApiRequests({
        endpoint: ENDPOINTS.DOCHUB_DOCUMENT_PARAMS(''),
        requestBody: {
          docId: docId,
          dataSource: 'Mock'
        }
      });

      if (updateLoader) {
        setLoadingFields(false);
      }

      if (error) {
        toast.error(error);
        return [];
      }

      const fields = response.foundParams;
      setDocumentFieldsToLocalStorage(docId, fields);
      return fields;
    };

    const getDocumentFields = async () => {
      const cachedFields = getDocumentFieldsFromLocalStorage(docId);
      if (cachedFields) {
        setDocumentFields(cachedFields);
      }

      const latestFields = await getDocumentFieldsFromServer(docId, !Boolean(cachedFields));
      setDocumentFields(latestFields);
    };

    getDocumentFields();
  }, [docId]);

  if (loadingFields || !documentFields?.length) {
    return <Loader />;
  }

  return (
    <DocumentPrinter
      appChoices={appChoices}
      docSource={'Contact'}
      documentId={docId}
      documentName={documentName}
      documentFields={{
        withData: documentFields.filter(p => p.value),
        withoutData: documentFields.filter(p => !p.value)
      }}
      isMockPrint
    />
  );
};

export default MockPrint;
