import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Route, Switch, withRouter } from 'react-router';
import NotFound from '../../components/NotFound';
import CachedResponse from '../common/CachedResponse';
import DeliverySetCalculator from './DeliverySetCalculator';
import MockPrint from '../doc-hub/MockPrint';

const Utilities = () => {
  const [role] = useState(localStorage.getItem('user-role'));

  if (!['super admin', 'admin', 'sales', 'sales manager', 'service'].includes(role)) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/utilities/commission-sheet">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <MockPrint appChoices={appChoices} documentName="Commission Sheet" />}
          />
        </Route>
        <Route exact path="/utilities/sales-agreement">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <MockPrint appChoices={appChoices} documentName="Sales Agreement" />}
          />
        </Route>
        {['super admin', 'admin', 'sales', 'sales manager'].includes(role) && (
          <Route exact path="/utilities/delivery-set-calculator">
            <CachedResponse
              listEndpoint={'app-choices'}
              render={appChoices => <DeliverySetCalculator appChoices={appChoices} />}
            />
          </Route>
        )}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  return (
    <>
      <Container fluid className="px-2 px-md-5 py-2 h-100">
        {getActivePage()}
      </Container>
    </>
  );
};

export default withRouter(Utilities);
