import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { NumberInput } from '../../../form-generator/components';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS } from '../../../helpers/constants';
import { downloadFileFromString } from '../../../helpers/global';
import Loader from '../../Loader';
import NotFound from '../../NotFound';

const ACRebateReport = () => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [submittingRebate, setSubmittingRebate] = useState(false);
  const [reportDownloaded, setReportDownloaded] = useState(false);

  const fetchRebates = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        filter: {
          acRebateReportedDate: null,
          dateACRebateReceived: { $ne: null }
        },
        query: '',
        returnPaths: ['buyer', 'dateACRebateRequested', 'salesLocation', 'inventoryUnit']
      },
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(
      response.results
        .filter(c => c['salesLocation'])
        .map(contract => {
          const inventoryUnit = contract['inventoryUnit']
            ? {
                ...contract['inventoryUnit'],
                manufacturer: contract['inventoryUnit']['manufacturer']?.name || ''
              }
            : {};
          delete inventoryUnit['_id'];

          return {
            ...contract,
            salesLocation: contract['salesLocation']['location'],
            reportChecked: true,
            priorPaidValue: 0,
            ...inventoryUnit
          };
        })
        .sort((a, b) => (a['manufacturer']?.name || '').localeCompare(b['manufacturer']?.name || ''))
    );
  };

  useEffect(() => {
    fetchRebates();
  }, []);

  const submitRebateReport = async () => {
    const filteredContracts = contracts.filter(c => c.reportChecked);

    if (filteredContracts.length === 0) {
      toast.error('Please select atleast one contract!');
      return;
    }

    toast.info('Please wait submitting report...');
    setSubmittingRebate(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.REPORTS_SUBMIT_AC_REBATE,
      requestBody: {
        contractIds: filteredContracts.map(c => c['_id'])
      }
    });

    if (error) {
      toast.error(error);
      setSubmittingRebate(false);
      return;
    }

    if (!reportDownloaded) {
      downloadReport();
    }

    setContracts(contracts.filter(c => !c.reportChecked));
    setSubmittingRebate(false);
    toast.success('Report submitted successfully');
    window.scrollTo({ top: 0 });
    setReportDownloaded(false);
  };

  const onRebateCheck = (e, contractId) => {
    if (!e) return;

    const index = contracts.findIndex(c => c['_id'] === contractId);
    if (index === -1) return;
    contracts[index]['reportChecked'] = e.target.checked;
    setContracts([...contracts]);
  };

  const onRebateValueChange = (e, contractId) => {
    if (!e) return;

    const index = contracts.findIndex(c => c['_id'] === contractId);
    if (index === -1) return;
    contracts[index]['priorPaidValue'] = e.target.value ? parseFloat(e.target.value) : 0;
    setContracts([...contracts]);
  };

  const onAllCheck = e => {
    if (!e) return;

    contracts.forEach(c => (c['reportChecked'] = e.target.checked));
    setContracts([...contracts]);
  };

  const downloadReport = () => {
    const filteredContracts = contracts.filter(c => c.reportChecked);
    if (filteredContracts.length === 0) {
      toast.error('Please select atleast one contract!');
      return;
    }

    let csv =
      'Mfg,Serial # A,Clients Name,Sales Location,Annual AC Rebate,Prior Paid Per Clayton,Amount Received,Notes\n';

    filteredContracts.forEach(c => {
      let row = ['manufacturer', 'serialA', 'buyer', 'salesLocation']
        .map(header => {
          if (!c[header]) return '';
          return c[header].includes(',') ? `"${c[header]}"` : c[header];
        })
        .join(',');

      const rebateAmount = c['amountACRebateReceived'] || '';

      if (c['priorPaidValue']) {
        const amountReceived = (rebateAmount ? parseFloat(rebateAmount) : 0) - c['priorPaidValue'];
        row = row + `,${[rebateAmount, c['priorPaidValue'], amountReceived].join(',')},\n`;
      } else {
        row = row + `,,,${rebateAmount},\n`;
      }

      csv = csv + row;
    });

    downloadFileFromString({ data: csv, fileName: `AC Rebate Report - ${moment().format('MMM DD, yyyy')}.csv` });
    setReportDownloaded(true);
  };

  return (
    <Container fluid className={'h-100 py-3 px-md-5 '}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                style={{
                  height: 22,
                  width: 22
                }}
                name="contractReports"
                checked={contracts.every(c => c['reportChecked'])}
                onChange={onAllCheck}
              />
            </div>
            <div className="flex-grow-1 ml-3">
              <h5 className="mb-0 mt-1">Unreported Rebates</h5>
            </div>
          </div>

          <hr />
          {contracts.length > 0 ? (
            <>
              {contracts.map(contract => (
                <div key={contract['_id']} className="d-flex mb-3">
                  <div className="text-center mb-3 form-check">
                    <input
                      className="form-check-input"
                      id={`rebate-check-${contract['_id']}`}
                      type="checkbox"
                      style={{
                        height: 22,
                        width: 22
                      }}
                      name="contractReports"
                      checked={contract['reportChecked']}
                      onChange={e => onRebateCheck(e, contract['_id'])}
                      value={contract['_id']}
                    />
                  </div>
                  <div className="flex-grow-1 pl-4">
                    <Row className="mb-2">
                      <Col xs={12} md={6} className="align-self-center">
                        <h6 className="d-inline-block mr-3 mb-0">
                          {contract['buyer']} - {contract['salesLocation']}
                        </h6>
                      </Col>
                      <Col xs={12} md={6} className="text-md-right">
                        <p className="mb-0">
                          Date AC Rebate Requested:{' '}
                          <span className="text-muted">
                            {contract['dateACRebateRequested']
                              ? moment(contract['dateACRebateRequested']).format('MMMM Do YYYY')
                              : 'N/A'}
                          </span>
                        </p>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col xs={12} md={4} className="align-self-center">
                        <h6 className="mb-0 text-muted">Prior Paid Per Clayton:</h6>
                      </Col>
                      <Col xs={12} md={8}>
                        <NumberInput
                          name="priorPaidValue"
                          size="sm"
                          onChange={e => onRebateValueChange(e, contract['_id'])}
                        />
                      </Col>
                    </Row>

                    <hr />
                  </div>
                </div>
              ))}

              <Row className="pb-5">
                <Col className="text-right">
                  <Button variant="danger" className="mr-2" disabled={submittingRebate} onClick={downloadReport}>
                    Download
                  </Button>
                  <Button disabled={submittingRebate} onClick={submitRebateReport}>
                    Report
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            !loading && <NotFound text="No contracts to show!" />
          )}
        </>
      )}
    </Container>
  );
};

export default ACRebateReport;
